@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700|Roboto:100,300,400,500,700,900");
section.container {
  max-width: 1300px; }

ul {
  list-style: none !important;
  margin: 0;
  padding: 0 !important; }
  ul li {
    margin: 0;
    padding: 0; }

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0; }
  .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

a:visited {
  color: unset; }

.entry-date ul {
  margin-left: 10px;
  margin-bottom: 8px;
  padding: 5px; }

/*------------------------------------------------------------------
[Master Stylesheet]

Project:        Learnpro
Version:        1.0
Created Date:   09.28.2017
Primary use:    Learnpro - Education University School Kindergarten Learning HTML Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/
/*
This file contains the common styles of this theme.
*/
/*------------------------------------------------------------------
[table of contents]

		* typography.less
		* common.less
		* extra.less
		* overlay.less
		* header.less
		* nav.less
		* topbar.less
		* inner-header-title.less
		* vertical-nav.less
		* menu-full-page.less
		* boxed-layout.less
		* form.less
		* side-push-panel.less
		* box-hover-effect.less
		* gallery-isotope.less
		* slider.less
		* home.less
		* about.less
		* causes.less
		* volunteer.less
		* contact.less
		* event.less
		* practice-area.less
		* services.less
		* job.less
		* shop.less
		* blog.less
		* shortcodes.less
			* alerts.less
			* blockquote.less
			* buttons.less
			* clients.less
			* countdown-Timer.less
			* countdown.less
			* dividers.less
			* facebook-disqus-comments.less
			* faqs.less
			* flex-slider.less
			* flip-box.less
			* font-icons.less
			* funfacts.less
			* infinitescroll.less
			* instagram.less
			* lightbox.less
			* map.less
			* pegination.less
			* pricing.less
			* process-steps.less
			* progress-bar.less
			* promo.less
			* section-title.less
			* separators.less
			* styled-icons.less
			* tabs.less
			* teams.less
			* testimonials.less
			* toggles-accordions.less
			* vertical-timeline.less
			* working-process.less

	* Footer
* ===============================================
*/
/*
 * Typography.less
 * -----------------------------------------------
*/
::selection {
  background: #333333;
  color: #fff;
  text-shadow: none; }

::-moz-selection {
  background: #333333;
  /* Firefox */
  color: #fff;
  text-shadow: none; }

::-webkit-selection {
  background: #333333;
  /* Safari */
  color: #fff;
  text-shadow: none; }

:active, :focus {
  outline: none !important; }

html {
  font-size: 100%; }

body {
  line-height: 1.7;
  color: #666666;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  background-color: #fff; }

a {
  color: #808080;
  text-decoration: none; }
  a:hover, a:focus {
    color: #595959;
    text-decoration: none; }
  a img {
    border: none; }

img {
  max-width: 100%; }

iframe {
  border: none !important; }

/* -------- Headings ---------- */
h1, .h1 {
  font-size: 3.4rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 24px; }

h4, .h4 {
  font-size: 18px; }

h5, .h5 {
  font-size: 14px; }

h6, .h6 {
  font-size: 12px; }

h1, h2, .h1, .h2 {
  font-family: 'Roboto Slab', serif;
  font-weight: 600;
  line-height: 1.42857143;
  color: #333333; }

h1 small, h2 small, .h1 small, .h2 small, h1 .small, h2 .small, .h1 .small, .h2 .small {
  font-weight: normal;
  line-height: 1;
  color: #333333; }

h1 a, h2 a, .h1 a, .h2 a {
  color: #333333; }

h3, h4, h5, h6, .h3, .h4, .h5, .h6 {
  font-family: 'Roboto Slab', serif;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333333; }

h3 small, h4 small, h5 small, h6 small, .h3 small, .h4 small, .h5 small, .h6 small, h3 .small, h4 .small, h5 .small, h6 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #333333; }

h3 a, h4 a, h5 a, h6 a, .h3 a, .h4 a, .h5 a, .h6 a {
  color: #333333; }

/* -------- Body Text ---------- */
p, pre, ul, ol, dl, dd, blockquote, address, table, fieldset, form {
  margin-bottom: 10px; }

/* -------- Custom Font Family ---------- */
.font-roboto {
  font-family: 'Roboto', sans-serif; }

.font-roboto-slab {
  font-family: 'Roboto Slab', serif; }

/* breadcrumb */
.breadcrumb {
  background-color: transparent;
  padding-left: 0; }
  .breadcrumb > li + li::before {
    content: "\f04b";
    font-family: fontawesome;
    font-size: 9px;
    margin-right: 5px; }
  .breadcrumb li.active {
    font-weight: 600; }
  .breadcrumb.white li.active, .breadcrumb.white li a {
    color: #fff; }

@media only screen and (min-width: 768px) {
  .breadcrumb.text-right {
    text-align: right; } }

/*Drop Caps*/
.drop-caps p {
  text-align: justify; }
  .drop-caps p:first-child:first-letter {
    color: #111111;
    display: block;
    float: left;
    font-size: 48px;
    line-height: 48px;
    margin: 6px 3px;
    padding: 10px 18px; }

.drop-caps.text-colored p:first-child:first-letter {
  display: block;
  float: left;
  font-size: 48px;
  line-height: 48px;
  margin: 6px 3px;
  padding: 10px 18px; }

.drop-caps.border p:first-child:first-letter {
  border: 1px solid;
  margin: 8px 16px 0 0;
  padding: 15px 20px; }

.drop-caps.border-rounded p:first-child:first-letter {
  border: 1px solid;
  border-radius: 50%;
  margin: 8px 16px 0 0;
  padding: 15px 20px; }

.drop-caps.colored-square p:first-child:first-letter {
  color: #fff;
  margin: 8px 16px 0 0;
  padding: 15px 20px; }

.drop-caps.colored-rounded p:first-child:first-letter {
  color: #fff;
  margin: 8px 16px 0 0;
  padding: 15px 20px;
  border-radius: 50%; }

.drop-caps.dark-square p:first-child:first-letter {
  background: #222222;
  color: #fff;
  margin: 8px 16px 0 0;
  padding: 15px 20px; }

.drop-caps.dark-rounded p:first-child:first-letter {
  background: #222222;
  color: #fff;
  margin: 8px 16px 0 0;
  padding: 15px 20px;
  border-radius: 50%; }

/*Text Highlight*/
.text-highlight {
  background: none repeat scroll 0 0 #111111;
  border-radius: 4px;
  color: #fff;
  padding: 0 5px 3px; }
  .text-highlight.light {
    background: #808080; }

/*
 * common.less
 * -----------------------------------------------
*/
/* -------- Basic Structure ---------- */
html {
  font-size: 100%;
  max-width: 100%;
  -webkit-font-smoothing: antialiased; }
  html a {
    -webkit-font-smoothing: antialiased; }

@media only screen and (max-width: 991px) {
  html {
    font-size: 90%; } }

@media only screen and (max-width: 767px) {
  html {
    font-size: 85%; } }

@media only screen and (max-width: 479px) {
  html {
    font-size: 80%; } }

body {
  max-width: 100%;
  overflow-x: hidden; }

img {
  max-width: 100%; }

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0; }

.fluid-video-wrapper {
  padding: 0;
  position: relative;
  width: 100%; }

iframe {
  border: none;
  width: 100%; }

.lead {
  font-size: 18px; }

.text-highlight {
  background: none repeat scroll 0 0 #111111;
  border-radius: 4px;
  color: #fff;
  padding: 0 5px 3px; }
  .text-highlight.light {
    background: #808080; }

.inline-block {
  display: inline-block; }

.display-block {
  display: block; }

.fullwidth {
  width: 100%; }

.bg-hover-theme-colored {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }

/* Text-decoration */
.text-underline {
  text-decoration: underline; }

/* -------- Line-Bottom ---------- */
.heading-line-bottom {
  border-bottom: 1px dashed #808080;
  display: inline-block;
  margin: 0;
  position: relative;
  margin: 20px 0; }
  .heading-line-bottom::after {
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    top: 4px;
    width: 20px; }

.heading-title {
  margin-top: 10px; }

.line-bottom {
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 10px; }
  .line-bottom:after {
    bottom: -1px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 50px; }

.line-bottom-theme-colored-2 {
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 10px; }
  .line-bottom-theme-colored-2:after {
    border-radius: 10px;
    bottom: -1px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 50px; }

.double-line-bottom-theme-colored-2 {
  margin-bottom: 20px;
  margin-top: 8px;
  padding-bottom: 5px;
  position: relative; }
  .double-line-bottom-theme-colored-2:after {
    border-radius: 8px;
    bottom: 1px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 64px; }
  .double-line-bottom-theme-colored-2:before {
    border-radius: 8px;
    bottom: -1px;
    content: "";
    height: 6px;
    left: 10px;
    position: absolute;
    width: 24px; }

.line-bottom-centered {
  position: relative;
  margin: 10px 0 20px; }
  .line-bottom-centered:after {
    bottom: -13px;
    content: "";
    height: 3px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    position: absolute;
    right: 0;
    width: 40px; }

.title-icon {
  margin: 0 auto;
  max-width: 160px;
  position: relative; }
  .title-icon [class^="flaticon-"]::after, .title-icon [class^="flaticon-"]::before {
    font-size: 48px;
    margin-left: 10px; }
  .title-icon::after {
    content: "";
    height: 2px;
    left: 24px;
    position: absolute;
    top: 10px;
    width: 30px; }
  .title-icon::before {
    content: "";
    height: 2px;
    position: absolute;
    right: 20px;
    top: 10px;
    width: 30px; }

/* -------- List ---------- */
ul, ol {
  margin: 0;
  padding: 0; }

ul ul, ol ul {
  margin-left: 15px; }

ul.list {
  list-style: outside none none;
  margin-top: 13px;
  padding-left: 0; }
  ul.list li {
    margin: 5px 0;
    font-weight: 500;
    position: relative; }
    ul.list li:before {
      border: none;
      color: #a9a9a9;
      content: "\f111";
      display: inline-block;
      font-family: fontawesome;
      font-size: 9px;
      margin-right: 10px;
      padding: 0;
      left: 0;
      position: absolute;
      top: 1px; }
    ul.list li:hover:before {
      background: none;
      border-color: transparent; }
    ul.list li a {
      text-decoration: none;
      -webkit-transition: all 300ms ease-in;
      -o-transition: all 300ms ease-in;
      transition: all 300ms ease-in; }
  ul.list.list-white li:before {
    color: #fff; }
  ul.list.list-white li:hover:before {
    background: none;
    border-color: transparent; }
  ul.list.list-white li a {
    color: #fff;
    text-decoration: none;
    -webkit-transition: all 300ms ease-in;
    -o-transition: all 300ms ease-in;
    transition: all 300ms ease-in; }

ul.list-border-bottom li {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px; }

ul.list.list-border-bottom li:last-child {
  border-bottom: none; }

ul.list-border-bottom.no-padding li {
  padding-bottom: 0px; }
  ul.list-border-bottom.no-padding li:last-child {
    border-bottom: none; }

/* -------- List White ---------- */
/* -------- List border bottom ---------- */
/* -------- List Icons ---------- */
.list-icon li {
  font-size: 14px;
  margin: 0; }
  .list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px; }

.list-icon.square li i {
  background: #c0c0c0;
  color: #555555;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  margin: 5px 10px 5px 0;
  text-align: center;
  width: 28px; }

.list-icon.rounded li i {
  color: #555555;
  background: #c0c0c0;
  border-radius: 50%;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  margin: 5px 10px 5px 0;
  text-align: center;
  width: 28px; }

.list-icon.theme-colored.square li i, .list-icon.theme-colored.rounded li i {
  color: #fff; }

/* -------- Personal Info List ---------- */
.list-info li {
  clear: both;
  position: relative; }
  .list-info li label {
    display: inline-block;
    font-weight: bold;
    padding: 5px 0 0; }
  .list-info li span {
    left: 110px;
    padding: 5px 0;
    position: absolute; }

.list-features {
  -moz-column-count: 3;
  column-count: 3; }

.list-dashed > article, .list-dashed > div, .list-dashed > li {
  border-bottom: 1px dashed #d3d3d3;
  margin-bottom: 7px;
  padding-bottom: 7px; }

.list-dashed.dark-dash > article, .list-dashed.dark-dash > div, .list-dashed.dark-dash > li {
  border-bottom: 1px dashed #333333; }

ul.list.check li:before {
  content: "\f00c";
  font-family: fontawesome;
  font-size: 12px; }

ul.list.angle-double-right li:before {
  content: "\f101";
  font-family: fontawesome;
  font-size: 12px; }

ul.list.angle-right li:before {
  content: "\f105";
  font-family: fontawesome; }

ul.list.check-circle li:before {
  content: "\f058";
  font-family: fontawesome;
  font-size: 14px; }

/* -------- Call To Actions ---------- */
.call-to-action {
  display: inline-block;
  width: 100%;
  padding-bottom: 20px;
  padding-top: 20px; }

/* -------- Section & Divider ---------- */
section {
  position: relative; }
  section > .container, section > .container-fluid {
    padding-top: 70px;
    padding-bottom: 70px; }

/* -------- Background Video ---------- */
.bg-video {
  height: 100%;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: -1; }
  .bg-video .video {
    height: 100%;
    left: 0;
    margin: 0;
    position: relative;
    top: 0;
    width: 100%; }
    .bg-video .video .mbYTP_wrapper {
      z-index: -1; }

/* -------- Box Table ---------- */
.display-table-parent {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.display-table {
  display: table;
  height: 100%;
  position: relative;
  width: 100%; }

.display-table-cell {
  display: table-cell;
  height: 100%;
  vertical-align: middle; }

/* -------- Scroll To Top ---------- */
.scrollToTop {
  background: rgba(0, 0, 0, 0.1);
  bottom: 15px;
  display: none;
  height: 50px;
  padding: 2px;
  position: fixed;
  right: 15px;
  text-align: center;
  text-decoration: none;
  width: 50px;
  z-index: 99999; }
  .scrollToTop i {
    color: #fff;
    font-size: 42px; }
  .scrollToTop:hover {
    background: rgba(0, 0, 0, 0.6);
    text-decoration: none; }

/* -------- magnific popup ---------- */
.mfp-close-btn-in .mfp-close {
  color: #eee;
  font-size: 64px; }

.read-more {
  position: relative; }
  .read-more::after {
    content: "\f054";
    font-family: fontawesome;
    font-size: 11px;
    margin-left: 5px;
    vertical-align: middle; }
  .read-more:hover::after {
    margin-left: 8px;
    -webkit-transition: all 200ms ease-in-out 0s;
    -o-transition: all 200ms ease-in-out 0s;
    transition: all 200ms ease-in-out 0s; }

/*
 * extra.less
 * -----------------------------------------------
*/
/* -------- Heading Style ---------- */
.heading-border {
  border-bottom: 1px solid #d3d3d3;
  margin: 30px 0;
  padding: 20px 0; }

/* -------- FullCalendar - Plugin ---------- */
.fc-day-number {
  color: #333333; }
  .fc-day-number.fc-other-month {
    opacity: 0.4; }

/* scrolltofixed-container */
.scrolltofixed-container {
  position: static; }

@media only screen and (max-width: 767px) {
  .scrolltofixed-container .scrolltofixed {
    position: static !important; }
    .scrolltofixed-container .scrolltofixed + div {
      display: none !important; } }

/* slider revolution */
.tp-bannertimer {
  z-index: 21; }

.mfp-close-btn-in .mfp-close {
  color: #fff;
  font-size: 48px; }

/*
 * overlays.less
 * -----------------------------------------------
*/
.layer-overlay {
  position: relative;
  /* overlay-dark */
  /* overlay-white */ }
  .layer-overlay::before {
    background: rgba(17, 17, 17, 0.5) none repeat scroll 0 0;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }
  .layer-overlay.overlay-light::before {
    background-color: rgba(255, 255, 255, 0.4); }
  .layer-overlay.overlay-lighter::before {
    background-color: rgba(255, 255, 255, 0.3); }
  .layer-overlay.overlay-deep::before {
    background-color: rgba(255, 255, 255, 0.9); }
  .layer-overlay.overlay-dark::before {
    background-color: rgba(17, 17, 17, 0.85); }
  .layer-overlay.overlay-dark-1::before {
    background-color: rgba(17, 17, 17, 0.1); }
  .layer-overlay.overlay-dark-2::before {
    background-color: rgba(17, 17, 17, 0.2); }
  .layer-overlay.overlay-dark-3::before {
    background-color: rgba(17, 17, 17, 0.3); }
  .layer-overlay.overlay-dark-4::before {
    background-color: rgba(17, 17, 17, 0.4); }
  .layer-overlay.overlay-dark-5::before {
    background-color: rgba(17, 17, 17, 0.5); }
  .layer-overlay.overlay-dark-6::before {
    background-color: rgba(17, 17, 17, 0.6); }
  .layer-overlay.overlay-dark-7::before {
    background-color: rgba(17, 17, 17, 0.7); }
  .layer-overlay.overlay-dark-8::before {
    background-color: rgba(17, 17, 17, 0.8); }
  .layer-overlay.overlay-dark-9::before {
    background-color: rgba(17, 17, 17, 0.9); }
  .layer-overlay.overlay-white::before {
    background-color: rgba(255, 255, 255, 0.4); }
  .layer-overlay.overlay-white-1::before {
    background-color: rgba(255, 255, 255, 0.1); }
  .layer-overlay.overlay-white-2::before {
    background-color: rgba(255, 255, 255, 0.2); }
  .layer-overlay.overlay-white-3::before {
    background-color: rgba(255, 255, 255, 0.3); }
  .layer-overlay.overlay-white-4::before {
    background-color: rgba(255, 255, 255, 0.4); }
  .layer-overlay.overlay-white-5::before {
    background-color: rgba(255, 255, 255, 0.5); }
  .layer-overlay.overlay-white-6::before {
    background-color: rgba(255, 255, 255, 0.6); }
  .layer-overlay.overlay-white-7::before {
    background-color: rgba(255, 255, 255, 0.7); }
  .layer-overlay.overlay-white-8::before {
    background-color: rgba(255, 255, 255, 0.8); }
  .layer-overlay.overlay-white-9::before {
    background-color: rgba(255, 255, 255, 0.9); }
  .layer-overlay.layer-pattern::before {
    background-image: url(../images/pattern1.png);
    background-color: rgba(0, 0, 0, 0.6); }
  .layer-overlay.layer-pattern2::before {
    background-image: url(../images/pattern2.png);
    background-color: rgba(0, 0, 0, 0.8); }
  .layer-overlay.layer-pattern3::before {
    background-image: url(../images/pattern3.png);
    background-color: rgba(0, 0, 0, 0.8); }
  .layer-overlay.maximage-layer-overlay::before {
    z-index: 1; }
  .layer-overlay.overlay-blue::before {
    background-color: rgba(1, 162, 208, 0.9); }
  .layer-overlay.overlay-blue-light::before {
    background-color: rgba(1, 162, 208, 0.45); }
  .layer-overlay.overlay-pink::before {
    background-color: rgba(235, 110, 142, 0.9); }
  .layer-overlay.overlay-pink-light::before {
    background-color: rgba(235, 110, 142, 0.45); }
  .layer-overlay.overlay-brown::before {
    background-color: rgba(60, 171, 111, 0.9); }
  .layer-overlay.overlay-brown-light::before {
    background-color: rgba(60, 171, 111, 0.45); }
  .layer-overlay.overlay-yellow::before {
    background-color: rgba(255, 187, 34, 0.9); }
  .layer-overlay.overlay-yellow-light::before {
    background-color: rgba(255, 187, 34, 0.45); }
  .layer-overlay.overlay-green::before {
    background-color: rgba(16, 196, 92, 0.9); }
  .layer-overlay.overlay-green-light::before {
    background-color: rgba(16, 196, 92, 0.45); }
  .layer-overlay.overlay-theme-color-1::before {
    background-color: rgba(173, 216, 230, 0.9); }
  .layer-overlay.overlay-theme-color-2::before {
    background-color: rgba(230, 173, 188, 0.9); }
  .layer-overlay.overlay-theme-color-3::before {
    background-color: rgba(230, 216, 173, 0.9); }

.half-divider .overlay-theme-color-1, .half-divider .overlay-theme-color-2, .half-divider .overlay-theme-color-3 {
  content: "";
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 50%; }

/*
 * header.less
 * -----------------------------------------------
*/
.header-top {
  padding: 5px 0; }
  .header-top .header-widget .social-icons a i:hover {
    background: none; }
  .header-top .header-widget .list-border li {
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    padding: 5px 15px; }
    .header-top .header-widget .list-border li:last-child {
      border-right: 0; }

@media only screen and (max-width: 767px) {
  .header-top .header-widget .contact-info {
    margin-bottom: 20px; }
    .header-top .header-widget .contact-info li {
      display: block;
      text-align: center; } }

.menuzord-brand img {
  max-height: 36px; }

/* -------- Inner Header ---------- */
.inner-header .title {
  text-transform: uppercase; }

/* -------- Custom theme style ---------- */
.header-nav .header-nav-wrapper .menuzord {
  padding: 0; }

.header-nav.navbar-transparent.animated-active {
  background-color: rgba(0, 0, 0, 0.25); }

/*
 * nav.less
 * -----------------------------------------------
*/
/* -------- nav sidebar ---------- */
.nav-sidebar li {
  background: #f1f1f1;
  padding-bottom: 0; }
  .nav-sidebar li a {
    border-radius: 0; }

.nav-pills li a:hover {
  background: #d3d3d3;
  color: #777777; }

.nav-pills li.active > a {
  background: #d3d3d3;
  color: #777777; }
  .nav-pills li.active > a:hover, .nav-pills li.active > a:focus {
    background: #d3d3d3;
    color: #777777; }

/* -------- Header Nav ---------- */
.header-nav {
  position: relative; }
  .header-nav .header-nav-absolute-wrapper {
    width: 100%; }
  .header-nav .header-nav-wrapper .menuzord {
    padding: 0; }

/* -------- Menuzord ---------- */
.menuzord-menu > li {
  padding: 24px 0; }

.menuzord-menu ul.dropdown {
  top: 83px; }

.menuzord-menu > li > a {
  font-size: 13px;
  color: #333333; }

.menuzord .menuzord-menu > li.active > a i, .menuzord .menuzord-menu > li:hover > a i {
  color: #fff; }

.menuzord .menuzord-menu ul.dropdown li:hover > a i {
  color: #fff; }

.menuzord-menu ul.dropdown {
  border: 1px solid #eee; }
  .menuzord-menu ul.dropdown li {
    margin: 0; }
    .menuzord-menu ul.dropdown li a {
      color: #666666;
      font-size: 12px;
      font-weight: 600;
      padding: 7px 25px 7px 22px; }
    .menuzord-menu ul.dropdown li .indicator {
      top: 4px; }

.menuzord-menu > li > .megamenu .megamenu-row li {
  border: 0 none;
  clear: both;
  font-size: 12px;
  margin: 0;
  padding: 2px;
  position: relative;
  width: 100%; }
  .menuzord-menu > li > .megamenu .megamenu-row li a {
    display: block;
    padding: 8px 10px;
    -webkit-transition: padding 0.2s linear;
    -o-transition: padding 0.2s linear;
    transition: padding 0.2s linear; }
  .menuzord-menu > li > .megamenu .megamenu-row li.active > a, .menuzord-menu > li > .megamenu .megamenu-row li:hover > a {
    color: #111;
    padding-left: 15px; }
  .menuzord-menu > li > .megamenu .megamenu-row li a i {
    color: #444; }
  .menuzord-menu > li > .megamenu .megamenu-row li.active a i {
    color: #fff; }

.menuzord-menu > li > .menuzord-menu > li > .megamenu .megamenu-row li:hover a i {
  color: #fff; }

/* -------- Megamenu-Row ---------- */
@media only screen and (max-width: 900px) {
  .menuzord-menu {
    background: #fff; } }

/* -------- Scrolltofixed ---------- */
.header-nav .header-nav-wrapper.scroll-to-fixed-fixed.scroll-to-fixed-fixed {
  border-bottom: 1px solid #eee; }

.header-nav .header-nav-absolute-wrapper.scroll-to-fixed-fixed.scroll-to-fixed-fixed .menuzord {
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-left: 1px solid #eee; }

.header .inner-sticky-wrapper {
  height: 80px; }

@media only screen and (min-width: 901px) {
  .header-nav .header-nav-absolute-wrapper {
    bottom: -36px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    z-index: 1111; }
    .header-nav .header-nav-absolute-wrapper.scroll-to-fixed-fixed.scroll-to-fixed-fixed {
      bottom: auto; } }

/* -------- Navbar Fixed  ---------- */
.header-nav {
  -webkit-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s; }
  .header-nav.navbar-fixed-top {
    left: 0;
    position: fixed;
    right: 0;
    width: 100%;
    z-index: 1030; }

/* -------- Navbar Sticky Animated ---------- */
.navbar-sticky-animated .header-nav-wrapper .container {
  -webkit-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s; }

/* -------- Header White ---------- */
.header-nav.navbar-white {
  background-color: rgba(255, 255, 255, 0.95);
  border: medium none; }
  .header-nav.navbar-white .menuzord {
    background: transparent; }

.header-nav.navbar-transparent .menuzord {
  background: transparent; }

.header-nav.navbar-transparent .header-nav-wrapper {
  border-bottom: 1px solid #eee; }

.header-nav.navbar-transparent.animated-active {
  background-color: rgba(0, 0, 0, 0.1); }
  .header-nav.navbar-transparent.animated-active .header-nav-wrapper {
    border-bottom: none; }
  .header-nav.navbar-transparent.animated-active .menuzord-menu > li > a {
    color: #fff; }

/* -------- Header Transparent ---------- */
/* Large Devices, Wide Screens */
@media only screen and (max-width: 1199px) {
  .menuzord-brand {
    margin: 18px 15px 0 0; } }

/* Extra Small Devices, Phones */
@media only screen and (max-width: 991px) {
  .menuzord .showhide {
    padding: 26px 0 0; } }

@media only screen and (max-width: 900px) {
  .header-nav.navbar-transparent.animated-active .menuzord-menu > li > a {
    color: #888; } }

/*
 * inner-header-title.less
 * -----------------------------------------------
*/
.inner-header .title {
  font-size: 36px;
  text-transform: uppercase; }

/*
 *  boxed-layout.less
 * -----------------------------------------------
*/
.boxed-layout .container {
  max-width: 1100px;
  padding-left: 30px;
  padding-right: 30px; }

.boxed-layout .header, .boxed-layout .main-content, .boxed-layout .footer {
  margin: 0 auto;
  max-width: 1100px; }

.boxed-layout .header, .boxed-layout section, .boxed-layout .footer {
  background-color: #fff; }

.boxed-layout .header {
  margin-bottom: 15px; }
  .boxed-layout .header .navbar-default {
    background-color: #fff;
    border-color: #fff;
    padding: 0; }
    .boxed-layout .header .navbar-default .navbar-nav > li > a {
      padding: 30px 5px; }
    .boxed-layout .header .navbar-default .navbar-collapse {
      background-color: #fff;
      border-color: #fff;
      margin-right: 30px; }
  .boxed-layout .header #header-logo {
    margin: 0;
    padding-top: 27px; }

@media only screen and (max-width: 991px) {
  .boxed-layout {
    padding-top: 0; }
    .boxed-layout .header .navbar-default .navbar-nav > li > a {
      padding: 10px; }
    .boxed-layout .header .navbar-default .navbar-collapse {
      margin-right: 15px; }
    .boxed-layout .navbar-header {
      padding: 15px 0; }
    .boxed-layout .navbar-collapse .navbar-nav li a .caret {
      margin-right: 0; } }

/*
 * form.less
 * -----------------------------------------------
*/
/* -------- Form ---------- */
.input-group-lg > .form-control, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .btn, .panel-contacto .contact-form .input-group-lg > .input-group-btn > input[type='submit'] {
  font-size: 14px; }

.form-control {
  border-radius: 0;
  box-shadow: none;
  height: 45px;
  border: 1px solid #eeeeee; }

label {
  font-weight: normal; }

.form-control:focus {
  color: #404040;
  border-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  outline: none; }

form input[type="text"]:focus, form input[type="email"]:focus, form input[type="number"]:focus, form input[type="url"]:focus, form input[type="search"]:focus, form input[type="tel"]:focus, form input[type="password"]:focus, form input[type="date"]:focus, form input[type="color"]:focus {
  color: #404040;
  border-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  outline: none; }

form select:focus, form textarea:focus {
  color: #404040;
  border-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  outline: none; }

.form-transparent .form-control {
  background: transparent;
  color: #f7f7f7; }
  .form-transparent .form-control:focus {
    color: #f7f7f7; }

.form-transparent form input[type="text"]:focus, .form-transparent form input[type="email"]:focus, .form-transparent form input[type="number"]:focus, .form-transparent form input[type="url"]:focus, .form-transparent form input[type="search"]:focus, .form-transparent form input[type="tel"]:focus, .form-transparent form input[type="password"]:focus, .form-transparent form input[type="date"]:focus, .form-transparent form input[type="color"]:focus {
  color: #f7f7f7; }

.form-transparent form select:focus, .form-transparent form textarea:focus {
  color: #f7f7f7; }

.form-transparent textarea.form-control {
  height: 150px; }

.form-white textarea.form-control {
  height: 150px; }

.form-white .form-control {
  background: #fff; }

.form-transparent.form-line .form-control {
  border: none;
  border-bottom: 1px solid #c0c0c0;
  padding-left: 0; }

.form-transparent.form-line textarea.form-control {
  height: 70px; }

form label {
  font-weight: bold; }

form .form-control.error {
  border: 2px solid #ee163b; }

form .error {
  color: #ee163b; }

/* form error */
/* -------- Donation Form ---------- */
.donation-form .form-group label.radio-inline {
  color: #808080; }

/*-------- form-text-white ---------*/
.form-text-white .form-group label {
  color: #fff; }

/*
 *  side-push-panel.less
 * -----------------------------------------------
*/
.side-push-panel {
  overflow-x: hidden; }
  .side-push-panel.fullwidth-page #wrapper {
    right: 0;
    position: relative; }
  .side-push-panel.fullwidth-page header .header-nav-wrapper.scroll-to-fixed-fixed .container {
    right: 0; }
  .side-push-panel.fullwidth-page.side-panel-left #wrapper, .side-push-panel.fullwidth-page.side-panel-left header .header-nav-wrapper.scroll-to-fixed-fixed .container {
    left: 0;
    right: auto; }
  .side-push-panel.fullwidth-page.side-panel-open #wrapper, .side-push-panel.fullwidth-page.side-panel-open header .header-nav-wrapper.scroll-to-fixed-fixed .container {
    right: 300px; }
  .side-push-panel.fullwidth-page.side-panel-left.side-panel-open #wrapper, .side-push-panel.fullwidth-page.side-panel-left.side-panel-open header .header-nav-wrapper.scroll-to-fixed-fixed .container {
    left: 300px;
    right: auto; }
  .side-push-panel.has-side-panel .menuzord .showhide {
    float: left; }

/* -------- Has Side Panel ---------- */
/* -------- Body Overlay ---------- */
.has-side-panel .body-overlay {
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1; }

.has-side-panel.side-panel-open .body-overlay {
  opacity: 1;
  pointer-events: auto;
  z-index: 1111; }

.side-panel-open:not(.device-xxs):not(.device-xs):not(.device-sm) .body-overlay:hover {
  cursor: url("../images/close.png") 15 15, default; }

/* -------- Side Panel ---------- */
#side-panel {
  -webkit-backface-visibility: hidden;
  background-color: #f3f3f3;
  height: 100%;
  overflow: hidden;
  position: fixed;
  right: -300px;
  top: 0;
  width: 300px;
  z-index: 1112;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease; }
  #side-panel .side-panel-wrap {
    bottom: 0;
    height: 100%;
    left: 0;
    overflow: auto;
    padding: 50px 70px 50px 40px;
    position: absolute;
    top: 0;
    width: 330px; }
  #side-panel .widget {
    border-top: 1px solid #eeeeee;
    margin-top: 40px;
    padding-top: 40px;
    width: 220px; }
    #side-panel .widget:first-child {
      border-top: 0;
      margin: 0;
      padding-top: 0; }

.side-panel-open #side-panel {
  right: 0; }

.side-panel-left #side-panel {
  left: -300px;
  right: auto; }

.side-panel-left.side-panel-open #side-panel {
  left: 0;
  right: auto; }

.device-touch #side-panel .side-panel-wrap {
  overflow-y: scroll; }

/* -------- Side-Panel-Nav ---------- */
#side-panel .side-panel-nav .nav li a {
  padding: 5px 0; }
  #side-panel .side-panel-nav .nav li a:hover {
    background-color: transparent;
    color: #a9a9a9; }

#side-panel .side-panel-nav .nav ul.tree {
  display: none;
  margin-left: 15px; }

/* -------- Side-Panel-Trigger And Close ---------- */
#side-panel-trigger {
  cursor: pointer;
  display: block;
  float: right;
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 25px;
  z-index: 11; }

@media only screen and (max-width: 767px) {
  #side-panel-trigger {
    margin-top: 15px; } }

#side-panel-trigger-close {
  left: 0;
  margin: 10px;
  position: absolute;
  top: 0; }

/* -------- Transition ---------- */
.side-push-panel.fullwidth-page #wrapper, .side-push-panel.fullwidth-page header .header-nav-wrapper.scroll-to-fixed-fixed .container {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease; }

/*
 *  box-hover-effect.less
 * -----------------------------------------------
*/
.box-hover-effect {
  display: inline-block;
  vertical-align: middle; }
  .box-hover-effect .effect-wrapper {
    position: relative;
    overflow: hidden;
    margin: 0;
    width: 100%;
    /* Icons Holder */
    /* Icons Holder middle */
    /* Icons Holder top left */
    /* Icons Holder top right */
    /* Icons Holder bottom left */
    /* Icons Holder bottom right */
    /* Text Holder */
    /* Text Holder middle */
    /* Text Holder top */
    /* Text Holder bottom */ }
    .box-hover-effect .effect-wrapper .thumb {
      position: relative;
      overflow: hidden;
      backface-visibility: hidden;
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
      -webkit-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease; }
      .box-hover-effect .effect-wrapper .thumb.gradient-effect:after {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(360deg, rgba(0, 0, 0, 0.6) 10px, transparent); }
    .box-hover-effect .effect-wrapper .hover-link {
      bottom: 0;
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      background: none !important;
      color: transparent !important;
      z-index: 11; }
    .box-hover-effect .effect-wrapper .overlay-shade {
      bottom: 0;
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10;
      background-color: #000;
      opacity: 0;
      filter: alpha(opacity=0);
      -webkit-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease; }
      .box-hover-effect .effect-wrapper .overlay-shade.shade-white {
        background-color: #fff; }
    .box-hover-effect .effect-wrapper .icons-holder {
      display: inline-block;
      opacity: 0;
      position: absolute;
      z-index: 13;
      -webkit-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease; }
      .box-hover-effect .effect-wrapper .icons-holder .icons-holder-inner {
        position: relative;
        margin: 0; }
        .box-hover-effect .effect-wrapper .icons-holder .icons-holder-inner a {
          -webkit-transition: all 0.4s ease;
          -o-transition: all 0.4s ease;
          transition: all 0.4s ease; }
    .box-hover-effect .effect-wrapper .icons-holder-middle {
      left: 0;
      margin: 0 auto;
      right: 0;
      text-align: center;
      top: 40%;
      vertical-align: middle;
      width: 80%; }
      .box-hover-effect .effect-wrapper .icons-holder-middle .icons-holder-inner {
        display: inline-block;
        vertical-align: middle; }
        .box-hover-effect .effect-wrapper .icons-holder-middle .icons-holder-inner a {
          -webkit-transition: all 0.4s ease;
          -o-transition: all 0.4s ease;
          transition: all 0.4s ease; }
    .box-hover-effect .effect-wrapper .icons-holder-top-left {
      top: -20%;
      left: 0;
      padding: 15px; }
      .box-hover-effect .effect-wrapper .icons-holder-top-left .icons-holder-inner {
        display: block; }
    .box-hover-effect .effect-wrapper .icons-holder-top-right {
      top: -20%;
      right: 0;
      padding: 15px; }
      .box-hover-effect .effect-wrapper .icons-holder-top-right .icons-holder-inner {
        display: block; }
    .box-hover-effect .effect-wrapper .icons-holder-bottom-left {
      bottom: -20%;
      left: 0;
      padding: 5px 15px; }
      .box-hover-effect .effect-wrapper .icons-holder-bottom-left .icons-holder-inner {
        display: block; }
    .box-hover-effect .effect-wrapper .icons-holder-bottom-right {
      bottom: -20%;
      right: 0;
      padding: 5px 15px; }
      .box-hover-effect .effect-wrapper .icons-holder-bottom-right .icons-holder-inner {
        display: block; }
    .box-hover-effect .effect-wrapper .text-holder {
      display: inline-block;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      -webkit-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      z-index: 13; }
      .box-hover-effect .effect-wrapper .text-holder .title {
        color: #fff; }
      .box-hover-effect .effect-wrapper .text-holder .text_holder_inner {
        display: table;
        height: 100%;
        position: relative;
        width: 100%; }
      .box-hover-effect .effect-wrapper .text-holder .text_holder_inner2 {
        display: table-cell;
        height: 100%;
        position: relative;
        text-align: center;
        vertical-align: middle;
        width: 100%; }
    .box-hover-effect .effect-wrapper .overlay-shade.shade-white + .text-holder .title {
      color: #333; }
    .box-hover-effect .effect-wrapper .text-holder-middle {
      display: block;
      left: 0;
      margin: 0 auto;
      right: 0;
      text-align: center;
      top: 30%;
      vertical-align: middle;
      width: 80%; }
    .box-hover-effect .effect-wrapper .text-holder-top {
      display: block;
      left: 0;
      margin: 0 auto;
      right: 0;
      text-align: center;
      top: -40px;
      vertical-align: middle;
      width: 80%; }
    .box-hover-effect .effect-wrapper .text-holder-bottom {
      display: block;
      left: 0;
      margin: 0 auto;
      right: 0;
      text-align: center;
      bottom: -40px;
      vertical-align: middle;
      width: 80%; }
    .box-hover-effect .effect-wrapper .text-holder-top-left {
      left: 0;
      padding: 5px 15px;
      top: -40px;
      width: auto; }
    .box-hover-effect .effect-wrapper .text-holder-top-right {
      right: 0;
      padding: 5px 15px;
      top: -40px;
      width: auto; }
    .box-hover-effect .effect-wrapper .text-holder-bottom-left {
      left: 0;
      padding: 5px 15px;
      bottom: -40px;
      width: auto; }
    .box-hover-effect .effect-wrapper .text-holder-bottom-right {
      right: 0;
      padding: 5px 15px;
      bottom: -40px;
      width: auto; }
  .box-hover-effect:hover .effect-wrapper .thumb {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05); }
  .box-hover-effect:hover .effect-wrapper .overlay-shade {
    opacity: 0.8;
    filter: alpha(opacity=80); }
  .box-hover-effect:hover .effect-wrapper .icons-holder {
    opacity: 1; }
  .box-hover-effect:hover .effect-wrapper .icons-holder-middle {
    top: 45%; }
  .box-hover-effect:hover .effect-wrapper .icons-holder-top-left, .box-hover-effect:hover .effect-wrapper .icons-holder-top-right {
    top: 0; }
  .box-hover-effect:hover .effect-wrapper .icons-holder-bottom-left, .box-hover-effect:hover .effect-wrapper .icons-holder-bottom-right {
    bottom: 2%; }
  .box-hover-effect:hover .effect-wrapper .text-holder {
    opacity: 1; }
  .box-hover-effect:hover .effect-wrapper .text-holder-middle {
    top: 40%; }
  .box-hover-effect:hover .effect-wrapper .text-holder-top {
    top: 20px; }
  .box-hover-effect:hover .effect-wrapper .text-holder-bottom {
    bottom: 20px; }
  .box-hover-effect:hover .effect-wrapper .text-holder-top-left, .box-hover-effect:hover .effect-wrapper .text-holder-top-right {
    top: 0; }
  .box-hover-effect:hover .effect-wrapper .text-holder-bottom-left, .box-hover-effect:hover .effect-wrapper .text-holder-bottom-right {
    bottom: 0; }

.shadow-overlay {
  background-image: url("../images/shadow-overlay.png");
  background-position: center bottom;
  background-repeat: repeat-x;
  bottom: 0;
  color: #fff;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%; }

/* Individual effects */
/* Individual effect = image-zoom-in */
.box-hover-effect.image-zoom-in .thumb {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

.box-hover-effect.image-zoom-in:hover .thumb {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1); }

.box-hover-effect.image-zoom-out .thumb {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1); }

.box-hover-effect.image-zoom-out:hover .thumb {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

.box-hover-effect.play-button {
  /* Text Holder */ }
  .box-hover-effect.play-button .thumb {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  .box-hover-effect.play-button:hover .thumb {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05); }
  .box-hover-effect.play-button .text-holder {
    opacity: 1; }
  .box-hover-effect.play-button .text-holder-middle {
    top: 34%;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  .box-hover-effect.play-button:hover .text-holder-middle {
    top: 34%;
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2); }
  .box-hover-effect.play-button .video-button {
    z-index: 11;
    position: absolute;
    top: 50%;
    left: 50%;
    background: rgba(255, 255, 255, 0.8);
    width: 60px;
    height: 60px;
    border-radius: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease; }
    .box-hover-effect.play-button .video-button:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid #666;
      -webkit-transform: translate(-15%, -50%);
      transform: translate(-15%, -50%);
      -webkit-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease; }
  .box-hover-effect.play-button:hover .video-button {
    background: rgba(255, 51, 51, 0.9);
    width: 80px;
    height: 80px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.3); }
    .box-hover-effect.play-button:hover .video-button:before {
      border-left: 10px solid #fff; }

.box-hover-effect.effect-alaska .bottom-info-box {
  background: rgba(0, 0, 0, 0.9) none repeat scroll 0 0;
  bottom: 0;
  left: 0;
  padding: 15px 30px 0;
  position: absolute;
  right: 0;
  top: 75%;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .box-hover-effect.effect-alaska .bottom-info-box .invisible-parts {
    display: none; }

.box-hover-effect.effect-alaska:hover .bottom-info-box {
  padding-top: 38px;
  top: 0; }
  .box-hover-effect.effect-alaska:hover .bottom-info-box .invisible-parts {
    display: block; }

.box-hover-effect.effect-siberia .info-box .info-title {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px 70px 15px 15px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0); }
  .box-hover-effect.effect-siberia .info-box .info-title:before {
    color: #fff;
    content: "\f054";
    font-family: "fontAwesome";
    position: absolute;
    text-align: center;
    line-height: 50px;
    bottom: 5px;
    top: 7px;
    right: 7px;
    width: 50px;
    height: 50px;
    background-color: rgba(9, 9, 9, 0.3);
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    font-size: 18px; }

.box-hover-effect.effect-siberia .info-box .info-content {
  color: #ffffff;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  padding: 30px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  visibility: hidden;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.box-hover-effect.effect-siberia:hover .info-box .info-title {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%); }

.box-hover-effect.effect-siberia:hover .info-box .info-content {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  visibility: visible;
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100); }

.box-hover-effect.effect-florida .info-box .info-title {
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 15px;
  padding: 15px 70px 15px 15px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0); }
  .box-hover-effect.effect-florida .info-box .info-title:before {
    color: #fff;
    content: "\f054";
    font-family: "fontAwesome";
    position: absolute;
    text-align: center;
    line-height: 50px;
    bottom: 5px;
    top: 7px;
    right: 7px;
    width: 50px;
    height: 50px;
    background-color: rgba(9, 9, 9, 0.3);
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    font-size: 18px; }

.box-hover-effect.effect-florida .info-box .info-content {
  color: #ffffff;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  padding: 30px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  visibility: hidden;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.box-hover-effect.effect-florida:hover .info-box .info-title {
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%); }

.box-hover-effect.effect-florida:hover .info-box .info-content {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100); }

.box-hover-effect.effect-london .text-holder {
  top: -30px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }

.box-hover-effect.effect-london:hover .text-holder {
  top: 0; }

.box-hover-effect.effect-rome:hover .text-holder {
  top: auto;
  bottom: 0; }

.box-hover-effect.effect-paris:hover .thumb {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transform: translate(0, -70px);
  -ms-transform: translate(0, -70px);
  -o-transform: translate(0, -70px);
  transform: translate(0, -70px); }

.box-hover-effect.effect-paris .text-holder {
  background: #333;
  padding: 5px 15px 5px;
  top: auto;
  bottom: -30px;
  width: 100%;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }

.box-hover-effect.effect-paris:hover .text-holder {
  top: auto;
  bottom: 0; }

.box-hover-effect.effect-barlin:hover .thumb {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

.box-hover-effect.effect-barlin .text-holder-middle {
  bottom: 0;
  padding: 50px;
  top: 0; }
  .box-hover-effect.effect-barlin .text-holder-middle .text_holder_inner .text_holder_inner2 .title1 {
    opacity: 0;
    -webkit-transform: translate(0, 10px);
    -ms-transform: translate(0, 10px);
    -o-transform: translate(0, 10px);
    transform: translate(0, 10px);
    -webkit-transition: all 0.4s ease 0.2s;
    -o-transition: all 0.4s ease 0.2s;
    transition: all 0.4s ease 0.2s; }
  .box-hover-effect.effect-barlin .text-holder-middle .text_holder_inner .text_holder_inner2 .title2 {
    opacity: 0;
    -webkit-transform: translate(0, 10px);
    -ms-transform: translate(0, 10px);
    -o-transform: translate(0, 10px);
    transform: translate(0, 10px);
    -webkit-transition: all 0.4s ease 0.3s;
    -o-transition: all 0.4s ease 0.3s;
    transition: all 0.4s ease 0.3s; }

.box-hover-effect.effect-barlin:hover .text-holder-middle {
  top: 0; }
  .box-hover-effect.effect-barlin:hover .text-holder-middle .text_holder_inner .text_holder_inner2 .title1, .box-hover-effect.effect-barlin:hover .text-holder-middle .text_holder_inner .text_holder_inner2 .title2 {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0); }

.box-hover-effect.effect-barlin .hover-link {
  z-index: 13; }

/* Individual effect = image-zoom-out */
/* Individual effect = play button */
/* Individual effect = alaska */
/* Individual effect = siberia */
/* Individual effect = florida */
/* Individual effect = london */
/* Individual effect = rome */
/* Individual effect = paris */
/* Individual effect = barlin */
/*
 * gallery-isotope.less
 * -----------------------------------------------
*/
/* -------- Portfolio Isotop Filter ---------- */
.portfolio-filter {
  margin-bottom: 30px; }
  .portfolio-filter a {
    border-left: 3px solid transparent;
    background-color: #f8f8f8;
    display: inline-block;
    float: none;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin-right: 5px;
    outline: medium none;
    padding: 5px 15px;
    -webkit-transition: all 100ms ease-in-out 0s;
    -o-transition: all 100ms ease-in-out 0s;
    transition: all 100ms ease-in-out 0s; }
    .portfolio-filter a.active, .portfolio-filter a:hover {
      color: #fff; }

@media only screen and (max-width: 479px) {
  .portfolio-filter a {
    margin-bottom: 10px; } }

/* -------- Portfolio Isotop Gallery ---------- */
.gallery-isotope {
  /*gutter*/
  /* by default 4 grids */
  /*portfolio 2 grids*/
  /*portfolio 2 grids*/
  /*portfolio 3 grids*/
  /*portfolio 4 grids*/
  /*portfolio 5 grids*/
  /*portfolio 6 grids*/
  /*portfolio 7 grids*/
  /*portfolio 8 grids*/
  /*portfolio 9 grids*/
  /*portfolio 10 grids*/ }
  .gallery-isotope.gutter .gallery-item {
    padding-right: 5px;
    padding-bottom: 5px; }
  .gallery-isotope.gutter-small .gallery-item {
    padding-right: 2px;
    padding-bottom: 2px; }
  .gallery-isotope.gutter-30 .gallery-item {
    padding-right: 30px;
    padding-bottom: 30px; }
  .gallery-isotope.gutter-40 .gallery-item {
    padding-right: 40px;
    padding-bottom: 40px; }
  .gallery-isotope.gutter-50 .gallery-item {
    padding-right: 50px;
    padding-bottom: 50px; }
  .gallery-isotope.gutter-60 .gallery-item {
    padding-right: 60px;
    padding-bottom: 60px; }
  .gallery-isotope .gallery-item {
    width: 25%; }
    .gallery-isotope .gallery-item.wide {
      width: 50%; }
  .gallery-isotope.grid-1 .gallery-item {
    width: 100%; }
    .gallery-isotope.grid-1 .gallery-item.wide {
      width: 100%; }
  .gallery-isotope.grid-2 .gallery-item {
    width: 50%; }
    .gallery-isotope.grid-2 .gallery-item.wide {
      width: 100%; }
  .gallery-isotope.grid-3 .gallery-item {
    width: 33.333%; }
    .gallery-isotope.grid-3 .gallery-item.wide {
      width: 66.6667%; }
  .gallery-isotope.grid-4 .gallery-item {
    width: 25%; }
    .gallery-isotope.grid-4 .gallery-item.wide {
      width: 50%; }
  .gallery-isotope.grid-5 .gallery-item {
    width: 20%; }
    .gallery-isotope.grid-5 .gallery-item.wide {
      width: 40%; }
  .gallery-isotope.grid-6 .gallery-item {
    width: 16.666666667%; }
    .gallery-isotope.grid-6 .gallery-item.wide {
      width: 33.333%; }
  .gallery-isotope.grid-7 .gallery-item {
    width: 14.2857143%; }
    .gallery-isotope.grid-7 .gallery-item.wide {
      width: 28.5714286%; }
  .gallery-isotope.grid-8 .gallery-item {
    width: 12.5%; }
    .gallery-isotope.grid-8 .gallery-item.wide {
      width: 25%; }
  .gallery-isotope.grid-9 .gallery-item {
    width: 11.11%; }
    .gallery-isotope.grid-9 .gallery-item.wide {
      width: 22.22%; }
  .gallery-isotope.grid-10 .gallery-item {
    width: 10%; }
    .gallery-isotope.grid-10 .gallery-item.wide {
      width: 20%; }
  .gallery-isotope .gallery-item {
    overflow: hidden; }
    .gallery-isotope .gallery-item .thumb {
      position: relative;
      overflow: hidden; }
    .gallery-isotope .gallery-item .hover-link {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      height: 100%;
      background: none !important;
      color: transparent !important;
      z-index: 11; }
    .gallery-isotope .gallery-item .overlay-shade {
      position: absolute;
      display: inline-block;
      vertical-align: middle;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 12;
      opacity: 0;
      filter: alpha(opacity=0);
      -webkit-transition: opacity 0.3s ease-in-out;
      -o-transition: opacity 0.3s ease-in-out;
      transition: opacity 0.3s ease-in-out; }
    .gallery-isotope .gallery-item:hover .overlay-shade {
      opacity: 0.8;
      filter: alpha(opacity=90); }
    .gallery-isotope .gallery-item .icons-holder {
      position: absolute;
      top: 0%;
      left: 50%;
      display: inline-block;
      width: auto;
      height: 36px;
      vertical-align: middle;
      z-index: 13;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .gallery-isotope .gallery-item .icons-holder .icons-holder-inner {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin: -18px 0 0 -50%; }
        .gallery-isotope .gallery-item .icons-holder .icons-holder-inner a {
          opacity: 0;
          filter: alpha(opacity=0);
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out; }
    .gallery-isotope .gallery-item:hover .icons-holder {
      top: 50%; }
      .gallery-isotope .gallery-item:hover .icons-holder .icons-holder-inner a {
        opacity: 1;
        filter: alpha(opacity=1); }
    .gallery-isotope .gallery-item .portfolio-description {
      padding: 10px 15px;
      background-color: #f8f8f8; }
      .gallery-isotope .gallery-item .portfolio-description .title {
        margin: 0; }
        .gallery-isotope .gallery-item .portfolio-description .title a {
          color: #333333; }
    .gallery-isotope .gallery-item .text-holder {
      box-sizing: border-box;
      display: block;
      height: 100%;
      left: 0;
      opacity: 0;
      overflow: hidden;
      padding: 25px 30px 60px;
      position: absolute;
      top: 0;
      -webkit-transition: opacity 0.3s ease-in-out 0s;
      -o-transition: opacity 0.3s ease-in-out 0s;
      transition: opacity 0.3s ease-in-out 0s;
      width: 100%;
      z-index: 13; }
      .gallery-isotope .gallery-item .text-holder .title {
        color: #fff; }
    .gallery-isotope .gallery-item:hover .text-holder {
      opacity: 1; }
  .gallery-isotope .flex-control-nav {
    z-index: 111; }

/* -------- Portfolio Isotop Item Media Query ---------- */
@media only screen and (max-width: 991px) {
  .gallery-isotope .gallery-item {
    width: 33.333% !important; }
    .gallery-isotope .gallery-item.wide {
      width: 66.6667% !important; } }

@media only screen and (max-width: 767px) {
  .gallery-isotope .gallery-item {
    width: 50% !important; }
    .gallery-isotope .gallery-item.wide {
      width: 100% !important; } }

@media only screen and (max-width: 479px) {
  .gallery-isotope .gallery-item {
    width: 100% !important; }
    .gallery-isotope .gallery-item.wide {
      width: 100% !important; } }

/* -------- Portfolio Isotop Overlay Effect ---------- */
/*
 * slider.less
 * -----------------------------------------------
*/
/* -------- Rev Slider ---------- */
#home .revslider-fullscreen {
  min-height: auto; }

/* -------- Home Revolution Slider For Minimal Layouts ---------- */
/* -------- Tab-Slider ---------- */
.tab-slider .tab-content {
  padding: 0;
  border: 0; }

.tab-slider .nav.nav-pills > div {
  background-color: #fff;
  float: left;
  margin-bottom: 15px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }

.tab-slider .nav.nav-pills i {
  color: #d3d3d3; }

.tab-slider .nav.nav-pills a {
  display: block;
  padding: 40px 30px;
  background: #f3f3f3; }
  .tab-slider .nav.nav-pills a:hover i, .tab-slider .nav.nav-pills a:hover h6 {
    color: #fff; }
  .tab-slider .nav.nav-pills a.active i, .tab-slider .nav.nav-pills a.active h6 {
    color: #fff; }

/* -------- MaxImage Slider ---------- */
.maximage-slider .fullscreen-controls .img-prev, .maximage-slider .fullscreen-controls .img-next {
  background: rgba(0, 0, 0, 0.3); }

/* -------- Testimonial-Carousel ---------- */
.testimonial-carousel, .testimonial-carousel-style2 {
  /* fullwidth */ }

.testimonial-carousel .quote, .testimonial-carousel-style2 .quote, .testimonial-carousel .thumb, .testimonial-carousel-style2 .thumb {
  margin-bottom: 40px; }

.testimonial-carousel .quote i, .testimonial-carousel-style2 .quote i {
  background: #555555;
  border-radius: 50%;
  color: #fff;
  font-size: 25px;
  height: 60px;
  line-height: 1;
  padding: 20px 0 0;
  text-align: center;
  width: 60px; }

.testimonial-carousel .author, .testimonial-carousel-style2 .author {
  margin-top: 30px;
  text-transform: uppercase; }

.testimonial-carousel.owl-theme .owl-controls, .testimonial-carousel-style2.owl-theme .owl-controls {
  margin-top: 10px; }

.testimonial-carousel.fullwidth .content p, .testimonial-carousel-style2.fullwidth .content p {
  font-size: 15px;
  font-style: italic;
  color: #fff; }

.testimonial-carousel.fullwidth .content p::after, .testimonial-carousel-style2.fullwidth .content p::after {
  content: "\f10e";
  font-family: fontawesome;
  font-size: 100px;
  left: 42%;
  opacity: 0.1;
  position: absolute; }

.testimonial-carousel.bullet-white .owl-dots span, .testimonial-carousel-style2.bullet-white .owl-dots span {
  background: #fff; }

.testimonial-carousel .owl-item img, .testimonial-carousel-style2 .owl-item img {
  display: inline-block;
  width: auto;
  max-width: 90px; }

.testimonial-carousel.boxed .quote, .testimonial-carousel-style2.boxed .quote, .testimonial-carousel.boxed .thumb, .testimonial-carousel-style2.boxed .thumb {
  margin-bottom: 20px; }

.testimonial-carousel.boxed .owl-item img, .testimonial-carousel-style2.boxed .owl-item img {
  display: inline-block;
  width: auto;
  max-width: 65px; }

.testimonial-carousel.boxed .content::after, .testimonial-carousel-style2.boxed .content::after {
  bottom: 12px;
  content: "\f10e";
  font-family: fontawesome;
  font-size: 55px;
  opacity: 0.2;
  position: absolute;
  right: 10px; }

.testimonial-carousel.boxed .owl-controls, .testimonial-carousel-style2.boxed .owl-controls {
  margin-top: 0; }

.testimonial-carousel.boxed.owl-theme .owl-dots .owl-dot.active span, .testimonial-carousel-style2.boxed.owl-theme .owl-dots .owl-dot.active span, .testimonial-carousel.boxed.owl-theme .owl-dots .owl-dot:hover span, .testimonial-carousel-style2.boxed.owl-theme .owl-dots .owl-dot:hover span {
  opacity: 1; }

/* -------- Widget-Testimonial-Carousel ---------- */
.widget-testimonial-carousel .item img {
  max-width: 75px;
  float: right; }

/*
 * owl-carousel.less
 * -----------------------------------------------
*/
.owl-carousel {
  /* owl dots */ }
  .owl-carousel .owl-item img {
    display: inline-block;
    width: auto; }
  .owl-carousel .owl-dots {
    text-align: center;
    margin-top: 10px; }
    .owl-carousel .owl-dots .owl-dot {
      display: inline-block;
      zoom: 1; }
      .owl-carousel .owl-dots .owl-dot span {
        background: #D6D6D6;
        border-radius: 50%;
        display: block;
        height: 12px;
        margin: 5px 3px;
        opacity: 0.8;
        -webkit-transition: all 0.4s ease 0s;
        -o-transition: all 0.4s ease 0s;
        transition: all 0.4s ease 0s;
        width: 12px; }
      .owl-carousel .owl-dots .owl-dot.active span, .owl-carousel .owl-dots .owl-dot:hover span {
        background: #869791; }
  .owl-carousel .owl-nav button {
    border-radius: 0;
    display: block;
    margin: -32px auto;
    position: absolute;
    opacity: 0.4;
    top: 50%;
    z-index: 6;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s; }
    .owl-carousel .owl-nav button i {
      color: #555;
      vertical-align: middle;
      font-size: 16px; }
    .owl-carousel .owl-nav button.owl-prev {
      left: 0;
      background: rgba(240, 240, 240, 0.8);
      padding: 10px; }
    .owl-carousel .owl-nav button.owl-next {
      right: 0;
      background: rgba(240, 240, 240, 0.8);
      padding: 10px; }
  .owl-carousel:hover .owl-nav > button {
    opacity: 1; }

/* Owl Dots	*/
.owl-dots-bottom-right .owl-dots {
  bottom: -10px;
  position: absolute;
  right: 0; }

.owl-dots-center-bottom .owl-dots {
  bottom: 15px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center; }

/* Image Carousel */
.image-carousel .owl-controls .owl-nav > div {
  opacity: 0; }

.image-carousel:hover .owl-controls .owl-nav > div {
  opacity: 1; }

/* Owl top nav style */
.owl-carousel.owl-nav-top.bullet-white .owl-dots span {
  background: #fff; }

.owl-carousel.owl-nav-top .owl-controls .owl-nav > div {
  background-color: transparent;
  top: -60px;
  right: 0; }
  .owl-carousel.owl-nav-top .owl-controls .owl-nav > div:hover {
    background-color: #d3d3d3; }

.owl-carousel.owl-nav-top .owl-controls .owl-nav .owl-prev {
  right: 35px; }

.owl-carousel.owl-nav-top .owl-controls .owl-nav .owl-next {
  right: 0; }

.owl-carousel.owl-nav-top .owl-controls .owl-nav .owl-prev, .owl-carousel.owl-nav-top .owl-controls .owl-nav .owl-next {
  background: #eeeeee;
  border: none;
  line-height: 28px;
  padding: 0 12px;
  text-align: center; }

.owl-carousel.owl-nav-top .owl-controls .owl-nav .owl-prev i, .owl-carousel.owl-nav-top .owl-controls .owl-nav .owl-next i {
  color: #888;
  font-size: 14px; }

.owl-carousel.no-bullet .owl-nav {
  display: none; }

.recent-project .owl-controls .owl-nav > div {
  top: 38%; }

.recent-project .owl-controls .owl-nav .owl-prev i, .recent-project .owl-controls .owl-nav .owl-next i {
  padding: 2px 16px; }

.featured-gallery.style2 {
  float: right;
  width: 92%; }
  .featured-gallery.style2 .owl-controls {
    bottom: 58px;
    left: -80px;
    margin: 0;
    position: absolute; }
    .featured-gallery.style2 .owl-controls .owl-nav > div {
      top: 95%; }
    .featured-gallery.style2 .owl-controls .owl-nav .owl-prev {
      left: 0px; }
    .featured-gallery.style2 .owl-controls .owl-nav .owl-next {
      display: inline-table;
      left: 40px; }
    .featured-gallery.style2 .owl-controls .owl-nav .owl-prev i, .featured-gallery.style2 .owl-controls .owl-nav .owl-next i {
      color: #fff;
      font-size: 35px; }

@media only screen and (max-width: 991px) {
  .featured-gallery.style2 {
    float: none;
    margin-top: 58px;
    width: 100%; }
    .featured-gallery.style2 .owl-controls {
      bottom: auto;
      left: auto;
      top: -45px; } }

.image-carousel .owl-controls .owl-nav > div {
  opacity: 0; }

.image-carousel:hover .owl-controls .owl-nav > div {
  opacity: 1; }

/* -------- Owl Fullwidth Carousel ---------- */
.fullwidth-carousel .owl-controls .owl-nav > div {
  top: 45%; }

.fullwidth-carousel .carousel-item {
  min-height: 615px;
  width: 100%; }

.fullwidth-carousel.blog-fullwidth-carousel .carousel-item {
  height: 600px;
  min-height: auto;
  width: 100%; }

.fullwidth-carousel.blog-fullwidth-carousel .owl-controls .owl-nav > div {
  background: none; }

/* -------- Twitter Feed Carousel ---------- */
.owl-dots-bottom-right .owl-dots {
  bottom: -10px;
  position: absolute;
  right: 0; }

/* -------- Twitter Feed Carousel ---------- */
.twitter-feed.twitter-carousel {
  color: #111111;
  font-size: 16px; }
  .twitter-feed.twitter-carousel a {
    color: #111111; }

.twitter-feed .date {
  font-size: 12px; }

.twitter-feed.twitter-white {
  color: #fff; }
  .twitter-feed.twitter-white a {
    color: #fff; }

/* -------- BX Slider ---------- */
.bx-wrapper {
  margin: 0 auto 7px;
  padding: 0;
  position: relative; }
  .bx-wrapper .bx-viewport {
    background: inherit;
    border: 0 solid #fff;
    box-shadow: 0 0 0 #ccc;
    left: 0;
    transform: translateZ(0px); }

/* bx top nav style */
.bx-controls .bx-controls-direction > a {
  background: #eee none repeat scroll 0 0;
  border: medium none;
  height: auto;
  line-height: 28px;
  padding: 0 12px;
  width: auto;
  text-indent: 0;
  top: -30px;
  z-index: 0; }
  .bx-controls .bx-controls-direction > a:hover {
    background: #ddd; }
  .bx-controls .bx-controls-direction > a i {
    color: #888; }

.bx-controls .bx-controls-direction .bx-prev {
  right: 35px;
  left: auto; }

.bx-controls .bx-controls-direction .bx-next {
  right: 0; }

/* -------- Typed Animation Slider ---------- */
.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite; }

@keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/*
 * home.less
 * -----------------------------------------------
*/
/* -------- Setion-Bottom-Absolute-Div ---------- */
.setion-bottom-absolute-div {
  background: rgba(255, 255, 255, 0.5) none repeat scroll 0 0;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0; }

/*
 * about.less
 * -----------------------------------------------
*/
.top-course-thumb {
  position: relative; }
  .top-course-thumb::after {
    background: #000 none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all 200ms ease-in-out 0s;
    -o-transition: all 200ms ease-in-out 0s;
    transition: all 200ms ease-in-out 0s;
    width: 100%; }
  .top-course-thumb:hover::after {
    opacity: 0.8;
    border: 8px solid rgba(255, 255, 255, 0.15); }
  .top-course-thumb .desc-box {
    left: 0;
    margin: -15px auto;
    position: absolute;
    right: 0;
    height: 100%;
    text-align: center;
    top: 30px; }
    .top-course-thumb .desc-box .title {
      color: #fff;
      font-size: 22px;
      font-weight: 600;
      left: 0;
      margin: -15px auto;
      position: absolute;
      right: 0;
      text-align: center;
      top: 35%;
      z-index: 999; }
    .top-course-thumb .desc-box .off {
      font-size: 42px;
      font-weight: 600;
      left: 0;
      margin: -15px auto;
      position: absolute;
      right: 0;
      text-align: center;
      top: 45%;
      z-index: 999; }
    .top-course-thumb .desc-box .signup {
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      left: 0;
      margin: -15px auto;
      position: absolute;
      right: 0;
      text-align: center;
      text-decoration: underline;
      top: 64%;
      z-index: 999; }

/* Individual effect = play button */
.about-video {
  /* Text Holder */ }
  .about-video .thumb, .about-video:hover .thumb {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  .about-video .text-holder {
    opacity: 1; }
  .about-video .text-holder-middle {
    top: 34%;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  .about-video:hover .text-holder-middle {
    top: 34%;
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2); }
  .about-video .video-button {
    z-index: 11;
    position: absolute;
    top: 50%;
    left: 50%;
    background: rgba(255, 255, 255, 0.8);
    width: 60px;
    height: 60px;
    border-radius: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    -webkit-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease; }
    .about-video .video-button:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid #666;
      -webkit-transform: translate(-15%, -50%);
      transform: translate(-15%, -50%);
      -webkit-transition: all 200ms ease;
      -o-transition: all 200ms ease;
      transition: all 200ms ease; }
  .about-video:hover .video-button {
    width: 65px;
    height: 65px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.3); }
    .about-video:hover .video-button:before {
      border-left: 10px solid #fff; }

/*
 * features.less
 * -----------------------------------------------
*/
.feature-icon {
  display: block;
  margin: 0 auto;
  width: 100%; }
  .feature-icon > img {
    width: 65px; }

.feature-title {
  text-align: center;
  padding-left: 0; }

.feature-box {
  width: auto; }
  .feature-box:hover .feature-icon {
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-name: bounce; }

/* Small Devices, Tablets */
@media only screen and (max-width: 767px) {
  .feature-box {
    width: 100%; } }

/*
 * causes.less
 * -----------------------------------------------
*/
.course-thumb {
  position: relative; }

.price-tag {
  border-radius: 0;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 0 10px;
  position: absolute;
  right: -7px;
  text-transform: capitalize;
  top: 16px;
  -webkit-transition: all 150ms ease-in-out 0s;
  -o-transition: all 150ms ease-in-out 0s;
  transition: all 150ms ease-in-out 0s; }

.course-single-item:hover .price-tag {
  padding: 2px 16px; }

.price-tag::before {
  border-left: 7px solid rgba(0, 0, 0, 0.45);
  border-top: 7px solid transparent;
  content: "";
  height: 0;
  position: absolute;
  right: -1px;
  top: 0;
  transform: translateY(-100%);
  width: 0; }

.course-meta li {
  border-right: 1px solid #ddd;
  padding: 0 18px; }
  .course-meta li:last-child {
    border-right: medium none; }
  .course-meta li h6 {
    color: #444;
    font-size: 14px;
    font-weight: 600;
    margin: 5px 0 0; }
  .course-meta li span {
    font-size: 13px;
    margin: 0; }

@media only screen and (max-width: 1199px) {
  .top-course-thumb .desc-box .title {
    font-size: 18px;
    top: 35%; }
  .top-course-thumb .desc-box .off {
    font-size: 35px;
    top: 46%; } }

/* Medium Devices, Desktops */
@media only screen and (max-width: 991px) {
  .top-course-thumb {
    max-width: 430px; }
    .top-course-thumb .desc-box .off {
      font-size: 46px;
      top: 46%; }
    .top-course-thumb .desc-box .title {
      font-size: 24px;
      top: 38%; }
    .top-course-thumb .desc-box .signup {
      top: 60%; } }

/*
 * volunteer.less
 * -----------------------------------------------
*/
.volunteer {
  position: relative; }
  .volunteer .overlay {
    height: 0;
    left: 0;
    margin: 0 auto;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s; }
  .volunteer .content {
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all 0.2s ease-in-out 0s;
    -o-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s; }
  .volunteer:hover .overlay {
    opacity: 1;
    height: 100%; }
  .volunteer:hover .content {
    opacity: 1;
    top: 30%; }
  .volunteer.border {
    border: 3px solid #fff; }
  .volunteer .info {
    padding: 15px 0;
    padding-bottom: 0;
    text-align: center; }
    .volunteer .info .name {
      font-weight: 700;
      margin: 0px; }
      .volunteer .info .name a {
        text-transform: uppercase; }
    .volunteer .info .occupation {
      color: #999;
      margin-bottom: 10px;
      margin-top: 0px;
      text-transform: uppercase; }

/*
 * contact.less
 * -----------------------------------------------
*/
.contact-wrapper {
  padding: 50px 50px 50px 100px; }

.contact-form .form-group {
  margin-bottom: 20px; }

.contact-form .form-control {
  background-image: none;
  border: 1px solid #c0c0c0;
  border-radius: 0;
  box-shadow: none;
  height: 48px; }
  .contact-form .form-control:focus, .contact-form .form-control:active {
    box-shadow: none;
    color: #333333; }

.contact-form .form-control2 {
  background-color: rgba(0, 0, 0, 0.8);
  color: #c0c0c0; }

.contact-form .form-group button {
  margin-top: 0; }

.contact-form textarea.form-control {
  height: 110px;
  resize: none; }

textarea {
  resize: none; }

/*
 * event.less
 * -----------------------------------------------
*/
.event-block {
  margin: 10px 0;
  position: relative; }
  .event-block .event-date {
    padding: 6px 18px;
    position: absolute;
    top: 24px;
    -webkit-transition: all 200ms ease-in-out 0s;
    -o-transition: all 200ms ease-in-out 0s;
    transition: all 200ms ease-in-out 0s; }
  .event-block .event-meta {
    background: #f7f6f2 none repeat scroll 0 0;
    display: inline-block;
    margin-left: 40px;
    padding: 14px 16px 10px 14px;
    z-index: -1; }
  .event-block:hover .event-date {
    top: 16px; }

.event-small .event-date {
  float: left;
  margin-right: 20px;
  padding: 0 16px 5px 20px;
  text-align: center; }

/* -------- event Style ---------- */
.event-list .thumb {
  position: relative;
  overflow: hidden; }
  .event-list .thumb img {
    transition: all 500ms ease 0s; }

.event-list:hover .thumb img {
  transform: scale(1.1); }

.event-list .thumb .overlay-donate-now {
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: -50px;
  transition: all 400ms ease 0s; }

.event-list:hover .thumb .overlay-donate-now {
  top: 10%; }

.event-list .thumb .entry-date {
  bottom: 0;
  position: absolute;
  right: -44px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }

.event-list:hover .thumb .entry-date {
  right: 0; }

/*
 * practice-area.less
 * -----------------------------------------------
*/
.practice-area {
  overflow: hidden;
  position: relative; }
  .practice-area:hover .practice-name {
    opacity: 1;
    right: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }

/*
 * Services.less
 * -----------------------------------------------
*/
.service-block {
  margin: 0 auto 30px;
  max-width: 400px; }
  .service-block:hover .thumb .desc {
    bottom: 0; }
  .service-block .thumb {
    overflow: hidden;
    position: relative; }
    .service-block .thumb .price {
      left: -1px;
      padding: 5px 10px;
      position: absolute;
      top: -1px; }
    .service-block .thumb .desc {
      bottom: -31%;
      padding: 10px 6px 5px 20px;
      position: absolute;
      -webkit-transition: all 400ms ease-in-out 0s;
      -o-transition: all 400ms ease-in-out 0s;
      transition: all 400ms ease-in-out 0s;
      width: 100%; }
      .service-block .thumb .desc p {
        color: #fff;
        font-size: 13px; }
  .service-block .content {
    border: 1px solid #eeeeee;
    padding: 12px 20px 20px; }

/*
 * Work-Gallery.less
 * -----------------------------------------------
*/
.work-gallery {
  margin: 0 auto 30px;
  max-width: 400px; }
  .work-gallery .gallery-thumb {
    overflow: hidden;
    position: relative; }
  .work-gallery .gallery-bottom-part {
    background-color: #f7f7f7;
    padding: 10px 10px 16px; }
  .work-gallery .gallery-thumb .gallery-overlay {
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    -webkit-transform: scale(0.2);
    -ms-transform: scale(0.2);
    -o-transform: scale(0.2);
    transform: scale(0.2);
    -webkit-transition: all 300ms ease-in-out 0s;
    -o-transition: all 300ms ease-in-out 0s;
    transition: all 300ms ease-in-out 0s;
    width: 100%; }
  .work-gallery:hover .gallery-thumb .gallery-overlay {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }

.gallery-contect {
  height: 100%;
  opacity: 0;
  padding: 30px;
  position: absolute;
  top: 0;
  -webkit-transform: scale(0.2);
  -ms-transform: scale(0.2);
  -o-transform: scale(0.2);
  transform: scale(0.2);
  -webkit-transition: all 100ms ease-in-out 0s;
  -o-transition: all 100ms ease-in-out 0s;
  transition: all 100ms ease-in-out 0s;
  width: 100%; }

.work-gallery:hover .gallery-thumb .gallery-contect {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

.work-gallery .gallery-thumb .styled-icons a {
  border-color: #fff;
  height: 36px;
  width: 36px;
  line-height: 34px; }
  .work-gallery .gallery-thumb .styled-icons a i {
    color: #fff; }
  .work-gallery .gallery-thumb .styled-icons a:hover {
    color: #fff;
    -webkit-transition: all 100ms ease-in-out 0s;
    -o-transition: all 100ms ease-in-out 0s;
    transition: all 100ms ease-in-out 0s; }
    .work-gallery .gallery-thumb .styled-icons a:hover i {
      color: #fff; }

.work-gallery .gallery-thumb .gallery-contect .styled-icons {
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 45%; }

.work-gallery:hover .gallery-bottom-part {
  -webkit-transition: all 300ms ease-in-out 0s;
  -o-transition: all 300ms ease-in-out 0s;
  transition: all 300ms ease-in-out 0s; }
  .work-gallery:hover .gallery-bottom-part .title, .work-gallery:hover .gallery-bottom-part .sub-title {
    color: #fff; }

/*
 * job.less
 * -----------------------------------------------
*/
.job-overview {
  box-shadow: 0 0 0 2px #eee;
  padding: 20px 0 20px 20px; }
  .job-overview .dl-horizontal dt {
    width: auto; }
  .job-overview .dl-horizontal dd {
    margin-left: 40px; }

/*
 * shop.less
 * -----------------------------------------------
*/
/* -------- Product Details Page ---------- */
.product {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  position: relative; }

.products .product {
  border: 1px solid #eee; }
  .products .product:hover {
    border: 1px solid #eee; }

.btn-add-to-cart-wrapper {
  opacity: 0.9;
  height: auto;
  margin: 0 0 15px;
  text-align: center;
  width: 100%;
  background: #ffffff;
  -webkit-transition: 400ms;
  -o-transition: 400ms;
  transition: 400ms; }

/* -------- Price ---------- */
.product .price {
  color: #333038;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 0 15px; }
  .product .price del {
    color: #9e9d9e;
    font-size: 13px;
    margin-right: 5px; }
  .product .price ins {
    color: #333333;
    font-size: 16px;
    text-decoration: none;
    background: transparent none repeat scroll 0 0;
    font-weight: 600; }

.product .product-summary .product-title {
  margin-top: 0; }

.product .product-summary .short-description {
  margin-bottom: 15px; }

.product .product-summary .cart-form-wrapper .btn, .product .product-summary .cart-form-wrapper .panel-contacto .contact-form input[type='submit'], .panel-contacto .contact-form .product .product-summary .cart-form-wrapper input[type='submit'] {
  margin-top: 0; }

.product .product-summary .variations {
  width: 100%; }

.product .product-tab {
  margin-top: 30px; }

.product .commentlist .comment {
  margin-top: 30px;
  position: relative; }
  .product .commentlist .comment .meta {
    font-size: 12px; }
  .product .commentlist .comment .author {
    font-size: 14px;
    font-weight: bold; }

.product .product-thumb {
  position: relative; }
  .product .product-thumb:hover .overlay {
    opacity: 1; }
  .product .product-thumb .overlay .add-to-wishlist {
    color: #fff;
    right: 10px;
    top: 10px;
    position: absolute; }

.product .product-summary .cart-form-wrapper .quantity {
  display: inline-block;
  margin-right: .8571em;
  position: relative;
  width: 4.1429em;
  vertical-align: top; }

/* -------- Product-Summary ---------- */
.tbl-shopping-cart .quantity {
  display: inline-block;
  margin-right: .8571em;
  position: relative;
  width: 4.1429em;
  vertical-align: top; }

.product .product-summary .cart-form-wrapper .quantity .minus, .tbl-shopping-cart .quantity .minus, .product .product-summary .cart-form-wrapper .quantity .plus, .tbl-shopping-cart .quantity .plus {
  background: none repeat scroll 0 0 transparent;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-family: 'Open Sans', sans-serif;
  height: 18px;
  left: 3em;
  line-height: 1;
  outline: medium none;
  padding: 0;
  position: absolute;
  width: 18px; }

.product .product-summary .cart-form-wrapper .quantity .minus, .tbl-shopping-cart .quantity .minus {
  bottom: 0; }

.product .product-summary .cart-form-wrapper .quantity .plus, .tbl-shopping-cart .quantity .plus {
  top: 0; }

.product .product-summary .cart-form-wrapper .quantity .qty, .tbl-shopping-cart .quantity .qty {
  -moz-appearance: textfield;
  border: 1px solid #ccc;
  height: 41px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  width: 40px; }

/* -------- Star-Rating ---------- */
.star-rating {
  font-size: 14px;
  height: 16px;
  line-height: 1;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  top: 5px;
  width: 75px; }
  .star-rating::before {
    color: #a9a9a9;
    content: "";
    float: left;
    font-family: fontawesome;
    left: 0;
    letter-spacing: 2px;
    position: absolute;
    top: 0; }
  .star-rating span {
    float: left;
    left: 0;
    overflow: hidden;
    padding-top: 1.5em;
    position: absolute;
    top: 0; }
    .star-rating span::before {
      color: #a9a9a9;
      content: "";
      float: left;
      font-family: fontawesome;
      left: 0;
      letter-spacing: 2px;
      position: absolute;
      top: 0; }

/* -------- Tag Sale ---------- */
.product .tag-sale {
  margin: 0;
  top: 0;
  display: block;
  left: auto;
  right: 0;
  -webkit-transition: 400ms;
  -o-transition: 400ms;
  transition: 400ms;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  padding: 4px 10px 4px 16px;
  position: absolute;
  text-align: right;
  text-transform: uppercase;
  z-index: 9; }
  .product .tag-sale .tag-sale::before {
    border-color: transparent transparent transparent #ddd;
    border-style: solid;
    border-width: 12px 13px 13px 8px;
    content: "";
    height: 0;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 1px;
    width: 0; }

/* -------- Cart Page ---------- */
.tbl-shopping-cart .product-thumbnail img {
  max-width: 72px;
  max-height: 72px; }

.tbl-shopping-cart .product-remove {
  text-align: center; }

.tbl-shopping-cart .variation .variation-size {
  font-size: 12px; }
  .tbl-shopping-cart .variation .variation-size span {
    font-size: 12px; }

.tbl-shopping-cart tr th, .tbl-shopping-cart tr td {
  vertical-align: middle !important; }

.tbl-shopping-cart .coupon label, .tbl-shopping-cart .coupon input, .tbl-shopping-cart .coupon button {
  vertical-align: middle !important; }

.tbl-shopping-cart tr td {
  padding: 10px 12px !important; }

table.no-border td {
  border: none !important; }

/* -------- Checkout Page ---------- */
.payment-method .radio {
  border: 1px solid #eee;
  padding: 10px; }
  .payment-method .radio > label {
    color: #111;
    font-size: 17px; }
  .payment-method .radio input {
    margin-top: 8px;
    vertical-align: initial; }
  .payment-method .radio > p {
    margin-left: 20px; }

#checkout-shipping-address, .top-nav-search-box .indicator, .top-nav-cart-box .indicator {
  display: none; }

/* -------- Top Nav Search And Cart ---------- */
.dropdown-cart {
  padding: 10px; }
  .dropdown-cart .cart-table-list {
    color: #222;
    border: none; }
    .dropdown-cart .cart-table-list i {
      color: #222;
      border: none; }
    .dropdown-cart .cart-table-list a {
      line-height: normal;
      color: #222; }
    .dropdown-cart .cart-table-list td img {
      width: 50px;
      height: auto; }
    .dropdown-cart .cart-table-list.table > tbody > tr > td {
      border: none;
      line-height: 20px;
      height: 20px;
      vertical-align: middle; }
  .dropdown-cart .total-cart {
    display: inline-block;
    width: 100%;
    padding-top: 20px;
    color: #222;
    text-align: right; }
    .dropdown-cart .total-cart ul {
      float: right;
      list-style: none;
      padding: 0;
      width: 100%; }
      .dropdown-cart .total-cart ul li {
        display: block;
        overflow: hidden; }
        .dropdown-cart .total-cart ul li span {
          width: 100px;
          display: inline-block;
          text-align: right;
          white-space: nowrap; }
    .dropdown-cart .total-cart .table > tbody > tr > td, .dropdown-cart .total-cart .table > tbody > tr > th {
      border: 0; }
    .dropdown-cart .total-cart .table > tfoot > tr > td, .dropdown-cart .total-cart .table > tfoot > tr > th {
      border: 0; }
    .dropdown-cart .total-cart .table > thead > tr > td, .dropdown-cart .total-cart .table > thead > tr > th {
      border: 0; }
  .dropdown-cart .cart-btn .btn, .dropdown-cart .cart-btn .panel-contacto .contact-form input[type='submit'], .panel-contacto .contact-form .dropdown-cart .cart-btn input[type='submit'] {
    width: auto;
    float: none;
    padding: 5px 15px; }

/*
 * blog.less
 * -----------------------------------------------
*/
.blog-posts .post {
  position: relative; }
  .blog-posts .post .entry-title {
    text-transform: uppercase; }
  .blog-posts .post .post-thumb {
    overflow: hidden;
    position: relative; }
  .blog-posts .post .entry-content {
    position: relative; }
    .blog-posts .post .entry-content .post-date, .blog-posts .post .entry-content .post-visited {
      color: #a9a9a9;
      font-size: 13px; }
    .blog-posts .post .entry-content .post-date {
      background: #222222 none repeat scroll 0 0;
      display: inline-block;
      padding: 0px 14px;
      top: 0; }
      .blog-posts .post .entry-content .post-date.right {
        top: -10px;
        height: 60px;
        padding: 6px 8px;
        position: absolute;
        right: 0;
        text-align: center;
        width: 60px; }
    .blog-posts .post .entry-content ul li {
      margin-bottom: 0;
      margin-top: 0; }
  .blog-posts .post .entry-meta span {
    font-size: 12px;
    margin-right: 15px; }
    .blog-posts .post .entry-meta span i {
      margin-right: 5px; }
  .blog-posts .post .post-date p {
    font-size: 14px;
    color: #a9a9a9; }
  .blog-posts .post .post-date a {
    font-size: 14px;
    color: #a9a9a9; }
    .blog-posts .post .post-date a:hover {
      color: #333333; }
  .blog-posts .post .like-comment li {
    margin-bottom: 0;
    margin-top: 0; }
  .blog-posts .post .post-thumb .owl-dots {
    bottom: 0;
    position: absolute;
    right: 10px; }
    .blog-posts .post .post-thumb .owl-dots .owl-dot span {
      border-radius: 50%;
      height: 10px;
      width: 10px; }

.like-comment li i {
  font-size: 20px;
  margin-right: 3px;
  vertical-align: middle; }

.like-comment.small-font li i {
  font-size: 16px; }

.blog-pull-right {
  float: right; }

@media only screen and (max-width: 767px) {
  .blog-pull-right {
    float: none; } }

.media-post .post-thumb {
  float: left;
  margin: 0 10px 10px 0; }

.media-post .post-right {
  overflow: hidden; }

.media-post .post-title a {
  font-size: 13px;
  letter-spacing: 1px; }

@media only screen and (max-width: 991px) {
  .blog-posts .post {
    margin-bottom: 30px; } }

/* -------- Entry-Meta Absolute ---------- */
.post .entry-header {
  position: relative;
  overflow: hidden; }

.post .entry-meta.meta-absolute {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0; }
  .post .entry-meta.meta-absolute i {
    display: block;
    font-size: 28px; }

.post:hover .entry-header .entry-date {
  right: 0;
  transform: rotate(0deg); }

.post .entry-header .entry-date {
  position: absolute;
  right: -47px;
  top: 0;
  transform: rotate(45deg);
  transition: all 200ms ease-in-out 0s; }

.post-overlay::after {
  background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
  content: "";
  height: 100%;
  opacity: 0;
  position: absolute;
  top: -100%;
  transition: all 300ms ease-in-out 0s;
  width: 100%; }

.post:hover .post-overlay::after {
  opacity: 1;
  top: 0; }

.post .entry-header .post-link {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  top: 0;
  transition: all 300ms ease-in-out 0s;
  opacity: 0; }

.post:hover .entry-header .post-link {
  opacity: 1;
  top: 45%; }

.post .entry-header .post-link a {
  border: 1px solid #fff;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  width: 32px; }

.post .entry-header .post-link i {
  color: #fff;
  font-size: 14px; }

.post .entry-header .post-link a:hover i {
  color: #fff; }

/* -------- Blog Single ---------- */
.single-post article {
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 20px;
  padding-bottom: 30px; }
  .single-post article .entry-title {
    font-family: "Open Sans", sans-serif;
    padding: 15px 0 0;
    text-transform: capitalize; }

.single-post .entry-content {
  border: none; }

.single-post .author-details {
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  margin: 20px 0;
  padding: 20px 0; }

.single-post .post-share ul {
  margin-bottom: 15px; }
  .single-post .post-share ul li {
    display: inline-block;
    margin-right: 5px; }
    .single-post .post-share ul li a {
      background: none repeat scroll 0 0 #eeeeee;
      border: 1px solid #eeeeee;
      border-radius: 0; }
      .single-post .post-share ul li a i {
        color: #555555; }
      .single-post .post-share ul li a:hover i {
        color: #111111; }

.single-post .media-heading {
  margin-bottom: 5px;
  margin-top: 0; }

.single-post .comments-title {
  margin-bottom: 15px; }

.single-post .comment-list {
  padding-left: 0; }
  .single-post .comment-list h6 {
    font-family: helvetica; }
  .single-post .comment-list li {
    list-style: none; }
  .single-post .comment-list .comment-author {
    margin-top: 30px; }
  .single-post .comment-list .comment-date, .single-post .comment-list .comment-heading {
    font-size: 13px; }

.single-post .reply-box h4 {
  margin-bottom: 15px; }

.single-post .reply-box .form-group input {
  border-radius: 0;
  height: 45px;
  margin-bottom: 28px; }

.single-post .reply-box .form-group textarea {
  border-radius: 0; }

.single-post .reply-box #comment-form button.btn, .single-post .reply-box .btn-primary, .single-post .reply-box .panel-contacto .contact-form input[type='submit'], .panel-contacto .contact-form .single-post .reply-box input[type='submit'] {
  margin-right: 0; }

.single-post .reply-box .tagline {
  border-top: 1px solid #d3d3d3; }

.reply-box .form-group input {
  border-radius: 0;
  height: 45px;
  margin-bottom: 30px; }

.post .entry-content {
  border: 1px solid #f7f8f7;
  transition: all 300ms ease-in-out 0s; }

.post:hover .entry-content {
  box-shadow: 2px 10px 28px -15px #aaa; }

.blog-posts.single-post .post .entry-content {
  border: medium none; }

.blog-posts.single-post .post:hover .entry-content {
  box-shadow: none; }

/*
 * shortcode.less
 * -----------------------------------------------
*/
/*
 * Shortcode: Alerts.less
 * -----------------------------------------------
*/
/*
 * Shortcode: Buttons.less
 * -----------------------------------------------
*/
/* -------- Btn-Default ---------- */
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc; }
  .btn-default:focus, .btn-default.focus {
    color: #333;
    background-color: #e6e6e6;
    border-color: #8c8c8c; }
  .btn-default:hover, .btn-default:active, .btn-default.active {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad; }

.open > .dropdown-toggle.btn-default {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad; }

.btn-default:active:hover, .btn-default.active:hover {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c; }

.open > .dropdown-toggle.btn-default:hover {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c; }

.btn-default:active:focus, .btn-default.active:focus {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c; }

.open > .dropdown-toggle.btn-default:focus {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c; }

.btn-default:active.focus, .btn-default.active.focus {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c; }

.open > .dropdown-toggle.btn-default.focus {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c; }

.btn-default:active, .btn-default.active {
  background-image: none; }

.open > .dropdown-toggle.btn-default {
  background-image: none; }

.btn-default.disabled:hover, .btn-default[disabled]:hover {
  background-color: #fff;
  border-color: #ccc; }

fieldset[disabled] .btn-default:hover {
  background-color: #fff;
  border-color: #ccc; }

.btn-default.disabled:focus, .btn-default[disabled]:focus {
  background-color: #fff;
  border-color: #ccc; }

fieldset[disabled] .btn-default:focus {
  background-color: #fff;
  border-color: #ccc; }

.btn-default.disabled.focus, .btn-default[disabled].focus {
  background-color: #fff;
  border-color: #ccc; }

fieldset[disabled] .btn-default.focus {
  background-color: #fff;
  border-color: #ccc; }

.btn-default .badge {
  color: #fff;
  background-color: #333; }

.btn-default:hover {
  background-color: #eeeeee;
  border-color: #eeeeee; }

/* -------- Btn-Border ---------- */
.btn-border {
  color: #222222;
  background-color: #fff;
  border-color: #222222; }
  .btn-border:focus, .btn-border.focus {
    color: #222222;
    background-color: #e6e6e6;
    border-color: #000000; }
  .btn-border:hover, .btn-border:active, .btn-border.active {
    color: #222222;
    background-color: #e6e6e6;
    border-color: #040404; }

.open > .dropdown-toggle.btn-border {
  color: #222222;
  background-color: #e6e6e6;
  border-color: #040404; }

.btn-border:active:hover, .btn-border.active:hover {
  color: #222222;
  background-color: #d4d4d4;
  border-color: #000000; }

.open > .dropdown-toggle.btn-border:hover {
  color: #222222;
  background-color: #d4d4d4;
  border-color: #000000; }

.btn-border:active:focus, .btn-border.active:focus {
  color: #222222;
  background-color: #d4d4d4;
  border-color: #000000; }

.open > .dropdown-toggle.btn-border:focus {
  color: #222222;
  background-color: #d4d4d4;
  border-color: #000000; }

.btn-border:active.focus, .btn-border.active.focus {
  color: #222222;
  background-color: #d4d4d4;
  border-color: #000000; }

.open > .dropdown-toggle.btn-border.focus {
  color: #222222;
  background-color: #d4d4d4;
  border-color: #000000; }

.btn-border:active, .btn-border.active {
  background-image: none; }

.open > .dropdown-toggle.btn-border {
  background-image: none; }

.btn-border.disabled:hover, .btn-border[disabled]:hover {
  background-color: #fff;
  border-color: #222222; }

fieldset[disabled] .btn-border:hover {
  background-color: #fff;
  border-color: #222222; }

.btn-border.disabled:focus, .btn-border[disabled]:focus {
  background-color: #fff;
  border-color: #222222; }

fieldset[disabled] .btn-border:focus {
  background-color: #fff;
  border-color: #222222; }

.btn-border.disabled.focus, .btn-border[disabled].focus {
  background-color: #fff;
  border-color: #222222; }

fieldset[disabled] .btn-border.focus {
  background-color: #fff;
  border-color: #222222; }

.btn-border {
  border-width: 2px; }
  .btn-border .badge {
    color: #fff;
    background-color: #222222; }
  .btn-border:hover, .btn-border:active, .btn-border:focus {
    background-color: #222222;
    border-color: #222222;
    color: #fff; }

/* -------- Btn-Dark ---------- */
.btn-dark {
  color: #fff;
  background-color: #222222;
  border-color: #222222; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #090909;
    border-color: #000000; }
  .btn-dark:hover, .btn-dark:active, .btn-dark.active {
    color: #fff;
    background-color: #090909;
    border-color: #040404; }

.open > .dropdown-toggle.btn-dark {
  color: #fff;
  background-color: #090909;
  border-color: #040404; }

.btn-dark:active:hover, .btn-dark.active:hover {
  color: #fff;
  background-color: #000000;
  border-color: #000000; }

.open > .dropdown-toggle.btn-dark:hover {
  color: #fff;
  background-color: #000000;
  border-color: #000000; }

.btn-dark:active:focus, .btn-dark.active:focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000; }

.open > .dropdown-toggle.btn-dark:focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000; }

.btn-dark:active.focus, .btn-dark.active.focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000; }

.open > .dropdown-toggle.btn-dark.focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000; }

.btn-dark:active, .btn-dark.active {
  background-image: none; }

.open > .dropdown-toggle.btn-dark {
  background-image: none; }

.btn-dark.disabled:hover, .btn-dark[disabled]:hover {
  background-color: #222222;
  border-color: #222222; }

fieldset[disabled] .btn-dark:hover {
  background-color: #222222;
  border-color: #222222; }

.btn-dark.disabled:focus, .btn-dark[disabled]:focus {
  background-color: #222222;
  border-color: #222222; }

fieldset[disabled] .btn-dark:focus {
  background-color: #222222;
  border-color: #222222; }

.btn-dark.disabled.focus, .btn-dark[disabled].focus {
  background-color: #222222;
  border-color: #222222; }

fieldset[disabled] .btn-dark.focus {
  background-color: #222222;
  border-color: #222222; }

.btn-dark .badge {
  color: #222222;
  background-color: #fff; }

/* -------- Btn-Gray ---------- */
.btn-gray {
  color: #333333;
  background-color: #eeeeee;
  border-color: #eeeeee; }
  .btn-gray:focus, .btn-gray.focus {
    color: #333333;
    background-color: #d5d5d5;
    border-color: #afafaf; }
  .btn-gray:hover, .btn-gray:active, .btn-gray.active {
    color: #333333;
    background-color: #d5d5d5;
    border-color: #d0d0d0; }

.open > .dropdown-toggle.btn-gray {
  color: #333333;
  background-color: #d5d5d5;
  border-color: #d0d0d0; }

.btn-gray:active:hover, .btn-gray.active:hover {
  color: #333333;
  background-color: #c3c3c3;
  border-color: #afafaf; }

.open > .dropdown-toggle.btn-gray:hover {
  color: #333333;
  background-color: #c3c3c3;
  border-color: #afafaf; }

.btn-gray:active:focus, .btn-gray.active:focus {
  color: #333333;
  background-color: #c3c3c3;
  border-color: #afafaf; }

.open > .dropdown-toggle.btn-gray:focus {
  color: #333333;
  background-color: #c3c3c3;
  border-color: #afafaf; }

.btn-gray:active.focus, .btn-gray.active.focus {
  color: #333333;
  background-color: #c3c3c3;
  border-color: #afafaf; }

.open > .dropdown-toggle.btn-gray.focus {
  color: #333333;
  background-color: #c3c3c3;
  border-color: #afafaf; }

.btn-gray:active, .btn-gray.active {
  background-image: none; }

.open > .dropdown-toggle.btn-gray {
  background-image: none; }

.btn-gray.disabled:hover, .btn-gray[disabled]:hover {
  background-color: #eeeeee;
  border-color: #eeeeee; }

fieldset[disabled] .btn-gray:hover {
  background-color: #eeeeee;
  border-color: #eeeeee; }

.btn-gray.disabled:focus, .btn-gray[disabled]:focus {
  background-color: #eeeeee;
  border-color: #eeeeee; }

fieldset[disabled] .btn-gray:focus {
  background-color: #eeeeee;
  border-color: #eeeeee; }

.btn-gray.disabled.focus, .btn-gray[disabled].focus {
  background-color: #eeeeee;
  border-color: #eeeeee; }

fieldset[disabled] .btn-gray.focus {
  background-color: #eeeeee;
  border-color: #eeeeee; }

.btn-gray .badge {
  color: #eeeeee;
  background-color: #333333; }

.btn-circled {
  border-radius: 25px; }

.btn-flat {
  border-radius: 0; }

/* -------- Btn-Default ---------- */
.btn, .panel-contacto .contact-form input[type='submit'] {
  font-size: 14px;
  padding: 8px 22px;
  line-height: 1.38; }

/* -------- Btn-xs ---------- */
.btn-xs {
  font-size: 11px;
  padding: 3px 14px; }

/* -------- Btn-sm ---------- */
.btn-sm {
  font-size: 12px;
  padding: 5px 17px; }

/* -------- Btn-lg ---------- */
.btn-lg {
  font-size: 14px;
  padding: 10px 26px; }

/* -------- Btn-xl ---------- */
.btn-xl {
  font-size: 16px;
  padding: 13px 32px; }

/* -------- Btn-transparent ---------- */
.btn-transparent {
  background-color: transparent;
  color: #fff; }

.hvr-shutter-out-horizontal::before {
  background: #000; }

/*
 * Blockquote.less
 * -----------------------------------------------
*/
blockquote {
  border-left: 3px solid #eeeeee;
  margin: 20px 0;
  padding: 30px; }
  blockquote.gray {
    border-left: 3px solid #666666;
    background-color: #eeeeee;
    color: #333333; }
    blockquote.gray.bg-theme-colored {
      background-color: #eeeeee; }
  blockquote.dark {
    border-left: 3px solid #3c3c3c;
    background-color: #555555;
    color: #fff; }
    blockquote.dark footer {
      color: #d3d3d3; }
  blockquote p {
    font-family: "Playfair Display", serif;
    font-size: 16px;
    font-style: italic; }

/*
 * Shortcode: clients.less
 * -----------------------------------------------
*/
.clients-logo .item {
  padding: 10px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }

.clients-logo img {
  max-height: 90px;
  width: auto;
  display: inline;
  opacity: 0.8; }
  .clients-logo img:hover {
    opacity: 1; }

.clients-logo.carousel {
  text-align: center; }
  .clients-logo.carousel .item {
    box-shadow: none;
    margin: 0 10px; }

.clients-logo.style2 .item {
  box-shadow: none; }
  .clients-logo.style2 .item img {
    max-height: 120px; }

.client-img {
  opacity: 0.6;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .client-img:hover {
    opacity: 1; }
  .client-img img {
    max-height: 90px; }

.clients-logo.carousel {
  text-align: center; }
  .clients-logo.carousel .item {
    box-shadow: none;
    margin: 0 10px; }

/*
 * Shortcode: Countdown.less
 * -----------------------------------------------
*/
.countdown-timer li {
  color: #808080;
  display: inline-block;
  padding: 30px 20px;
  background-color: #f1f1f1;
  margin-right: 15px;
  font-size: 40px;
  text-align: center;
  min-width: 120px;
  min-height: 120px; }
  .countdown-timer li span {
    color: #c0c0c0;
    display: block;
    font-size: 15px;
    margin-top: 5px; }

.countdown-timer.timer-box {
  padding: 20px;
  padding-top: 40px;
  text-align: center; }
  .countdown-timer.timer-box li {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.05);
    margin-bottom: 15px; }
    .countdown-timer.timer-box li span {
      color: #eeeeee; }

.countdown-timer.timer-deep-bg li {
  background-color: rgba(0, 0, 0, 0.05); }

@media only screen and (max-width: 767px) {
  .timer-responsive li {
    margin-top: 30px;
    font-size: 20px;
    min-width: 60px;
    min-height: 60px;
    padding: 20px 10px; }
  .countdown-timer li span {
    font-size: 12px; } }

/*
 * Shortcode: Countdown-Timer.less
 * -----------------------------------------------
*/
/*
 * Shortcode: Dividers.less
 * -----------------------------------------------
*/
.divider {
  background-repeat: repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%; }

.parallax {
  background-repeat: repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: repeat-y;
  background-position: top center; }

/* -------- Parallax ---------- */
.mobile-parallax {
  background-size: cover;
  background-attachment: scroll;
  background-position: center center; }

.divider .container {
  padding-top: 60px;
  padding-bottom: 60px; }
  .divider .container.small-padding {
    padding-top: 30px;
    padding-bottom: 30px; }
  .divider .container.extra-padding {
    padding-top: 180px;
    padding-bottom: 180px; }

/*
 * Shortcode: Facebook-Disqus-Comments.less
 * -----------------------------------------------
*/
.fb-comments, .fb_iframe_widget, .fb-comments > span, .fb_iframe_widget > span, .fb-comments > span > iframe, .fb_iframe_widget > span > iframe {
  display: block;
  margin: 0;
  width: 100%; }

/*
 * Shortcode: faqs.less
 * -----------------------------------------------
*/
/*
 * Shortcode: Flexslider.less
 * -----------------------------------------------
*/
.flex-container a:active, .flexslider a:active, .flex-container a:focus, .flexslider a:focus {
  outline: none;
  border: none; }

.flex-direction-nav {
  display: none; }

.flexslider {
  position: relative;
  margin: 0;
  padding: 0;
  border: 0; }

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }

.flex-control-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  width: auto;
  position: absolute;
  z-index: 10;
  text-align: center;
  bottom: 15px;
  right: 15px; }
  .flex-control-nav li {
    float: left;
    display: block;
    margin: 0 3px;
    width: 10px;
    height: 10px; }
    .flex-control-nav li a {
      display: block;
      cursor: pointer;
      text-indent: -9999px;
      width: 10px;
      height: 10px;
      border: 1px solid #fff;
      border-radius: 50%;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .flex-control-nav li:hover a, .flex-control-nav li a.flex-active {
      background-color: #fff; }

/*
 * Shortcode: Flip Box
 * -----------------------------------------------
*/
.card {
  float: left;
  min-height: 300px;
  padding-bottom: 25%;
  position: relative;
  text-align: center;
  width: 100%; }
  .card:nth-child(1) {
    margin-left: -3px;
    margin-right: 1px;
    margin-right: 1px; }
  .card .card:nth-child(3) {
    margin-right: 1px; }
  .card .card__front {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s; }
  .card .card__back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg); }
  .card .card__text {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    line-height: 20px; }
  .card.effect__hover:hover .card__front, .card.effect__click.flipped .card__front, .card.effect__random.flipped .card__front {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg); }
  .card.effect__hover:hover .card__back, .card.effect__click.flipped .card__back, .card.effect__random.flipped .card__back {
    -webkit-transform: rotateY(0);
    transform: rotateY(0); }

/*
 * Responsive : Flip Box
 * -----------------------------------------------
*/
@media only screen and (max-width: 767px) {
  .card {
    margin-bottom: 30px;
    min-height: 489px; } }

@media only screen and (min-width: 501px) and (max-width: 600px) {
  .card {
    margin-bottom: 30px;
    min-height: 400px; } }

@media only screen and (min-width: 450px) and (max-width: 500px) {
  .card {
    margin-bottom: 30px;
    min-height: 350px; } }

@media only screen and (min-width: 320px) and (max-width: 449px) {
  .card {
    margin-bottom: 30px;
    min-height: 300px; } }

/*
 * Shortcode: funfacts.less
 * -----------------------------------------------
*/
.funfact {
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease; }
  .funfact.border {
    border: 1px solid #d3d3d3; }
  .funfact.no-border {
    border: 0; }
  .funfact.border {
    border: 1px solid #d3d3d3; }
  .funfact i {
    font-size: 45px; }
  .funfact h2 {
    position: relative; }
    .funfact h2:after {
      bottom: 0; }
  .funfact span {
    color: #eeeeee;
    font-size: 18px; }
  .funfact .animate-number {
    line-height: 1; }
  .funfact.style-1 i {
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease; }
  .funfact.style-1 .funfact-title {
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    color: #999999;
    font-size: 14px;
    text-transform: uppercase; }
  .funfact.style-1:hover i {
    font-size: 40px;
    opacity: 0.3; }
  .funfact.style-1:hover .funfact-title {
    color: #333333; }

/* funfact style-1*/
/*
 * Shortcode: Icon-Boxes.less
 * -----------------------------------------------
*/
.icon-box {
  padding: 40px 17px; }
  .icon-box .icon {
    display: inline-block;
    height: 68px;
    margin-bottom: 20px;
    text-align: center;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    width: 68px; }
    .icon-box .icon i {
      line-height: 68px; }
    .icon-box .icon.icon-gray {
      background-color: #eeeeee;
      color: #555555; }
      .icon-box .icon.icon-gray:hover {
        background-color: #d5d5d5;
        color: #bbbbbb; }
      .icon-box .icon.icon-gray.icon-bordered {
        background-color: transparent;
        border: 2px solid #eeeeee; }
        .icon-box .icon.icon-gray.icon-bordered:hover {
          background-color: #eeeeee;
          color: #555555; }
    .icon-box .icon.icon-dark {
      background-color: #111111;
      color: #fff; }
      .icon-box .icon.icon-dark:hover {
        color: #5e5e5e; }
      .icon-box .icon.icon-dark.icon-bordered {
        background-color: transparent;
        border: 2px solid #111111;
        color: #111111; }
        .icon-box .icon.icon-dark.icon-bordered:hover {
          background-color: #111111;
          border-color: #111111;
          color: #fff; }
    .icon-box .icon.icon-white {
      background-color: #fff;
      color: #333333; }
      .icon-box .icon.icon-white:hover {
        background-color: #b3b3b3;
        color: #000000; }
      .icon-box .icon.icon-white.icon-bordered {
        background-color: transparent;
        border: 2px solid #fff;
        color: #fff; }
        .icon-box .icon.icon-white.icon-bordered:hover {
          background-color: #fff;
          border-color: #fff;
          color: #111111; }
      .icon-box .icon.icon-white.icon-border-effect {
        position: relative; }
        .icon-box .icon.icon-white.icon-border-effect::after {
          box-shadow: 0 0 0 3px #fff; }
    .icon-box .icon.icon-bordered {
      border: 1px solid #777777; }
      .icon-box .icon.icon-bordered:hover {
        background-color: #777777;
        color: #fff; }
    .icon-box .icon.icon-rounded {
      border-radius: 3px; }
    .icon-box .icon.icon-circled {
      border-radius: 50%; }
    .icon-box .icon.icon-xs {
      height: 30px;
      width: 30px; }
      .icon-box .icon.icon-xs i {
        font-size: 18px;
        line-height: 30px; }
    .icon-box .icon.icon-sm {
      height: 50px;
      width: 50px; }
      .icon-box .icon.icon-sm i {
        font-size: 22px;
        line-height: 50px; }
    .icon-box .icon.icon-md {
      height: 75px;
      width: 75px; }
      .icon-box .icon.icon-md i {
        font-size: 36px;
        line-height: 75px; }
    .icon-box .icon.icon-lg {
      height: 90px;
      width: 90px; }
      .icon-box .icon.icon-lg i {
        font-size: 48px;
        line-height: 90px; }
    .icon-box .icon.icon-xl {
      height: 120px;
      width: 120px; }
      .icon-box .icon.icon-xl i {
        font-size: 60px;
        line-height: 120px; }
    .icon-box .icon.icon-border-effect {
      position: relative; }
      .icon-box .icon.icon-border-effect::after {
        border-radius: 50%;
        box-shadow: 0 0 0 3px #eeeeee;
        box-sizing: content-box;
        content: "";
        height: 100%;
        left: -4px;
        opacity: 0;
        padding: 4px;
        top: -4px;
        transform: scale(0.8);
        transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
        pointer-events: none;
        position: absolute;
        width: 100%; }
      .icon-box .icon.icon-border-effect.effect-flat::after {
        border-radius: 0; }
      .icon-box .icon.icon-border-effect.effect-rounded::after {
        border-radius: 3px; }
      .icon-box .icon.icon-border-effect.effect-circled::after {
        border-radius: 50%; }
      .icon-box .icon.icon-border-effect.icon-gray::after {
        box-shadow: 0 0 0 3px #eeeeee; }
    .icon-box .icon.icon-top {
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      top: -32px; }
  .icon-box i {
    display: inline-block;
    font-size: 40px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .icon-box i[class*="pe-7s-"] {
      vertical-align: text-bottom; }
  .icon-box.left {
    text-align: left; }
    .icon-box.left a {
      margin-right: 30px;
      padding: 0; }
  .icon-box.iconbox-border {
    border: 1px solid #dcdcdc; }
  .icon-box.iconbox-bg {
    background-color: #fafafa; }
    .icon-box.iconbox-bg.iconbox-bg-dark {
      background-color: #333333; }
  .icon-box .icon-box-title {
    margin-bottom: 15px; }
  .icon-box .icon-border-effect:hover::after, .icon-box:hover .icon-border-effect::after {
    opacity: 1;
    transform: scale(1); }
  .icon-box .icon-box-details {
    margin-left: 80px; }

/*
 * Shortcode: Infinitescroll.less
 * -----------------------------------------------
*/
#infscr-loading {
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 3px;
  color: #fff;
  font-size: 24px;
  height: 50px;
  line-height: 50px;
  left: 48%;
  position: fixed;
  text-align: center;
  top: 50%;
  width: 50px;
  z-index: 100; }
  #infscr-loading img {
    display: none; }

/*
 * Shortcode: Instagram.less
 * -----------------------------------------------
*/
/* -------- Instagram-Feed ---------- */
.instagram-feed-carousel figure, .instagram-feed figure {
  position: relative; }

.instagram-feed-carousel figure .link-out, .instagram-feed figure .link-out {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background: rgba(0, 0, 0, 0.45);
  color: #f8f8f8;
  line-height: 1;
  padding: 10px;
  font-weight: 300;
  opacity: 0; }

.instagram-feed-carousel figure .link-out:hover, .instagram-feed figure .link-out:hover {
  background: rgba(0, 0, 0, 0.7);
  color: #f8f8f8; }

.instagram-feed-carousel figure:hover .link-out {
  opacity: 1; }

.instagram-feed figure:hover .link-out {
  opacity: 1; }

.instagram-feed .item {
  width: 100px;
  float: left;
  margin-bottom: 2px;
  margin-right: 2px; }

/* -------- Instagram-Feed ---------- */
/* -------- Widget - Instagram-Feed ---------- */
.widget .instagram-feed .item {
  margin-bottom: 5px;
  margin-right: 5px;
  width: 70px; }

/*
 * Shortcode: Light-Box.less
 * -----------------------------------------------
*/
.nivo-lightbox-theme-default .nivo-lightbox-nav {
  height: 100%;
  top: 0; }

.lightbox .lb-dataContainer {
  left: 0;
  position: absolute;
  right: 0;
  top: -45px; }

/*
 * Shortcode: Map.less
 * -----------------------------------------------
*/
.toggle-map {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 16px;
  padding: 30px 0;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  z-index: 200;
  -webkit-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear; }
  .toggle-map:hover {
    color: #fff; }
  .toggle-map span:after {
    content: "\f107";
    font-family: fontawesome;
    margin-left: 10px; }
  .toggle-map.open span:after {
    content: "\f106"; }

.map-canvas {
  height: 400px;
  -webkit-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s; }
  .map-canvas.big {
    height: 720px; }
  .map-canvas.map-hidden {
    height: 0; }

.btn-show-map {
  color: #111111;
  font-size: 18px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 45%; }
  .btn-show-map i {
    font-size: 48px; }

/*
 * Shortcode: opening-hours.less
 * -----------------------------------------------
*/
.opening-hours ul li {
  border-bottom: 1px solid #eeeeee;
  padding: 10px 0;
  margin-bottom: 0; }
  .opening-hours ul li span {
    float: left; }
  .opening-hours ul li .value {
    float: right; }

/*
 * Shortcode: Pegination.less
 * -----------------------------------------------
*/
.pagination.theme-colored li.active a, .pagination.theme-colored li a:hover {
  color: #fff;
  border: 1px solid transparent; }

.pagination.dark li a {
  color: #333333; }

.pagination.dark li.active a {
  background: #333333;
  color: #fff;
  border: 1px solid transparent; }

.pager.theme-colored li.active a {
  color: #fff;
  border: 1px solid transparent; }

.pager.dark li a {
  color: #fff;
  background-color: #333333;
  border: 1px solid transparent; }

/*
 * Shortcode: pricing.less
 * -----------------------------------------------
*/
.pricing-table {
  margin-bottom: 30px;
  position: relative; }
  .pricing-table .table-price {
    color: #111111;
    font-size: 45px; }
    .pricing-table .table-price span {
      font-size: 13px;
      vertical-align: middle; }
  .pricing-table.featured {
    border: 1px solid #111111; }
  .pricing-table .table-type {
    display: inline; }
  .pricing-table .btn-signup {
    margin-right: 0;
    width: 100%; }
  .pricing-table .table-list {
    list-style: outside none none;
    padding: 10px 0 10px 20px; }
    .pricing-table .table-list li {
      border-bottom: 0;
      font-size: 12px;
      padding: 10px 0; }
      .pricing-table .table-list li i {
        margin-right: 8px; }
  .pricing-table .package-type span {
    position: relative;
    padding-left: 15px;
    margin-left: 10px; }
    .pricing-table .package-type span::after {
      background: #2d915b;
      content: "";
      height: 20px;
      left: -20px;
      position: absolute;
      top: 11px;
      width: 20px; }

/*
 * Shortcode: Process-Steps.less
 * -----------------------------------------------
*/
/*
 * Shortcode: Progress-Bar.less
 * -----------------------------------------------
*/
.progress-item {
  margin-top: 20px; }
  .progress-item .progress {
    background-color: #f8f8f8;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.4) inset;
    border-radius: 0;
    height: 10px;
    margin-bottom: 15px;
    overflow: visible; }
  .progress-item .progress-bar {
    font-size: 11px;
    padding-left: 10px;
    position: relative;
    text-align: left;
    width: 0;
    line-height: 18px;
    -webkit-transition: all 1s ease 0s;
    -o-transition: all 1s ease 0s;
    transition: all 1s ease 0s; }
    .progress-item .progress-bar .percent {
      background: none repeat scroll 0 0 #555555;
      border-radius: 8px 0 0;
      padding: 3px 5px;
      position: absolute;
      right: 0;
      top: -30px; }
  .progress-item.progress-white .progress {
    padding: 2px; }
  .progress-item.style2 .progress {
    height: 25px; }
  .progress-item.style2 .progress-bar .percent {
    background: none;
    color: #555555;
    font-size: 14px; }

/*
 * Shortcode: Promo.less
 * -----------------------------------------------
*/
.modal-promo-box {
  max-width: 700px;
  padding: 90px 60px;
  margin: 0 auto; }

/*
 * Shortcode: section-title.less
 * -----------------------------------------------
*/
.small-title {
  position: relative; }
  .small-title i {
    font-size: 36px;
    margin-top: 7px;
    padding-top: 0px; }
  .small-title .title {
    margin-left: 15px; }
    .small-title .title:after {
      bottom: -6px;
      content: "";
      height: 1px;
      left: 45px;
      position: absolute;
      width: 45px; }

.section-title {
  margin-bottom: 30px; }
  .section-title .both-side-line::after {
    content: "";
    height: 2px;
    position: absolute;
    right: -40px;
    top: 10px;
    width: 30px; }
  .section-title .both-side-line::before {
    content: "";
    height: 2px;
    position: absolute;
    left: -40px;
    top: 10px;
    width: 30px; }
  .section-title .top-side-line::after {
    content: "";
    height: 2px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: -8px;
    width: 40px; }
  .section-title .left-side-line::before {
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    top: -8px;
    width: 35px; }
  .section-title .right-side-line::before {
    content: "";
    height: 2px;
    position: absolute;
    right: 0;
    top: -8px;
    width: 35px; }
  .section-title .sub-title {
    margin: 0;
    position: relative;
    display: inline-block; }
  .section-title .title {
    margin-top: 0;
    position: relative;
    line-height: 1; }
  .section-title .icon i {
    font-size: 50px;
    position: absolute;
    top: -3px;
    left: 14px;
    opacity: 0.10; }
  .section-title p {
    font-size: 14px; }
    .section-title p.left-bordered {
      border-left: 2px solid #c0c0c0;
      padding-left: 10px; }
    .section-title p.right-bordered {
      border-right: 2px solid #c0c0c0;
      padding-right: 10px; }
  .section-title.title-border {
    padding-left: 30px; }
    .section-title.title-border.title-right {
      border-left: 0;
      padding-left: 0;
      padding-right: 30px;
      text-align: right; }
  .section-title.icon-bg p {
    font-size: 14px;
    font-style: italic;
    margin-bottom: 0; }
  .section-title.icon-bg .page-title {
    font-size: 38px;
    line-height: 52px; }
    .section-title.icon-bg .page-title i {
      font-size: 42px;
      line-height: 1.3;
      position: absolute;
      opacity: 0.15;
      left: 0;
      right: 0;
      margin: 0 auto; }

/*Theme Section Title*/
/*
 * Shortcode: Separators.less
 * -----------------------------------------------
*/
.title-separator {
  border-top-style: solid;
  border-top-width: 1px;
  font-size: 16px;
  height: auto;
  line-height: 20px;
  margin: 10px 0;
  width: 70%; }
  .title-separator span {
    background: #fff none repeat scroll 0 0;
    border-radius: 2px;
    letter-spacing: 0.2em;
    padding: 2px 10px;
    position: relative;
    text-transform: uppercase;
    top: -12px; }

.separator {
  color: #c0c0c0;
  line-height: 1.2em;
  margin: 30px auto;
  overflow: hidden;
  text-align: center;
  width: 100%; }
  .separator::before {
    border-bottom: 1px solid #eeeeee;
    content: "";
    display: inline-block;
    height: .65em;
    margin: 0 -4px 0 -100%;
    vertical-align: top;
    width: 50%; }
  .separator::after {
    border-bottom: 1px solid #eeeeee;
    content: "";
    display: inline-block;
    height: .65em;
    margin: 0 -4px 0 -100%;
    vertical-align: top;
    width: 50%;
    margin: 0 -100% 0 0; }
  .separator i, .separator span, .separator a {
    display: inline-block;
    margin: 0 20px 0 24px;
    font-size: 20px; }
  .separator.separator-left, .separator.left i {
    float: left;
    margin: 2px 50px 0 0; }
  .separator.right i {
    float: right;
    margin: 0 0 2px 50px; }
  .separator.separator-medium-line {
    margin: 10px auto;
    width: 20%; }
  .separator.separator-small-line {
    width: 10%; }
  .separator.separator-rouned i {
    background-color: #f5f5f5;
    border-radius: 50%;
    height: 40px;
    left: 0;
    line-height: 40px;
    margin: -12px auto auto;
    position: absolute;
    right: 0;
    width: 40px; }
  .separator.separator-border-rouned i {
    background-color: #f5f5f5;
    border-radius: 50%;
    height: 40px;
    left: 0;
    line-height: 40px;
    margin: -12px auto auto;
    position: absolute;
    right: 0;
    width: 40px;
    background: transparent;
    border: 1px solid #f5f5f5; }
  .separator.separator-rouned.separator-rouned-left i {
    margin: -12px auto auto;
    right: auto;
    left: 0; }
  .separator.separator-rouned.separator-rouned-right i {
    margin: -12px auto auto;
    right: 0;
    left: auto; }
  .separator.dotted::before, .separator.dotted::after {
    border-bottom: 2px dotted #c0c0c0; }

/*
 * Shortcode: styled-icons.less
 * -----------------------------------------------
*/
.styled-icons a {
  color: #333333;
  font-size: 18px;
  height: 32px;
  line-height: 32px;
  width: 32px;
  float: left;
  margin: 5px 7px 5px 0;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .styled-icons a:hover {
    color: #666666; }

.styled-icons.icon-gray a {
  background-color: #eeeeee;
  color: #555555;
  display: block;
  font-size: 18px;
  height: 36px;
  line-height: 36px;
  width: 36px; }
  .styled-icons.icon-gray a:hover {
    color: #bbbbbb; }

.styled-icons.icon-gray.icon-bordered a {
  background-color: transparent;
  border: 2px solid #eeeeee; }
  .styled-icons.icon-gray.icon-bordered a:hover {
    border: 2px solid #d5d5d5; }

.styled-icons.icon-dark a {
  background-color: #333333;
  color: #eeeeee;
  display: block;
  font-size: 18px;
  height: 36px;
  line-height: 36px;
  width: 36px; }
  .styled-icons.icon-dark a:hover {
    color: #5e5e5e; }

.styled-icons.icon-dark.icon-bordered a {
  background-color: transparent;
  border: 2px solid #111111;
  color: #111111; }
  .styled-icons.icon-dark.icon-bordered a:hover {
    background-color: #111111;
    border-color: #111111;
    color: #fff; }

.styled-icons.icon-bordered a {
  border: 1px solid #777777; }
  .styled-icons.icon-bordered a:hover {
    background-color: #777777;
    color: #fff; }

.styled-icons.icon-rounded a {
  border-radius: 3px; }

.styled-icons.icon-circled a {
  border-radius: 50%; }

.styled-icons.icon-sm a {
  font-size: 13px;
  height: 30px;
  line-height: 30px;
  margin: 2px 7px 2px 0;
  width: 30px; }

.styled-icons.icon-md a {
  font-size: 24px;
  height: 50px;
  line-height: 50px;
  width: 50px; }

.styled-icons.icon-lg a {
  font-size: 32px;
  height: 60px;
  line-height: 60px;
  width: 60px; }

.styled-icons.icon-xl a {
  font-size: 60px;
  height: 120px;
  line-height: 120px;
  width: 120px; }

.styled-icons li {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 0; }

/*
 * Shortcode: Tabs.less
 * -----------------------------------------------
*/
.tab-content {
  padding: 15px;
  border: 1px solid #eeeeee; }

.nav-tabs {
  border: none; }
  .nav-tabs > li {
    float: left;
    margin-bottom: -1px;
    text-align: center; }
    .nav-tabs > li.active a {
      color: #fff;
      background-color: #a9a9a9; }
      .nav-tabs > li.active a:hover, .nav-tabs > li.active a:focus {
        color: #fff;
        background-color: #a9a9a9; }
    .nav-tabs > li > a {
      background-color: #f5f5f5;
      border-radius: 0;
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      color: #555555;
      padding: 7px 15px;
      margin-right: 0; }
      .nav-tabs > li > a .fa {
        margin-right: 10px; }
      .nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
        color: #333333;
        background-color: transparent; }
      .nav-tabs > li > a i {
        font-size: 32px;
        margin-right: 20px;
        vertical-align: middle;
        display: block;
        font-size: 48px;
        margin-right: 0;
        margin-bottom: 10px;
        vertical-align: middle; }

/* -------- Nav Tabs ---------- */
/* -------- Horizontal-Tab-Centered ---------- */
.horizontal-tab-centered .nav-pills {
  display: inline-block; }
  .horizontal-tab-centered .nav-pills > li > a {
    background: #eeeeee;
    color: #555555;
    font-size: 14px;
    padding: 8px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    text-transform: capitalize; }
    .horizontal-tab-centered .nav-pills > li > a:hover {
      color: #fff; }
    .horizontal-tab-centered .nav-pills > li > a i {
      font-size: 14px;
      margin-right: 8px; }
  .horizontal-tab-centered .nav-pills > li.active > a {
    color: #fff; }
    .horizontal-tab-centered .nav-pills > li.active > a:hover, .horizontal-tab-centered .nav-pills > li.active > a:focus {
      color: #fff; }

/* -------- Horizontal-Tab Fullwide ---------- */
.horizontal-tab.fullwide .nav-tabs > li {
  width: 20%; }

/* -------- Vertical Nav-Tabs ---------- */
.vertical-tab .tab-content {
  padding: 5px 5px 20px 20px;
  border: none; }

.vertical-tab .nav-tabs > li {
  float: none;
  margin-bottom: 0; }
  .vertical-tab .nav-tabs > li > a {
    background: none;
    border-radius: 0;
    border-bottom: 1px solid #d3d3d3;
    border-right: 1px solid #d3d3d3;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    font-size: 14px;
    padding: 30px 0 30px 15px; }
  .vertical-tab .nav-tabs > li:first-child > a {
    border-top: 1px solid #d3d3d3; }
  .vertical-tab .nav-tabs > li.active > a {
    background: none;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid #d3d3d3; }
    .vertical-tab .nav-tabs > li.active > a:hover, .vertical-tab .nav-tabs > li.active > a:focus {
      background: none;
      border-top: 1px solid transparent;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      border-bottom: 1px solid #d3d3d3; }

.vertical-tab .small-padding .nav-tabs > li a {
  padding: 20px 0 20px 15px; }

.vertical-tab .small-padding .nav-tabs > li i {
  font-size: 24px; }

@media only screen and (max-width: 767px) {
  .vertical-tab .tab-content {
    padding: 20px 5px; }
  .nav-tabs > li > a {
    border-radius: 0;
    border-bottom: none;
    border-left: 2px solid #d3d3d3; }
  .nav-tabs > li.active > a {
    border-bottom: none;
    border-left: 2px solid #d3d3d3; }
    .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
      border-bottom: none;
      border-left: 2px solid #d3d3d3; } }

.custom-nav-tabs {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: -moz-use-text-color;
  border-image: none;
  border-style: none solid none none;
  border-width: medium 0px medium medium;
  margin-right: 53px; }
  .custom-nav-tabs > li {
    float: none;
    margin: 0 0 1px; }
    .custom-nav-tabs > li a {
      background: #eeeeee none repeat scroll 0 0;
      border: medium none;
      border-radius: 0;
      display: block;
      font-size: 17px;
      font-weight: 700;
      line-height: 59px;
      margin: 0;
      outline: medium none;
      padding: 0;
      text-align: center;
      text-transform: uppercase; }
    .custom-nav-tabs > li.active > a {
      border: medium none;
      color: #000;
      padding: 0; }
      .custom-nav-tabs > li.active > a:hover, .custom-nav-tabs > li.active > a:focus {
        border: medium none;
        color: #000;
        padding: 0; }
    .custom-nav-tabs > li > a:hover, .custom-nav-tabs > li > a:focus {
      border: medium none;
      color: #000;
      padding: 0; }

/* -------- Services Tab ---------- */
.services-tab .nav-tabs {
  border-bottom: 1px solid #f1f1f1;
  text-align: center; }
  .services-tab .nav-tabs > li {
    border-left: 1px solid #eeeeee;
    margin-bottom: 0;
    width: 20%; }
    .services-tab .nav-tabs > li:first-child {
      border-left: none; }
    .services-tab .nav-tabs > li > a {
      background-color: #fff;
      color: #a9a9a9;
      font-size: 13px;
      margin-right: 0;
      padding: 35px 20px;
      text-transform: uppercase; }
      .services-tab .nav-tabs > li > a i {
        display: block;
        font-size: 36px;
        margin-right: 0;
        margin-bottom: 10px;
        vertical-align: middle; }
    .services-tab .nav-tabs > li.active > a {
      color: #fff; }
      .services-tab .nav-tabs > li.active > a:hover, .services-tab .nav-tabs > li.active > a:focus {
        color: #fff; }
    .services-tab .nav-tabs > li a:hover, .services-tab .nav-tabs > li a:focus {
      color: #fff; }
    .services-tab .nav-tabs > li.active > a i, .services-tab .nav-tabs > li.active > a:hover i, .services-tab .nav-tabs > li.active > a:focus i {
      color: #fff; }
    .services-tab .nav-tabs > li a:hover i, .services-tab .nav-tabs > li a:focus i {
      color: #fff; }

.services-tab .tab-content .service-content .sub-title {
  color: #c8c8c8;
  font-family: 'Playfair Display', sans-serif; }

.services-tab .tab-content .service-content .title {
  color: #555555;
  font-family: 'Playfair Display', sans-serif; }

@media only screen and (max-width: 1199px) {
  .services-tab .service-content {
    margin-bottom: 30px; } }

@media only screen and (max-width: 991px) {
  .services-tab .service-content {
    padding: 0 20px; } }

@media only screen and (max-width: 767px) {
  .services-tab .nav.nav-tabs a {
    padding: 10px 0;
    font-size: 9px; }
    .services-tab .nav.nav-tabs a i {
      font-size: 28px; } }

.vertical-tab .nav-tabs li a:active {
  color: #d3d3d3; }

/*
 * Shortcode: Teams.less
 * -----------------------------------------------
*/
.team-members {
  overflow: hidden;
  position: relative; }
  .team-members .team-details {
    background: #fff none repeat scroll 0 0;
    bottom: -100px;
    padding: 10px 15px;
    position: absolute;
    transition: all 300ms ease-in-out 0s;
    width: 100%; }
  .team-members:hover .team-details {
    bottom: 0; }

.team-social {
  opacity: 0;
  padding: 14px 0;
  position: absolute;
  right: 0;
  top: -140px;
  transition: all 300ms ease-in-out 0s; }
  .team-social li {
    display: block; }

.team-members:hover .team-social {
  opacity: 1;
  top: 0; }

.team-members .team-social li a {
  margin: 0; }

.team-social li a i {
  color: #fff; }

.team-social li a:hover i {
  color: #212331; }

@media only screen and (max-width: 1199px) {
  .team-members .team-details {
    bottom: -45px; }
  .team-members:hover .team-details {
    bottom: -18px; } }

/* Small Devices, Tablets */
@media only screen and (max-width: 767px) {
  .team-members .team-details {
    bottom: -75px; } }

/* Medium Devices, Desktops */
@media only screen and (max-width: 991px) {
  .team-members .team-details {
    bottom: -100px; } }

/*
 * Shortcode: testimonials.less
 * -----------------------------------------------
*/
.testimonial .item {
  position: relative;
  overflow: hidden; }
  .testimonial .item .thumb img {
    margin: auto;
    max-width: 75px; }

.testimonial .comment p {
  color: #a9a9a9;
  font-weight: 400; }

/* -------- Testimonial-Style1 ---------- */
.testimonials.attorney-details .thumb img {
  width: auto; }

/* -------- Testimonial-Style2 ---------- */
.testimonial.style2 {
  text-align: center; }
  .testimonial.style2 .thumb img {
    display: inline-block;
    width: auto;
    width: 75px; }

.testimonial.style1 .item {
  border: none; }
  .testimonial.style1 .item::before {
    display: none; }

.testimonial.style1 .quote, .testimonial.style1 .thumb {
  margin-bottom: 30px; }

.testimonial.style1 .author {
  margin-bottom: 5px;
  text-transform: uppercase; }

.testimonial.style1 .title {
  color: #c0c0c0;
  margin-bottom: 20px; }

.testimonial.style1 .comment {
  padding: 35px 25px;
  background: #f8f8f8;
  color: #777777;
  border: 3px solid white;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  margin: 1px;
  position: relative; }
  .testimonial.style1 .comment p {
    font-weight: 500;
    margin-left: 30px; }
  .testimonial.style1 .comment::before {
    color: #d3d3d3;
    content: "\f10d";
    font-family: fontawesome;
    font-size: 30px;
    left: 20px;
    position: absolute;
    top: 7px; }
  .testimonial.style1 .comment::after {
    content: "";
    width: 14px;
    height: 14px;
    position: absolute;
    left: 30px;
    background: url("../images/testimonials/quote_arrow.png") 0px 0px no-repeat;
    top: 97%; }

/* -------- Testimonial-Style1 ---------- */
/*.testimonial.style1 .comment::after {
  bottom: -18px;
  right: 19px;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 15px;
  border-color: fade(@black, 04%) transparent transparent transparent;
}*/
.owl-carousel .owl-item .testimonial img {
  display: inline;
  width: auto; }

.testimonial .signature {
  width: 60px; }

.testimonial-content {
  margin-top: 30px;
  margin-left: 140px;
  position: relative; }
  .testimonial-content::after {
    content: "\f10d";
    font-family: fontawesome;
    font-size: 80px;
    left: 0;
    opacity: 0.1;
    position: absolute;
    top: -65px; }

/*
 * Shortcode: Toggles-Accordions.less
 * -----------------------------------------------
*/
.panel-group .panel {
  box-shadow: none;
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
  border: none;
  position: relative; }

.panel-group .panel-title {
  font-size: 13px; }
  .panel-group .panel-title a {
    background: #f8f8f8;
    display: block;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 15px 10px 48px;
    white-space: normal; }
    .panel-group .panel-title a.active {
      background: #16174f none repeat scroll 0 0;
      border-bottom: 1px solid #d3d3d3;
      color: #fff; }
      .panel-group .panel-title a.active .open-sub:after {
        display: none; }
      .panel-group .panel-title a.active::after {
        color: #fff;
        content: "\f068"; }
    .panel-group .panel-title a::after {
      color: #888;
      content: "\f067";
      font-family: fontawesome;
      font-size: 14px;
      left: 0;
      padding: 10px 14px;
      position: absolute;
      top: 0; }
  .panel-group .panel-title.active.panel-group .panel-title:hover a, .panel-group .panel-title.active.panel-group .panel-title:focus a {
    color: #333333; }
  .panel-group .panel-title .open-sub {
    display: block;
    position: relative; }
    .panel-group .panel-title .open-sub:before, .panel-group .panel-title .open-sub:after {
      content: "";
      display: block;
      position: absolute;
      height: 1px;
      top: 50%;
      margin-top: -0.5px;
      left: 50%; }

.panel-group .panel-content {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #ddd;
  padding: 10px 20px 20px; }
  .panel-group .panel-content > :last-child {
    margin-bottom: 0; }

.panel-group.white .panel-title a {
  background-color: #fff; }

.panel-group.toggle .panel-heading {
  padding: 0;
  border-bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.panel-group.toggle .panel-body {
  padding: 10px 0 5px 25px; }

.panel-group.accordion.transparent .panel-title a, .panel-group.toggle.transparent .panel-title a, .panel-group.accordion.transparent .panel-title a.active, .panel-group.toggle.transparent .panel-title a.active {
  background: transparent;
  border: none;
  color: #333333; }

.panel-group.accordion.transparent .panel-title a::after, .panel-group.toggle.transparent .panel-title a::after, .panel-group.accordion.transparent .panel-title a.active::after, .panel-group.toggle.transparent .panel-title a.active::after {
  color: #333333;
  background: transparent; }

.panel-group.accordion.transparent .panel-content {
  background: transparent; }

.panel-group.toggle.transparent .panel-content {
  background: transparent; }

.panel-group.toggle .panel-heading + .panel-collapse > .panel-body, .panel-group.toggle .panel-heading + .panel-collapse > .list-group {
  border-top: none; }

/* -------- Toggle ---------- */
/*
 * Shortcode: timetable-fullcalendar.less
 * -----------------------------------------------
*/
.fc-timetable-wrapper .fc-scroller {
  height: auto;
  overflow: hidden; }

.fc-timetable-wrapper .fc-toolbar {
  display: none;
  height: 38px;
  margin-bottom: 20px; }
  .fc-timetable-wrapper .fc-toolbar h2 {
    color: #7f7f7f;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 38px;
    text-transform: uppercase; }
  .fc-timetable-wrapper .fc-toolbar .fc-button {
    -moz-box-sizing: border-box;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-box-sizing: border-box;
    -webkit-transition: all 0.3s ease;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-shadow: none;
    box-sizing: border-box;
    height: 38px;
    line-height: 36px;
    transition: all 0.3s ease;
    width: 40px; }
    .fc-timetable-wrapper .fc-toolbar .fc-button:hover {
      background: #5fc7ae;
      border-color: #5fc7ae; }
      .fc-timetable-wrapper .fc-toolbar .fc-button:hover .fc-icon.fc-icon-left-single-arrow {
        background-position: -108px -50px; }
      .fc-timetable-wrapper .fc-toolbar .fc-button:hover .fc-icon.fc-icon-right-single-arrow {
        background-position: -115px -50px; }
    .fc-timetable-wrapper .fc-toolbar .fc-button .fc-icon {
      height: 11px;
      top: -1px;
      width: 6px; }
      .fc-timetable-wrapper .fc-toolbar .fc-button .fc-icon:after {
        display: none; }
      .fc-timetable-wrapper .fc-toolbar .fc-button .fc-icon.fc-icon-left-single-arrow {
        background-position: -100px -50px; }
      .fc-timetable-wrapper .fc-toolbar .fc-button .fc-icon.fc-icon-right-single-arrow {
        background-position: -122px -50px; }

.fc-timetable-wrapper .fc-day-header {
  background: #ffffff;
  color: #7f7f7f;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 52px; }

.fc-timetable-wrapper .fc-time-grid .fc-day.fc-widget-content, .fc-timetable-wrapper .fc-axis {
  background: #ffffff; }

.fc-timetable-wrapper .filter-departments {
  margin-bottom: 20px; }
  .fc-timetable-wrapper .filter-departments li {
    margin-bottom: 20px; }
  .fc-timetable-wrapper .filter-departments a {
    background: #eeeeee;
    border-color: #eeeeee;
    border-radius: 5px;
    color: #333333;
    padding: 5px;
    padding: 7px 15px; }
  .fc-timetable-wrapper .filter-departments .active {
    background: #888888;
    border-color: #888888;
    color: #ffffff; }
  .fc-timetable-wrapper .filter-departments .fc-departments-orthopaedics {
    background: #72a230;
    border-color: #72a230; }
  .fc-timetable-wrapper .filter-departments .fc-departments-cardiology {
    background: #ffab00;
    border-color: #ffab00; }
  .fc-timetable-wrapper .filter-departments .fc-departments-neurology {
    background: #fd4084;
    border-color: #fd4084; }
  .fc-timetable-wrapper .filter-departments .fc-departments-dental {
    background: #00a4ef;
    border-color: #00a4ef; }
  .fc-timetable-wrapper .filter-departments .fc-departments-haematology {
    background: #80acdd;
    border-color: #80acdd; }

.fc-timetable-wrapper .fc-event {
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  border-radius: 0;
  border: 0;
  border: 2px solid #5fc7ae;
  color: #ffffff;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 300;
  transition: all 0.3s ease; }
  .fc-timetable-wrapper .fc-event .fc-bg {
    opacity: 0; }
  .fc-timetable-wrapper .fc-event .fc-content {
    padding-left: 10px;
    padding-top: 10px; }
    .fc-timetable-wrapper .fc-event .fc-content div.fc-time {
      display: none; }
  .fc-timetable-wrapper .fc-event.hide {
    opacity: 0;
    z-index: -2; }
  .fc-timetable-wrapper .fc-event.fc-departments-orthopaedics {
    background: #72a230;
    border-color: #72a230; }
  .fc-timetable-wrapper .fc-event.fc-departments-cardiology {
    background: #ffab00;
    border-color: #ffab00; }
  .fc-timetable-wrapper .fc-event.fc-departments-neurology {
    background: #fd4084;
    border-color: #fd4084; }
  .fc-timetable-wrapper .fc-event.fc-departments-dental {
    background: #00a4ef;
    border-color: #00a4ef; }
  .fc-timetable-wrapper .fc-event.fc-departments-haematology {
    background: #80acdd;
    border-color: #80acdd; }

.fc-timetable-wrapper td.fc-time {
  color: #7f7f7f;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 70px;
  text-align: center; }

.fc-timetable-wrapper .fc-time-grid-container {
  height: auto; }

.fc-timetable-wrapper .fc-content-skeleton .fc-event-container {
  margin: 0; }

@media only screen and (max-width: 768px) {
  .fc-timetable-wrapper .fc-day-header {
    font-size: 10px; }
  .fc-timetable-wrapper .fc-time-grid-event .fc-time, .fc-timetable-wrapper .fc-time-grid-event .fc-title {
    font-size: 12px; }
  .fc-timetable-wrapper .fc-event .fc-content {
    padding-left: 0;
    padding-top: 0; } }

/*
 * Shortcode: Vertical-Timeline.less
 * -----------------------------------------------
*/
.cd-timeline-content {
  background: #f1f1f1; }
  .cd-timeline-content::before {
    border-color: transparent transparent transparent #eee; }

.cd-timeline-block:nth-child(2n) .cd-timeline-content::before {
  border-color: transparent #eee transparent transparent; }

#cd-timeline.cd-timeline-simple::before {
  background: #ccc none repeat scroll 0 0;
  height: 82%;
  top: 65px;
  width: 2px; }

#cd-timeline.cd-timeline-simple.time-line-media::before {
  height: 65%; }

.cd-timeline-simple .cd-timeline-block {
  margin: 38px 0 20px 0; }
  .cd-timeline-simple .cd-timeline-block .cd-timeline-content {
    padding-top: 0;
    background: none;
    box-shadow: none; }
    .cd-timeline-simple .cd-timeline-block .cd-timeline-content::before {
      display: none; }
    .cd-timeline-simple .cd-timeline-block .cd-timeline-content p {
      font-size: 14px; }
  .cd-timeline-simple .cd-timeline-block .cd-timeline-img {
    background: #333 none repeat scroll 0 0;
    box-shadow: 0 0 0 4px #ccc, 0 3px 0 rgba(0, 0, 0, 0.06) inset, 0 0px 0 3px rgba(0, 0, 0, 0.05); }

@media only screen and (max-width: 992px) {
  .cd-timeline-simple .cd-timeline-block .cd-timeline-content {
    text-align: left; } }

@media only screen and (max-width: 768px) {
  .cd-timeline-simple .cd-timeline-block .cd-timeline-content {
    margin-bottom: 10px; } }

@media only screen and (max-width: 992px) {
  .cd-timeline-simple .cd-timeline-block .cd-timeline-content h2 {
    font-size: 20px; } }

@media only screen and (max-width: 768px) {
  .cd-timeline-simple .cd-timeline-block .cd-timeline-content h2 {
    font-size: 18px; } }

@media only screen and (max-width: 768px) {
  .cd-timeline-simple .cd-timeline-block .cd-timeline-content .cd-date {
    font-size: 14px; } }

/* Vertical Masonry Timeline */
.vertical-masonry-timeline .each-masonry-item {
  position: relative;
  width: 50%;
  margin-bottom: 30px; }
  .vertical-masonry-timeline .each-masonry-item:nth-child(2) {
    margin-top: 80px; }
  .vertical-masonry-timeline .each-masonry-item:nth-child(2n) {
    padding-left: 50px; }
  .vertical-masonry-timeline .each-masonry-item:nth-child(2n+1) {
    padding-right: 50px; }
  .vertical-masonry-timeline .each-masonry-item .timeline-block {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #d7e4ed;
    height: 100%;
    padding: 20px;
    position: relative;
    width: 100%; }
    .vertical-masonry-timeline .each-masonry-item .timeline-block:before, .vertical-masonry-timeline .each-masonry-item .timeline-block:after {
      border-style: solid;
      border-width: 8px;
      content: "";
      display: block;
      position: absolute;
      top: 20px; }
  .vertical-masonry-timeline .each-masonry-item:nth-child(2n+1) .timeline-block:before {
    border-color: transparent transparent transparent #c6d2db;
    left: auto;
    right: -16px; }
  .vertical-masonry-timeline .each-masonry-item:nth-child(2n+1) .timeline-block:after {
    border-color: transparent transparent transparent #ffffff;
    left: auto;
    right: -15px; }
  .vertical-masonry-timeline .each-masonry-item:nth-child(2n) .timeline-block:before {
    border-color: transparent #c6d2db transparent transparent;
    left: -16px;
    right: auto; }
  .vertical-masonry-timeline .each-masonry-item:nth-child(2n) .timeline-block:after {
    border-color: transparent #ffffff transparent transparent;
    left: -15px;
    right: auto; }
  .vertical-masonry-timeline .each-masonry-item:nth-child(2n+1) .timeline-post-format {
    left: auto;
    right: -82px; }
  .vertical-masonry-timeline .each-masonry-item:nth-child(2n) .timeline-post-format {
    left: -80px;
    right: auto; }

.timeline-post-format {
  border: 3px solid #bbb;
  border-radius: 100%;
  color: #bbb;
  height: 60px;
  overflow: hidden;
  position: absolute;
  top: -2px;
  width: 60px;
  z-index: 1; }
  .timeline-post-format:after {
    background: #fff none repeat scroll 0 0;
    color: #fff;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
  .timeline-post-format i {
    font-size: 18px;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%); }

.vertical-masonry-timeline-wrapper:before {
  background: #ddd none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 100%;
  left: 50%;
  margin-left: 0px;
  position: absolute;
  width: 4px; }

.vertical-masonry-timeline-wrapper:after {
  background: transparent linear-gradient(#ddd, transparent) repeat scroll 0 0;
  bottom: -100px;
  content: "";
  height: 100px;
  left: 50%;
  margin-left: 0px;
  position: absolute;
  width: 4px; }

.vertical-masonry-timeline::after {
  background: transparent linear-gradient(transparent, #ddd) repeat scroll 0 0;
  content: "";
  height: 100px;
  left: 50%;
  position: absolute;
  top: -65px;
  width: 4px; }

@media only screen and (min-width: 768px) {
  .vertical-masonry-timeline .each-masonry-item.item-right {
    padding-right: 0px;
    padding-left: 50px; }
    .vertical-masonry-timeline .each-masonry-item.item-right .timeline-block:before {
      border-color: transparent #c6d2db transparent transparent;
      left: -16px;
      right: auto; }
    .vertical-masonry-timeline .each-masonry-item.item-right .timeline-block:after {
      border-color: transparent #ffffff transparent transparent;
      left: -15px;
      right: auto; }
    .vertical-masonry-timeline .each-masonry-item.item-right .timeline-post-format {
      left: -80px;
      right: auto; }
  .vertical-masonry-timeline .each-masonry-item.item-left {
    padding-right: 50px;
    padding-left: 0px; }
    .vertical-masonry-timeline .each-masonry-item.item-left .timeline-block:before {
      border-color: transparent transparent transparent #c6d2db;
      right: -16px;
      left: auto; }
    .vertical-masonry-timeline .each-masonry-item.item-left .timeline-block:after {
      border-color: transparent transparent transparent #ffffff;
      right: -15px;
      left: auto; }
    .vertical-masonry-timeline .each-masonry-item.item-left .timeline-post-format {
      right: -81px;
      left: auto; } }

@media only screen and (max-width: 768px) {
  .vertical-masonry-timeline .each-masonry-item {
    width: 100%; }
    .vertical-masonry-timeline .each-masonry-item:nth-child(2n+1) {
      padding-right: 0px;
      padding-left: 71px; }
    .vertical-masonry-timeline .each-masonry-item:nth-child(2n) {
      padding-left: 71px;
      padding-right: inherit; }
    .vertical-masonry-timeline .each-masonry-item:nth-child(2) {
      margin-top: 0; }
    .vertical-masonry-timeline .each-masonry-item:nth-child(2n+1) .timeline-post-format {
      left: -72px;
      right: auto; }
    .vertical-masonry-timeline .each-masonry-item:nth-child(2n) .timeline-post-format {
      left: -72px; }
    .vertical-masonry-timeline .each-masonry-item:nth-child(2n+1) .timeline-block::before {
      border-color: transparent #c6d2db transparent transparent;
      left: -16px;
      right: auto; }
    .vertical-masonry-timeline .each-masonry-item:nth-child(2n+1) .timeline-block::after {
      border-color: transparent #ffffff transparent transparent;
      left: -15px;
      right: auto; }
  .vertical-masonry-timeline:after {
    left: 29px; }
  .vertical-masonry-timeline-wrapper:before, .vertical-masonry-timeline-wrapper:after {
    left: 44px; } }

/*
 * Shortcode: Working-Process.less
 * -----------------------------------------------
*/
.working-process > li {
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center; }
  .working-process > li::after {
    border-top: 1px dashed #333333;
    content: "";
    left: 6px;
    margin-left: -32px;
    overflow: hidden;
    position: absolute;
    top: 25px;
    width: 50%; }
  .working-process > li::before {
    border-top: 1px dashed #333333;
    content: "";
    margin-right: -25px;
    position: absolute;
    right: 0;
    top: 25px;
    width: 50%; }
  .working-process > li :first-child::after, .working-process > li :last-child::before {
    border-top: medium none; }

.working-process li a {
  border: 1px solid;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  width: 50px;
  border-radius: 50%; }
  .working-process li a:hover {
    background: #333333 none repeat scroll 0 0;
    border: 1px solid transparent;
    color: #fff; }
  .working-process li a i {
    font-size: 18px; }

.working-process.square li a, .working-process.square.large-circle li a {
  border-radius: 0; }

.working-process.large-circle li a {
  height: 120px;
  line-height: 130px;
  width: 120px;
  border-radius: 50%; }
  .working-process.large-circle li a i {
    font-size: 28px; }

.working-process.large-circle > li::before {
  margin-right: -60px;
  top: 60px; }

.working-process.large-circle > li::after {
  margin-left: -64px;
  top: 60px; }

.working-process.theme-colored li a {
  color: #fff; }

/*
 * Widgets.less
 * -----------------------------------------------
*/
/* -------- Top Search Bar ---------- */
.search-form-wrapper {
  position: relative;
  /* form absolute */ }
  .search-form-wrapper.toggle {
    display: none; }
    .search-form-wrapper.toggle.active {
      display: block; }
  .search-form-wrapper form {
    padding: 10px;
    position: relative; }
    .search-form-wrapper form label {
      margin: 0px;
      position: absolute;
      right: 11px;
      top: 11px; }
      .search-form-wrapper form label:before {
        color: #555555;
        cursor: pointer;
        content: "\f002";
        font-family: fontawesome;
        font-size: 16px;
        position: absolute;
        right: 13px;
        top: 6px; }
    .search-form-wrapper form input[type="text"] {
      background-color: #fff;
      border: 1px solid #c0c0c0;
      height: 46px;
      padding: 8px 17px;
      width: 100%; }
    .search-form-wrapper form input[type="submit"] {
      background-color: #fff;
      border-left: 1px solid #c0c0c0;
      border: none;
      height: 44px;
      width: 50px;
      text-indent: -999px; }
  .search-form-wrapper.form-absolute form {
    background-color: #333333;
    position: absolute;
    right: 0px;
    top: 12px;
    width: 370px;
    z-index: 99; }
  .search-form-wrapper.round form input[type="text"] {
    border-radius: 30px;
    border-width: 4px; }
  .search-form-wrapper.round form input[type="submit"] {
    height: auto;
    width: auto;
    margin-right: 10px;
    margin-top: 10px; }
  .search-form-wrapper.round form label::before {
    color: #c0c0c0; }

/* -------- Form Round ---------- */
.widget {
  margin-bottom: 30px; }
  .widget .list-border li {
    border-bottom: 1px dashed #d3d3d3; }
  .widget.dark .list-border li {
    border-bottom: 1px dashed #404040; }
  .widget .widget-title {
    margin-top: 0;
    margin-bottom: 20px; }
  .widget ul li {
    margin-bottom: 5px;
    padding-bottom: 5px; }
  .widget ul.list li {
    margin-bottom: 10px;
    padding-bottom: 10px; }
  .widget .post {
    margin-bottom: 10px;
    padding-bottom: 10px; }
  .widget.brochures > li {
    margin-bottom: 10px;
    padding: 10px; }
  .widget.brochures i {
    color: #666666;
    font-size: 16px;
    margin-right: 10px; }
  .widget.address > li {
    font-size: 13px;
    margin-bottom: 10px;
    padding: 5px 10px; }
    .widget.address > li i {
      font-size: 16px;
      margin-right: 8px;
      vertical-align: middle; }
  .widget .twitter-feed li {
    font-size: 13px;
    margin-bottom: 15px;
    margin-top: 0px;
    padding-left: 30px; }
    .widget .twitter-feed li.item {
      position: relative; }
    .widget .twitter-feed li::after {
      content: "\f099";
      font-size: 24px;
      font-family: fontawesome;
      left: 0;
      position: absolute;
      top: 0; }
  .widget .styled-icons li {
    margin-bottom: 0;
    padding-bottom: 0; }
    .widget .styled-icons li a {
      margin-bottom: 0; }
  .widget .tags a {
    border: 1px solid #404040;
    display: inline-block;
    font-size: 12px;
    margin: 5px 4px 5px -2px;
    padding: 5px 10px; }
    .widget .tags a:hover {
      color: #fff; }
  .widget .search-input {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #c0c0c0;
    border-radius: initial;
    box-shadow: none;
    height: 45px;
    padding: 10px; }
  .widget .search-button {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #c0c0c0;
    border-radius: 0;
    height: 45px; }
  .widget .nav-tabs li {
    border-bottom: 1px solid transparent;
    margin-bottom: -2px;
    padding-bottom: 0; }
    .widget .nav-tabs li:hover {
      border-bottom: 1px solid transparent; }
  .widget .post-title a {
    font-size: 14px;
    letter-spacing: 0; }
  .widget .quick-contact-form .form-control {
    background-color: rgba(241, 241, 241, 0.8);
    border-color: #d3d3d3;
    color: #a9a9a9; }
  .widget .product-list .product-title {
    font-size: 14px;
    font-weight: 400; }
  .widget.dark .widget-title {
    color: #fff; }
  .widget.dark .tags a {
    border: 1px solid #404040; }
  .widget.dark .widget-image-carousel .title {
    color: #fff; }
  .widget.dark .search-input {
    border: 1px solid #333333; }
  .widget.dark .styled-icons li a:hover i {
    color: #fff; }
  .widget.dark .search-button {
    border: 1px solid #333333; }
  .widget.dark .widget-subscribe .subscribe-title {
    color: #fff; }
  .widget.dark .widget-subscribe .subscribe-sub-title {
    color: #666666; }
  .widget.dark .nav-tabs li {
    border-bottom: 1px solid transparent;
    margin-bottom: -2px;
    padding-bottom: 0; }
    .widget.dark .nav-tabs li:hover {
      border-bottom: 1px solid transparent; }
      .widget.dark .nav-tabs li:hover a {
        border: 1px solid #555555; }
    .widget.dark .nav-tabs li.active a {
      -moz-border-bottom-colors: none;
      -moz-border-left-colors: none;
      -moz-border-right-colors: none;
      -moz-border-top-colors: none;
      background: #333333 none repeat scroll 0 0;
      border-color: #555555 #555555 transparent;
      border-image: none;
      border-style: solid;
      border-width: 1px; }
  .widget.dark .product-title a {
    color: #808080; }
  .widget.dark .post-title a {
    color: #c0c0c0; }
  .widget.dark .tab-content {
    background: #333333 none repeat scroll 0 0;
    border: 1px solid #555555; }
  .widget.dark .quick-contact-form .form-control {
    background-color: rgba(5, 15, 5, 0.1);
    border-color: #333333; }
  .widget.dark .widget-title.line-bottom-theme-colored-2.border-bottom {
    border-bottom: 1px solid #444; }
  .widget.no-border ul li {
    border: none;
    padding-bottom: 0;
    margin-bottom: 0; }
  .widget ul.list li::before, .widget.dark ul.list li::before {
    display: none; }

/* -------- Footer & Sidebar Widgets ---------- */
/* --------Horizontal Contact Widget  ---------- */
.horizontal-contact-widget {
  border-top: 1px dashed #666666; }
  .horizontal-contact-widget .each-widget::before {
    content: "\f111";
    font-family: FontAwesome;
    left: 0;
    position: absolute;
    right: 0;
    top: -42px; }
  .horizontal-contact-widget.dark {
    border-top: 1px dashed #333333; }

/* -------- Horizontal Contact Widget Dark ---------- */
.widget.dark .btn.btn-default, .widget.dark .panel-contacto .contact-form input.btn-default[type='submit'], .panel-contacto .contact-form .widget.dark input.btn-default[type='submit'] {
  border-color: #333; }

/* -------- Flickr-Feed ---------- */
#flickr-feed img {
  background: none repeat scroll 0 0 #000;
  float: left;
  height: 60px;
  margin: 0 5px 5px 0;
  padding: 0;
  width: 70px; }

.text-hover-theme-colored {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }

.widget .services-list li {
  background-color: #eeeeee;
  border-bottom: 1px solid #ddd;
  padding: 10px 15px 10px 20px; }
  .widget .services-list li.active {
    position: relative;
    border-bottom: 1px solid transparent; }
    .widget .services-list li.active a {
      color: #fff; }
    .widget .services-list li.active::after {
      -moz-border-bottom-colors: none;
      -moz-border-left-colors: none;
      -moz-border-right-colors: none;
      -moz-border-top-colors: none;
      border-image: none;
      border-style: solid;
      border-width: 21px;
      bottom: 0;
      content: "";
      height: 21px;
      left: -42px;
      position: absolute;
      top: 0;
      width: 23px; }

/*
 * footer.less
 * -----------------------------------------------
*/
.footer a {
  color: #808080; }
  .footer a:focus, .footer a:hover, .footer a:active {
    color: #666666; }

.footer .widget .widget-title {
  margin-top: 0px; }

.footer .widget.dark .quick-contact-form button {
  border-color: #333333;
  color: #909090; }
  .footer .widget.dark .quick-contact-form button:hover {
    background-color: #eeeeee;
    border-color: #eeeeee;
    color: #333333; }

/* fixed footer */
footer#footer.fixed-footer {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: -1; }

@media screen and (max-width: 992px) {
  footer#footer.fixed-footer {
    position: relative; } }

body.has-fixed-footer .main-content {
  background-color: #fff; }

@media screen and (max-width: 992px) {
  body.has-fixed-footer .main-content {
    margin-bottom: 0 !important; } }

/*# sourceMappingURL=style-main.css.map */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.slider-l h3 {
  display: block;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase; }

.slider-l .slider-logos {
  padding: 3rem 0 4rem 0; }
  .slider-l .slider-logos a img {
    transition: all .3s;
    display: block;
    margin: 0 auto; }
  .slider-l .slider-logos a:hover img {
    transform: scale(1.05); }
  .slider-l .slider-logos button:before {
    color: #156E7C; }

/* --------------------------------------
@Author: ThemeMascot
@URL: http://themeforest.net/user/ThemeMascot

Default COLOR :  Color Set 1
COLOR CODE1: $color_gris_oscuro
COLOR CODE2: $color_celi
----------------------------------------- */
/*Theme Colored Red Text-color*/
.text-theme-color-2, .work-gallery .gallery-bottom-part .title {
  color: #156E7C; }

/*Theme Colored Red Bg-color*/
/* background-theme-color */
.bg-theme-colored {
  background-color: #212331 !important; }

.bg-theme-colored-lighter2 {
  background-color: #272a3a !important; }

.bg-theme-colored-lighter3 {
  background-color: #2b2e40 !important; }

.bg-theme-colored-lighter4 {
  background-color: #2f3246 !important; }

.bg-theme-colored-darker2 {
  background-color: #1b1c28 !important; }

.bg-theme-colored-darker3 {
  background-color: #171822 !important; }

.bg-theme-colored-darker4 {
  background-color: #13141c !important; }

/* background-color-transparent */
.bg-theme-colored-transparent-9 {
  background-color: rgba(33, 35, 49, 0.9); }

.bg-theme-colored-transparent-8 {
  background-color: rgba(33, 35, 49, 0.8); }

.bg-theme-colored-transparent-7 {
  background-color: rgba(33, 35, 49, 0.7); }

.bg-theme-colored-transparent-6 {
  background-color: rgba(33, 35, 49, 0.6); }

.bg-theme-colored-transparent-5 {
  background-color: rgba(33, 35, 49, 0.5); }

.bg-theme-colored-transparent-4 {
  background-color: rgba(33, 35, 49, 0.4); }

.bg-theme-colored-transparent-3 {
  background-color: rgba(33, 35, 49, 0.3); }

.bg-theme-colored-transparent-2 {
  background-color: rgba(33, 35, 49, 0.2); }

.bg-theme-colored-transparent-1 {
  background-color: rgba(33, 35, 49, 0.1); }

.bg-theme-colored-transparent {
  background-color: rgba(33, 35, 49, 0.75) !important; }

.bg-theme-color-2 {
  background: #156E7C !important; }

.team-block .team-thumb .styled-icons a:hover {
  background: #156E7C; }

.work-gallery .gallery-thumb .styled-icons a:hover, .work-gallery:hover .gallery-bottom-part {
  background: #156E7C; }

.double-line-bottom-theme-colored-2:after, .double-line-bottom-theme-colored-2:before {
  background: #156E7C; }

.line-bottom-theme-colored-2:after, .panel-group .panel-title a.active::after, .line-bottom-centered::after, .event-block .event-date, .team-social, .event-small .event-date, .portfolio-filter a.active {
  background: #156E7C; }

.testimonial-content::after, .top-course-thumb .desc-box .off, .breadcrumb.white li.active, .widget .post-title a:hover {
  color: #156E7C; }

.about-video:hover .video-button {
  background-color: rgba(255, 153, 0, 0.9); }

.panel-group .panel-title a.active::after {
  background-color: #156E7C !important; }

.btn-theme-color-2 {
  background-color: #156E7C; }

/*Theme Colored Red Bg-transparent-color*/
/*Theme Colored Red Border-top-color*/
.border-theme-color-2-1px {
  border: 1px solid #156E7C !important; }

.border-top-theme-color-2-1px {
  border-top: 1px solid #156E7C !important; }

.border-top-theme-color-2-2px {
  border-top: 2px solid #156E7C !important; }

.border-top-theme-color-2-3px {
  border-top: 3px solid #156E7C !important; }

.border-top-theme-color-2-4px {
  border-top: 4px solid #156E7C !important; }

.border-top-theme-color-2-5px {
  border-top: 5px solid #156E7C !important; }

.border-top-theme-color-2-6px {
  border-top: 6px solid #156E7C !important; }

.border-top-theme-color-2-7px {
  border-top: 7px solid #156E7C !important; }

.border-top-theme-color-2-8px {
  border-top: 8px solid #156E7C !important; }

.border-top-theme-color-2-9px {
  border-top: 9px solid #156E7C !important; }

.border-top-theme-color-2-10px {
  border-top: 10px solid #156E7C !important; }

/*Theme Colored Red Border-bottom-color*/
.border-bottom-theme-color-2-1px {
  border-bottom: 1px solid #156E7C !important; }

.border-bottom-theme-color-2-2px {
  border-bottom: 2px solid #156E7C !important; }

.border-bottom-theme-color-2-3px {
  border-bottom: 3px solid #156E7C !important; }

.border-bottom-theme-color-2-4px {
  border-bottom: 4px solid #156E7C !important; }

.border-bottom-theme-color-2-5px {
  border-bottom: 5px solid #156E7C !important; }

.border-bottom-theme-color-2-6px {
  border-bottom: 6px solid #156E7C !important; }

.border-bottom-theme-color-2-7px {
  border-bottom: 7px solid #156E7C !important; }

.border-bottom-theme-color-2-8px {
  border-bottom: 8px solid #156E7C !important; }

.border-bottom-theme-color-2-9px {
  border-bottom: 9px solid #156E7C !important; }

.border-bottom-theme-color-2-10px {
  border-bottom: 10px solid #156E7C !important; }

/*Theme Colored Red Border-left-color*/
.border-left-theme-color-2-1px {
  border-left: 1px solid #156E7C !important; }

.border-left-theme-color-2-2px {
  border-left: 2px solid #156E7C !important; }

.border-left-theme-color-2-3px {
  border-left: 3px solid #156E7C !important; }

.border-left-theme-color-2-4px {
  border-left: 4px solid #156E7C !important; }

.border-left-theme-color-2-5px {
  border-left: 5px solid #156E7C !important; }

.border-left-theme-color-2-6px {
  border-left: 6px solid #156E7C !important; }

.border-left-theme-color-2-7px {
  border-left: 7px solid #156E7C !important; }

.border-left-theme-color-2-8px {
  border-left: 8px solid #156E7C !important; }

.border-left-theme-color-2-9px {
  border-left: 9px solid #156E7C !important; }

.border-left-theme-color-2-10px {
  border-left: 10px solid #156E7C !important; }

/*Theme Colored Red Border-right-color*/
.border-right-theme-color-2-1px {
  border-right: 1px solid #156E7C !important; }

.border-right-theme-color-2-2px {
  border-right: 2px solid #156E7C !important; }

.border-right-theme-color-2-3px {
  border-right: 3px solid #156E7C !important; }

.border-right-theme-color-2-4px {
  border-right: 4px solid #156E7C !important; }

.border-right-theme-color-2-5px {
  border-right: 5px solid #156E7C !important; }

.border-right-theme-color-2-6px {
  border-right: 6px solid #156E7C !important; }

.border-right-theme-color-2-7px {
  border-right: 7px solid #156E7C !important; }

.border-right-theme-color-2-8px {
  border-right: 8px solid #156E7C !important; }

.border-right-theme-color-2-9px {
  border-right: 9px solid #156E7C !important; }

.border-right-theme-color-2-10px {
  border-right: 10px solid #156E7C !important; }

/*Theme Colored 2 styles*/
.review-stars i {
  color: #156E7C; }

.price-tag {
  background: #156E7C; }

/*
 * background: theme-color
 * -----------------------------------------------
*/
.tab-slider .nav.nav-pills a:hover, .tab-slider .nav.nav-pills a.active {
  background: #212331; }

.custom-nav-tabs > li > a:hover, .widget .tags a:hover, .progress-item .progress-bar, .small-title .title::after, .testimonial .item::after {
  background: #212331; }

.drop-caps.colored-square p:first-child:first-letter, .drop-caps.colored-rounded p:first-child:first-letter {
  background: #212331; }

.list-icon.theme-colored.square li i, .list-icon.theme-colored.rounded li i {
  background: #212331; }

.working-process.theme-colored a, .blog-posts .post .entry-content .post-date.right {
  background: #212331; }

.horizontal-tab-centered .nav-pills > li > a:hover {
  background: #212331; }

.horizontal-tab-centered .nav-pills > li.active > a {
  background: #212331; }
  .horizontal-tab-centered .nav-pills > li.active > a:hover, .horizontal-tab-centered .nav-pills > li.active > a:focus {
    background: #212331; }

.owl-theme.dot-theme-colored .owl-controls .owl-dot span, .pagination.theme-colored li.active a {
  background: #212331; }

.section-title .both-side-line::after, .section-title .both-side-line::before {
  background: #212331; }

.section-title .top-side-line::after, .section-title .left-side-line::before, .section-title .right-side-line::before {
  background: #212331; }

.product .tag-sale, .owl-theme .owl-dots .owl-dot.active span, .line-bottom:after, .line-bottom-center:after, .portfolio-filter a:hover, .gallery-isotope .gallery-item .overlay-shade {
  background: #212331; }

.panel-group .panel-title a.active {
  background-color: #212331 !important; }

.ui-state-highlight {
  background: #212331 !important;
  color: #fff !important; }

/*
 * text: theme-color
 * -----------------------------------------------
*/
ul.list.theme-colored li:before, ul.list.theme-colored.angle-double-right li:before, ul.list.theme-colored.angle-right li:before, ul.list.theme-colored.check-circle li:before, ul.list.theme-colored.check li:before {
  color: #212331; }

.list-icon.theme-colored li i, .pricing-table .table-list li i, .testimonial-carousel.boxed .content::after, .blog-posts .post .entry-meta li i, .widget .address li i, .icon-box.box-style1.practice-style3 i, .attorney-carousel .content .contact-area i, .attorney-address li i, .icon-box.services-style1:hover .heading, .small-title i, .drop-caps.text-colored p:first-child:first-letter, .schedule-box:hover .schedule-details .title a, .widget.dark .nav-tabs li.active a, .pagination.theme-colored li a, .pager.theme-colored a, .volunteer .info .name a, .donation-form .form-group label, .team-block .team-thumb .team-overlay .styled-icons a:hover i, .section-title .title-icon i, .portfolio-filter a, .panel-group .panel-title a, .testimonials-details::after {
  color: #212331; }

.menuzord .menuzord-menu > li.active > a, .menuzord .menuzord-menu > li:hover > a {
  background: #156E7C none repeat scroll 0 0;
  color: #fff; }

.menuzord .menuzord-menu ul.dropdown li:hover > a {
  background: #156E7C none repeat scroll 0 0;
  color: #fff; }

.menuzord .menuzord-menu ul.dropdown li:hover > a i {
  color: #fff; }

.menuzord .menuzord-menu > li.active > a i, .menuzord .menuzord-menu > li:hover > a i {
  color: #fff; }

/* text-theme-color */
.text-theme-colored, .text-hover-theme-colored:hover {
  color: #212331 !important; }

/* text-hover-theme-color */
/* background-theme-color */
.bg-theme-colored {
  background-color: #212331 !important; }

/* bg-hover-theme-color */
.bg-hover-theme-colored:hover {
  background: #212331 !important;
  border-color: #212331 !important;
  color: #fff !important; }
  .bg-hover-theme-colored:hover h1, .bg-hover-theme-colored:hover h2, .bg-hover-theme-colored:hover h3, .bg-hover-theme-colored:hover h4, .bg-hover-theme-colored:hover h5, .bg-hover-theme-colored:hover h6, .bg-hover-theme-colored:hover p, .bg-hover-theme-colored:hover a, .bg-hover-theme-colored:hover i {
    color: #fff !important; }

.hover-text-theme-color-2:hover {
  color: #156E7C; }

/* border-theme-color */
.border-theme-colored {
  border-color: #212331 !important; }

.widget .services-list li.active {
  background-color: #212331 !important;
  border-right: 6px solid #156E7C !important; }
  .widget .services-list li.active::after {
    border-color: transparent #212331 transparent transparent; }

/* background-color-transparent */
.bg-theme-colored-transparent, .piechart-block {
  background-color: rgba(33, 35, 49, 0.75) !important; }

.service-block .thumb .price, .service-block .thumb .desc {
  background: rgba(33, 35, 49, 0.7); }

.team-block .team-thumb .team-overlay, .team-block .team-thumb .styled-icons {
  background: rgba(33, 35, 49, 0.7); }

.work-gallery .gallery-thumb .gallery-overlay {
  background: rgba(33, 35, 49, 0.7); }

.team-block .team-thumb .team-overlay::after {
  border-color: rgba(33, 35, 49, 0.75) transparent transparent rgba(33, 35, 49, 0.75); }

.team-block .team-thumb .styled-icons::after {
  border-color: transparent rgba(33, 35, 49, 0.75) rgba(33, 35, 49, 0.75) transparent; }

/* Layer-overlay */
.overlay-theme-colored-1:before {
  background-color: rgba(33, 35, 49, 0.1) !important; }

.overlay-theme-colored-2:before {
  background-color: rgba(33, 35, 49, 0.2) !important; }

.overlay-theme-colored-3:before {
  background-color: rgba(33, 35, 49, 0.3) !important; }

.overlay-theme-colored-4:before {
  background-color: rgba(33, 35, 49, 0.4) !important; }

.overlay-theme-colored-5:before {
  background-color: rgba(33, 35, 49, 0.5) !important; }

.overlay-theme-colored-6:before {
  background-color: rgba(33, 35, 49, 0.6) !important; }

.overlay-theme-colored-7:before {
  background-color: rgba(33, 35, 49, 0.7) !important; }

.overlay-theme-colored-8:before {
  background-color: rgba(33, 35, 49, 0.8) !important; }

.overlay-theme-colored-9:before, .post .entry-meta.meta-absolute, .volunteer .overlay, .bg-theme-colored-transparent-deep {
  background-color: rgba(33, 35, 49, 0.9) !important; }

/* Layeroverlay */
.icon-box.box-style1.practice-style3:hover .icon-wrapper, .zeus .tp-bullet::after {
  background-color: #212331; }

.preloader-dot-loading .cssload-loading i {
  background: #212331 !important; }

/*
 * border: theme-color
 * -----------------------------------------------
*/
.icon-box.icon-rounded-bordered i, .widget .line-bottom:after, .pager.theme-colored a {
  border: 1px solid #212331; }

.border-theme-colored, .owl-theme .owl-dots .owl-dot span {
  border-color: #212331; }

.icon-box.box-style1.practice-style3:hover .icon-wrapper::after {
  border-color: #212331 transparent transparent; }

.opening-hours ul li {
  border-bottom: 1px dashed #212331; }

/* border-left-color */
blockquote.theme-colored {
  border-left: 3px solid #212331; }

blockquote.bg-theme-colored {
  border-left: 3px solid #0c0d13;
  color: #fff; }
  blockquote.bg-theme-colored footer {
    color: #eeeeee; }

blockquote.gray.bg-theme-colored {
  background-color: #eeeeee !important;
  border-left: 3px solid #212331;
  color: #212331; }
  blockquote.gray.bg-theme-colored footer {
    color: #777777; }

.attorney-address li {
  border-left: 1px solid #212331; }

.section-title.title-border {
  border-left: 4px solid #212331; }

/* border-top-color */
.member-info {
  border-top: 3px outset #212331; }

.cssload-tri {
  border-top: 27px solid #212331; }

/* border-right-color */
.border-right-theme-color-2 {
  border-right: 1px solid #212331; }

.section-title.title-border.title-right {
  border-right: 4px solid #212331; }

/* border-bottom-color */
.event {
  border-bottom-color: #212331; }

.cssload-tri.cssload-invert {
  border-bottom: 27px solid #212331; }

/* box-shadow */
/*
 * btn-default: theme-color
 * -----------------------------------------------
*/
.btn-default.btn-theme-colored {
  color: #212331;
  background-color: #fff;
  border-color: #212331; }
  .btn-default.btn-theme-colored:focus, .btn-default.btn-theme-colored.focus {
    color: #212331;
    background-color: #e6e6e6;
    border-color: #000000; }
  .btn-default.btn-theme-colored:hover, .btn-default.btn-theme-colored:active, .btn-default.btn-theme-colored.active {
    color: #212331;
    background-color: #e6e6e6;
    border-color: #08090c; }

.open > .dropdown-toggle.btn-default.btn-theme-colored {
  color: #212331;
  background-color: #e6e6e6;
  border-color: #08090c; }

.btn-default.btn-theme-colored:active:hover, .btn-default.btn-theme-colored.active:hover {
  color: #212331;
  background-color: #d4d4d4;
  border-color: #000000; }

.open > .dropdown-toggle.btn-default.btn-theme-colored:hover {
  color: #212331;
  background-color: #d4d4d4;
  border-color: #000000; }

.btn-default.btn-theme-colored:active:focus, .btn-default.btn-theme-colored.active:focus {
  color: #212331;
  background-color: #d4d4d4;
  border-color: #000000; }

.open > .dropdown-toggle.btn-default.btn-theme-colored:focus {
  color: #212331;
  background-color: #d4d4d4;
  border-color: #000000; }

.btn-default.btn-theme-colored:active.focus, .btn-default.btn-theme-colored.active.focus {
  color: #212331;
  background-color: #d4d4d4;
  border-color: #000000; }

.open > .dropdown-toggle.btn-default.btn-theme-colored.focus {
  color: #212331;
  background-color: #d4d4d4;
  border-color: #000000; }

.btn-default.btn-theme-colored:active, .btn-default.btn-theme-colored.active {
  background-image: none; }

.open > .dropdown-toggle.btn-default.btn-theme-colored {
  background-image: none; }

.btn-default.btn-theme-colored.disabled:hover, .btn-default.btn-theme-colored[disabled]:hover {
  background-color: #fff;
  border-color: #212331; }

fieldset[disabled] .btn-default.btn-theme-colored:hover {
  background-color: #fff;
  border-color: #212331; }

.btn-default.btn-theme-colored.disabled:focus, .btn-default.btn-theme-colored[disabled]:focus {
  background-color: #fff;
  border-color: #212331; }

fieldset[disabled] .btn-default.btn-theme-colored:focus {
  background-color: #fff;
  border-color: #212331; }

.btn-default.btn-theme-colored.disabled.focus, .btn-default.btn-theme-colored[disabled].focus {
  background-color: #fff;
  border-color: #212331; }

fieldset[disabled] .btn-default.btn-theme-colored.focus {
  background-color: #fff;
  border-color: #212331; }

.btn-default.btn-theme-colored .badge {
  color: #fff;
  background-color: #212331; }

.btn-default.btn-theme-colored:hover, .btn-default.btn-theme-colored:active, .btn-default.btn-theme-colored:focus {
  background-color: #212331;
  border-color: #212331;
  color: #fff; }

/*
 * btn-border
 * -----------------------------------------------
*/
.btn-border.btn-theme-colored {
  color: #212331;
  background-color: #fff;
  border-color: #212331; }
  .btn-border.btn-theme-colored:focus, .btn-border.btn-theme-colored.focus {
    color: #212331;
    background-color: #e6e6e6;
    border-color: #000000; }
  .btn-border.btn-theme-colored:hover, .btn-border.btn-theme-colored:active, .btn-border.btn-theme-colored.active {
    color: #212331;
    background-color: #e6e6e6;
    border-color: #08090c; }

.open > .dropdown-toggle.btn-border.btn-theme-colored {
  color: #212331;
  background-color: #e6e6e6;
  border-color: #08090c; }

.btn-border.btn-theme-colored:active:hover, .btn-border.btn-theme-colored.active:hover {
  color: #212331;
  background-color: #d4d4d4;
  border-color: #000000; }

.open > .dropdown-toggle.btn-border.btn-theme-colored:hover {
  color: #212331;
  background-color: #d4d4d4;
  border-color: #000000; }

.btn-border.btn-theme-colored:active:focus, .btn-border.btn-theme-colored.active:focus {
  color: #212331;
  background-color: #d4d4d4;
  border-color: #000000; }

.open > .dropdown-toggle.btn-border.btn-theme-colored:focus {
  color: #212331;
  background-color: #d4d4d4;
  border-color: #000000; }

.btn-border.btn-theme-colored:active.focus, .btn-border.btn-theme-colored.active.focus {
  color: #212331;
  background-color: #d4d4d4;
  border-color: #000000; }

.open > .dropdown-toggle.btn-border.btn-theme-colored.focus {
  color: #212331;
  background-color: #d4d4d4;
  border-color: #000000; }

.btn-border.btn-theme-colored:active, .btn-border.btn-theme-colored.active {
  background-image: none; }

.open > .dropdown-toggle.btn-border.btn-theme-colored {
  background-image: none; }

.btn-border.btn-theme-colored.disabled:hover, .btn-border.btn-theme-colored[disabled]:hover {
  background-color: #fff;
  border-color: #212331; }

fieldset[disabled] .btn-border.btn-theme-colored:hover {
  background-color: #fff;
  border-color: #212331; }

.btn-border.btn-theme-colored.disabled:focus, .btn-border.btn-theme-colored[disabled]:focus {
  background-color: #fff;
  border-color: #212331; }

fieldset[disabled] .btn-border.btn-theme-colored:focus {
  background-color: #fff;
  border-color: #212331; }

.btn-border.btn-theme-colored.disabled.focus, .btn-border.btn-theme-colored[disabled].focus {
  background-color: #fff;
  border-color: #212331; }

fieldset[disabled] .btn-border.btn-theme-colored.focus {
  background-color: #fff;
  border-color: #212331; }

.btn-border.btn-theme-colored .badge {
  color: #fff;
  background-color: #212331; }

.btn-border.btn-theme-colored:hover, .btn-border.btn-theme-colored:active, .btn-border.btn-theme-colored:focus {
  background-color: #0c0d13;
  border-color: #0c0d13;
  color: #fff; }

/*
 * btn-dark
 * -----------------------------------------------
*/
.btn-dark.btn-theme-colored {
  color: #fff;
  background-color: #212331;
  border-color: #212331; }
  .btn-dark.btn-theme-colored:focus, .btn-dark.btn-theme-colored.focus {
    color: #fff;
    background-color: #0c0d13;
    border-color: #000000; }
  .btn-dark.btn-theme-colored:hover, .btn-dark.btn-theme-colored:active, .btn-dark.btn-theme-colored.active {
    color: #fff;
    background-color: #0c0d13;
    border-color: #08090c; }

.open > .dropdown-toggle.btn-dark.btn-theme-colored {
  color: #fff;
  background-color: #0c0d13;
  border-color: #08090c; }

.btn-dark.btn-theme-colored:active:hover, .btn-dark.btn-theme-colored.active:hover {
  color: #fff;
  background-color: #000000;
  border-color: #000000; }

.open > .dropdown-toggle.btn-dark.btn-theme-colored:hover {
  color: #fff;
  background-color: #000000;
  border-color: #000000; }

.btn-dark.btn-theme-colored:active:focus, .btn-dark.btn-theme-colored.active:focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000; }

.open > .dropdown-toggle.btn-dark.btn-theme-colored:focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000; }

.btn-dark.btn-theme-colored:active.focus, .btn-dark.btn-theme-colored.active.focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000; }

.open > .dropdown-toggle.btn-dark.btn-theme-colored.focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000; }

.btn-dark.btn-theme-colored:active, .btn-dark.btn-theme-colored.active {
  background-image: none; }

.open > .dropdown-toggle.btn-dark.btn-theme-colored {
  background-image: none; }

.btn-dark.btn-theme-colored.disabled:hover, .btn-dark.btn-theme-colored[disabled]:hover {
  background-color: #212331;
  border-color: #212331; }

fieldset[disabled] .btn-dark.btn-theme-colored:hover {
  background-color: #212331;
  border-color: #212331; }

.btn-dark.btn-theme-colored.disabled:focus, .btn-dark.btn-theme-colored[disabled]:focus {
  background-color: #212331;
  border-color: #212331; }

fieldset[disabled] .btn-dark.btn-theme-colored:focus {
  background-color: #212331;
  border-color: #212331; }

.btn-dark.btn-theme-colored.disabled.focus, .btn-dark.btn-theme-colored[disabled].focus {
  background-color: #212331;
  border-color: #212331; }

fieldset[disabled] .btn-dark.btn-theme-colored.focus {
  background-color: #212331;
  border-color: #212331; }

.btn-dark.btn-theme-colored .badge {
  color: #212331;
  background-color: #fff; }

/*
 * btn-gray
 * -----------------------------------------------
*/
.btn-gray.btn-theme-colored {
  color: #fff;
  background-color: #36394f;
  border-color: #36394f; }
  .btn-gray.btn-theme-colored:focus, .btn-gray.btn-theme-colored.focus {
    color: #fff;
    background-color: #212331;
    border-color: #020203; }
  .btn-gray.btn-theme-colored:hover, .btn-gray.btn-theme-colored:active, .btn-gray.btn-theme-colored.active {
    color: #fff;
    background-color: #212331;
    border-color: #1d1f2b; }

.open > .dropdown-toggle.btn-gray.btn-theme-colored {
  color: #fff;
  background-color: #212331;
  border-color: #1d1f2b; }

.btn-gray.btn-theme-colored:active:hover, .btn-gray.btn-theme-colored.active:hover {
  color: #fff;
  background-color: #13141c;
  border-color: #020203; }

.open > .dropdown-toggle.btn-gray.btn-theme-colored:hover {
  color: #fff;
  background-color: #13141c;
  border-color: #020203; }

.btn-gray.btn-theme-colored:active:focus, .btn-gray.btn-theme-colored.active:focus {
  color: #fff;
  background-color: #13141c;
  border-color: #020203; }

.open > .dropdown-toggle.btn-gray.btn-theme-colored:focus {
  color: #fff;
  background-color: #13141c;
  border-color: #020203; }

.btn-gray.btn-theme-colored:active.focus, .btn-gray.btn-theme-colored.active.focus {
  color: #fff;
  background-color: #13141c;
  border-color: #020203; }

.open > .dropdown-toggle.btn-gray.btn-theme-colored.focus {
  color: #fff;
  background-color: #13141c;
  border-color: #020203; }

.btn-gray.btn-theme-colored:active, .btn-gray.btn-theme-colored.active {
  background-image: none; }

.open > .dropdown-toggle.btn-gray.btn-theme-colored {
  background-image: none; }

.btn-gray.btn-theme-colored.disabled:hover, .btn-gray.btn-theme-colored[disabled]:hover {
  background-color: #36394f;
  border-color: #36394f; }

fieldset[disabled] .btn-gray.btn-theme-colored:hover {
  background-color: #36394f;
  border-color: #36394f; }

.btn-gray.btn-theme-colored.disabled:focus, .btn-gray.btn-theme-colored[disabled]:focus {
  background-color: #36394f;
  border-color: #36394f; }

fieldset[disabled] .btn-gray.btn-theme-colored:focus {
  background-color: #36394f;
  border-color: #36394f; }

.btn-gray.btn-theme-colored.disabled.focus, .btn-gray.btn-theme-colored[disabled].focus {
  background-color: #36394f;
  border-color: #36394f; }

fieldset[disabled] .btn-gray.btn-theme-colored.focus {
  background-color: #36394f;
  border-color: #36394f; }

.btn-gray.btn-theme-colored .badge {
  color: #36394f;
  background-color: #fff; }

/*
 * btn-primary: theme-color
 * -----------------------------------------------
*/
.btn-theme-colored {
  color: #fff;
  background-color: #212331;
  border-color: #212331; }
  .btn-theme-colored:focus, .btn-theme-colored.focus {
    color: #fff;
    background-color: #0c0d13;
    border-color: #000000; }
  .btn-theme-colored:hover, .btn-theme-colored:active, .btn-theme-colored.active {
    color: #fff;
    background-color: #0c0d13;
    border-color: #08090c; }

.open > .dropdown-toggle.btn-theme-colored {
  color: #fff;
  background-color: #0c0d13;
  border-color: #08090c; }

.btn-theme-colored:active:hover, .btn-theme-colored.active:hover {
  color: #fff;
  background-color: #000000;
  border-color: #000000; }

.open > .dropdown-toggle.btn-theme-colored:hover {
  color: #fff;
  background-color: #000000;
  border-color: #000000; }

.btn-theme-colored:active:focus, .btn-theme-colored.active:focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000; }

.open > .dropdown-toggle.btn-theme-colored:focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000; }

.btn-theme-colored:active.focus, .btn-theme-colored.active.focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000; }

.open > .dropdown-toggle.btn-theme-colored.focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000; }

.btn-theme-colored:active, .btn-theme-colored.active {
  background-image: none; }

.open > .dropdown-toggle.btn-theme-colored {
  background-image: none; }

.btn-theme-colored.disabled:hover, .btn-theme-colored[disabled]:hover {
  background-color: #212331;
  border-color: #212331; }

fieldset[disabled] .btn-theme-colored:hover {
  background-color: #212331;
  border-color: #212331; }

.btn-theme-colored.disabled:focus, .btn-theme-colored[disabled]:focus {
  background-color: #212331;
  border-color: #212331; }

fieldset[disabled] .btn-theme-colored:focus {
  background-color: #212331;
  border-color: #212331; }

.btn-theme-colored.disabled.focus, .btn-theme-colored[disabled].focus {
  background-color: #212331;
  border-color: #212331; }

fieldset[disabled] .btn-theme-colored.focus {
  background-color: #212331;
  border-color: #212331; }

.btn-theme-colored .badge {
  color: #212331;
  background-color: #fff; }

/*
 * btn-transparent
 * -----------------------------------------------
*/
.btn-transparent {
  background-color: transparent;
  color: #fff; }
  .btn-transparent.btn-theme-colored {
    background-color: transparent; }
  .btn-transparent.btn-dark {
    background-color: #111111;
    border-color: #111111; }
    .btn-transparent.btn-dark:hover {
      background-color: #000000;
      border-color: #000000; }
    .btn-transparent.btn-dark.btn-theme-colored {
      background-color: #212331;
      border-color: #212331; }
      .btn-transparent.btn-dark.btn-theme-colored:hover {
        background-color: #0c0d13;
        border-color: #0c0d13; }
  .btn-transparent.btn-border {
    background-color: transparent;
    border-color: #eeeeee; }
    .btn-transparent.btn-border:hover {
      background-color: #eeeeee;
      color: #000; }
    .btn-transparent.btn-border.btn-theme-colored {
      background-color: transparent;
      border-color: #212331; }
      .btn-transparent.btn-border.btn-theme-colored:hover {
        background-color: #212331;
        color: #fff; }

.btn-gray.btn-transparent {
  background-color: #d3d3d3;
  color: #333333; }
  .btn-gray.btn-transparent:hover {
    background-color: #ececec;
    color: #1a1a1a; }
  .btn-gray.btn-transparent.btn-theme-colored {
    background-color: #36394f;
    color: #fff; }
    .btn-gray.btn-transparent.btn-theme-colored:hover {
      background-color: #0c0d13;
      color: #e6e6e6; }

.btn-hover-theme-colored:hover {
  background-color: #212331;
  border-color: #212331;
  color: #fff; }

/*
 * Shortcode: styled-icons Theme Colored
 * -----------------------------------------------
*/
.icon-theme-colored a {
  color: #212331; }

.icon-theme-colored.icon-bordered a {
  border-color: #212331; }
  .icon-theme-colored.icon-bordered a:hover {
    background-color: #212331;
    color: #fff; }

.icon-theme-colored.icon-dark a {
  background-color: #212331;
  color: #fff; }
  .icon-theme-colored.icon-dark a:hover {
    background-color: #0c0d13;
    color: #e6e6e6; }

.icon-theme-colored.icon-dark.icon-bordered a {
  border-color: #212331;
  color: #212331; }
  .icon-theme-colored.icon-dark.icon-bordered a:hover {
    background-color: #212331;
    border-color: #212331;
    color: #fff; }

.icon-theme-colored.icon-gray a {
  color: #212331; }
  .icon-theme-colored.icon-gray a:hover {
    color: #0c0d13; }

.icon-theme-colored.icon-gray.icon-bordered a {
  color: #212331; }
  .icon-theme-colored.icon-gray.icon-bordered a:hover {
    background-color: #eeeeee;
    border-color: #eeeeee;
    color: #212331; }

/*
 * Shortcode: styled-icons Theme Color 2
 * -----------------------------------------------
*/
.icon-theme-color-2 a {
  color: #156E7C; }

.icon-theme-color-2.icon-bordered a {
  border-color: #156E7C; }
  .icon-theme-color-2.icon-bordered a:hover {
    background-color: #156E7C;
    color: #fff; }

.icon-theme-color-2.icon-dark a {
  background-color: #156E7C;
  color: #fff; }
  .icon-theme-color-2.icon-dark a:hover {
    background-color: #115b66;
    color: #e6e6e6; }

.icon-theme-color-2.icon-dark.icon-bordered a {
  border-color: #156E7C;
  color: #156E7C; }
  .icon-theme-color-2.icon-dark.icon-bordered a:hover {
    background-color: #156E7C;
    border-color: #156E7C;
    color: #fff; }

.icon-theme-colored a:hover {
  color: #0c0d13; }

.icon-hover-theme-colored a:hover, .styled-icons.icon-hover-theme-colored a:hover {
  background-color: #212331;
  border-color: #212331;
  color: #fff; }

/*
 * Shortcode: Icon Boxes Theme Colored
 * -----------------------------------------------
*/
.icon-box.iconbox-theme-colored .icon {
  color: #212331; }
  .icon-box.iconbox-theme-colored .icon.icon-border-effect::after {
    box-shadow: 0 0 0 3px #212331; }
  .icon-box.iconbox-theme-colored .icon.icon-bordered {
    border-color: #212331; }
    .icon-box.iconbox-theme-colored .icon.icon-bordered:hover {
      background-color: #212331;
      color: #fff; }
  .icon-box.iconbox-theme-colored .icon.icon-gray:hover {
    background-color: #212331;
    color: #fff; }
  .icon-box.iconbox-theme-colored .icon.icon-gray.icon-bordered {
    border-color: #eeeeee; }
    .icon-box.iconbox-theme-colored .icon.icon-gray.icon-bordered:hover {
      background-color: #212331;
      border-color: #212331;
      color: #fff; }
  .icon-box.iconbox-theme-colored .icon.icon-dark {
    background-color: #212331;
    color: #fff; }
    .icon-box.iconbox-theme-colored .icon.icon-dark:hover {
      color: #fff; }
    .icon-box.iconbox-theme-colored .icon.icon-dark.icon-bordered {
      background-color: transparent;
      border-color: #212331;
      color: #212331; }
      .icon-box.iconbox-theme-colored .icon.icon-dark.icon-bordered:hover {
        background-color: #212331;
        border-color: #212331;
        color: #fff; }
  .icon-box.iconbox-theme-colored .icon.icon-white {
    background-color: #fff;
    color: #212331; }
    .icon-box.iconbox-theme-colored .icon.icon-white:hover {
      background-color: #212331;
      color: #fff; }
    .icon-box.iconbox-theme-colored .icon.icon-white.icon-bordered {
      background-color: transparent;
      border-color: #212331;
      color: #fff; }
      .icon-box.iconbox-theme-colored .icon.icon-white.icon-bordered:hover {
        background-color: #212331;
        border-color: #212331;
        color: #fff; }

.icon-box.iconbox-theme-colored.iconbox-border {
  border-color: #212331; }

/*# sourceMappingURL=theme-skin-color-set-1.css.map */
.custom-logo-link {
  margin-top: .2rem;
  margin-left: 0; }
  .custom-logo-link img {
    width: auto;
    max-height: 70px; }

.styled-icons, .list-inline {
  margin-bottom: 0;
  font-size: 12px; }

.header-nav-wrapper #menu-item-296 {
  display: none; }

@media (max-width: 480px) {
  .header-nav-wrapper {
    padding: 1rem 0;
    box-shadow: 0 0 2px 1px grey; } }

@media (max-width: 480px) {
  .header-nav-wrapper .menuzord-brand {
    display: none; } }

.header-nav-wrapper ul.menu-header-site li {
  position: relative; }

.header-nav-wrapper ul.menu-header-site .current-menu-item a {
  background: #156E7C;
  color: whitesmoke; }

.header-nav-wrapper ul.menu-header-site .current-menu-item .sub-menu a {
  background: transparent; }

.header-nav-wrapper ul.menu-header-site li.menu-item-has-children:hover ul.sub-menu {
  display: block;
  min-width: 180px; }
  .header-nav-wrapper ul.menu-header-site li.menu-item-has-children:hover ul.sub-menu .sub-menu {
    width: 130%;
    margin-left: -5px !important; }

.header-nav-wrapper ul.menu-header-site li.menu-item-has-children ul.sub-menu {
  display: none;
  position: absolute;
  z-index: 200;
  left: auto;
  width: 100%;
  margin-left: 0;
  background: white;
  margin-top: .3rem;
  padding: 1rem;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5); }
  .header-nav-wrapper ul.menu-header-site li.menu-item-has-children ul.sub-menu .current-menu-item a {
    color: #156E7C !important;
    background: transparent; }
  .header-nav-wrapper ul.menu-header-site li.menu-item-has-children ul.sub-menu li a {
    width: 100%;
    display: block;
    color: black;
    position: relative; }
    .header-nav-wrapper ul.menu-header-site li.menu-item-has-children ul.sub-menu li a:before {
      transition: all .3s;
      content: '';
      height: 1px;
      width: 0;
      position: absolute;
      left: 0;
      bottom: 0;
      background: lightgrey; }
    .header-nav-wrapper ul.menu-header-site li.menu-item-has-children ul.sub-menu li a:hover:before {
      content: '';
      height: 1px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      background: lightgrey; }
  .header-nav-wrapper ul.menu-header-site li.menu-item-has-children ul.sub-menu li.menu-item-has-children:hover .sub-menu {
    display: block; }
  .header-nav-wrapper ul.menu-header-site li.menu-item-has-children ul.sub-menu li.menu-item-has-children ul.sub-menu {
    display: none;
    position: absolute;
    left: 100%;
    top: -8% !important;
    margin-top: 0;
    margin-left: -8px !important; }

.line-footer {
  height: 25px;
  width: 100%;
  background: #156E7C; }

footer .opening-hours li a {
  font-size: 1rem;
  color: grey; }

footer .chat {
  position: fixed;
  width: 300px;
  bottom: 0;
  right: 5%;
  background: #156E7C; }
  footer .chat .wpcf7-validation-errors, footer .chat .wpcf7-response-output {
    padding: 0;
    text-align: center;
    margin: 3px;
    color: white; }
  footer .chat span.wpcf7-not-valid-tip {
    color: white;
    font-size: .9rem; }
  footer .chat p {
    line-height: 1.2;
    padding: 0;
    margin: 3px 0; }
  footer .chat .chat-header {
    background: white;
    padding: 5px;
    border: 1px solid #156E7C;
    display: block;
    cursor: pointer; }
    footer .chat .chat-header img {
      height: 20px; }
    footer .chat .chat-header span {
      text-transform: uppercase;
      padding-left: 5px;
      color: #156E7C; }
  footer .chat .chat-body {
    padding: 10px;
    display: none; }
    footer .chat .chat-body input[type='text'], footer .chat .chat-body input[type='email'] {
      height: 30px;
      border-radius: 0;
      border: none;
      text-indent: 6px; }
    footer .chat .chat-body textarea {
      height: 85px;
      border: none; }
    footer .chat .chat-body .btn, footer .chat .chat-body .panel-contacto .contact-form input[type='submit'], .panel-contacto .contact-form footer .chat .chat-body input[type='submit'] {
      width: 100%;
      background: transparent;
      color: white;
      border-radius: 0; }

footer .widget figure img {
  width: auto;
  max-height: 80px; }

footer .newsletter {
  text-align: center;
  padding: 1rem 0; }
  footer .newsletter h4 {
    color: white; }
  footer .newsletter #result_mailchimp {
    width: 100%;
    display: block;
    color: white; }
  footer .newsletter input[type='email'] {
    width: 50%; }
  footer .newsletter button {
    padding: .2rem 1rem;
    background: #156E7C;
    color: white;
    border: none; }

.panel-contacto {
  background: #212331;
  margin: 10px 0;
  border-radius: .6rem;
  padding: 1rem; }
  .panel-contacto .informate p, .panel-contacto .informate h1, .panel-contacto .informate h2 {
    margin-top: 0;
    color: white; }
  .panel-contacto .informate a {
    color: #156E7C; }
  .panel-contacto .contact-form label {
    color: white;
    font-size: .8rem;
    text-transform: uppercase;
    font-weight: 400; }
  .panel-contacto .contact-form .ajax-loader {
    display: none; }
  .panel-contacto .contact-form .wpcf7-validation-errors {
    margin: 0;
    border-radius: .3rem;
    text-align: center; }
  .panel-contacto .contact-form p {
    position: relative;
    display: inline-block; }
    .panel-contacto .contact-form p .wpcf7-form-control-wrap {
      position: relative;
      display: block; }
    .panel-contacto .contact-form p .wpcf7-not-valid-tip {
      position: absolute;
      left: 0;
      width: auto;
      right: 0;
      text-align: right; }
    .panel-contacto .contact-form p:nth-child(5), .panel-contacto .contact-form p:nth-child(6) {
      display: block; }
    .panel-contacto .contact-form p:nth-child(6) {
      margin-bottom: 0; }
  .panel-contacto .contact-form input, .panel-contacto .contact-form textarea {
    padding: 1rem;
    border-radius: .3rem;
    color: #156E7C; }
  .panel-contacto .contact-form input[type='submit'] {
    width: 100%;
    background: #156E7C;
    color: white;
    border: none;
    margin-bottom: .5rem; }

div.post-item {
  width: auto !important;
  background: white !important; }
  div.post-item .excpert {
    height: 100px;
    display: block; }
  div.post-item .event-list-details {
    min-height: 177px; }
  div.post-item .thumb {
    min-height: 233px;
    text-align: center;
    background-color: white;
    border-top: 1px solid #eeeeee;
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee; }
  div.post-item .entry-content {
    margin-top: 0; }

#single-post main.single-post {
  padding: 0.5rem 1rem; }

.data-centro {
  padding: 1rem;
  background: white;
  box-shadow: 0 0 2px rgba(144, 144, 144, 0.33);
  margin: 1rem 0; }
  .data-centro h2, .data-centro h3, .data-centro h4 {
    margin-top: 0;
    margin-bottom: 0; }

form .wpcf7-validation-errors {
  background: #156E7C;
  color: white;
  border: none;
  padding: 1rem; }

#menu-lateral-celi, #menu-profesorado {
  margin-top: 1rem; }
  #menu-lateral-celi li, #menu-profesorado li {
    width: 100%;
    border-bottom: 1px solid lightgrey;
    letter-spacing: 1px;
    color: grey;
    font-size: .9rem !important; }
    #menu-lateral-celi li a, #menu-profesorado li a {
      color: black; }
      #menu-lateral-celi li a:active, #menu-lateral-celi li a:focus, #menu-lateral-celi li a:visited, #menu-profesorado li a:active, #menu-profesorado li a:focus, #menu-profesorado li a:visited {
        color: grey; }
    #menu-lateral-celi li.current-menu-item, #menu-profesorado li.current-menu-item {
      background: #156E7C; }
      #menu-lateral-celi li.current-menu-item a, #menu-profesorado li.current-menu-item a {
        font-weight: 500;
        color: white; }

.solicitar-info form textarea {
  text-indent: 0 !important;
  height: 100px; }

.solicitar-info form input.form-control {
  text-indent: .8rem; }

.solicitar-info form label {
  color: white;
  font-weight: 400; }

.solicitar-info form input[type='submit'] {
  padding: .8rem 2rem;
  text-transform: uppercase;
  float: right;
  background: #156E7C;
  color: white;
  font-weight: 700;
  letter-spacing: 1px;
  border: none;
  transition: all .3s;
  border-radius: 0.4rem; }

.tp-bullets {
  display: none; }

section#home {
  position: relative;
  overflow: hidden; }
  @media (max-width: 860px) {
    section#home {
      height: 200px; } }
  section#home .video-cont {
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
    z-index: -1; }
    section#home .video-cont video {
      transform: translateY(-10%);
      width: 100%;
      height: 100%; }

section#redes ul {
  display: flex;
  justify-content: center;
  text-align: center; }
  @media (max-width: 480px) {
    section#redes ul {
      flex-direction: column; } }
  section#redes ul h2, section#redes ul h3, section#redes ul h4 {
    display: block;
    width: 100%;
    margin: 0 auto;
    text-align: center; }
  section#redes ul li {
    padding: 0 2rem;
    font-size: 7em; }
    @media (max-width: 480px) {
      section#redes ul li {
        padding: 0; } }
    section#redes ul li i {
      color: #156E7C; }

#single-profesor {
  padding-top: 1.9rem;
  padding-bottom: 1.9rem; }
  #single-profesor h1, #single-profesor h2 {
    margin-top: 0;
    margin-bottom: .5rem;
    line-height: 32px; }
  #single-profesor .detalle-prof h3 {
    margin-top: 0; }
  #single-profesor .detalle-prof img {
    width: 100%; }
  #single-profesor .detalle-prof p {
    text-align: justify; }
  #single-profesor .detalle-prof span a {
    margin-right: .5rem; }
    #single-profesor .detalle-prof span a i {
      font-size: 2em; }

#contacto-section {
  padding-bottom: 2rem; }
  #contacto-section form .form-control {
    border: 1px solid #a9a9a9;
    border-radius: .4rem;
    text-indent: 1rem;
    font-size: 1.1rem; }
  #contacto-section form textarea {
    text-indent: 0 !important;
    height: 180px; }
  #contacto-section form input[type="submit"] {
    padding: 1rem 2rem;
    text-transform: uppercase;
    float: right;
    background: #156E7C;
    color: white;
    font-weight: 700;
    letter-spacing: 1px;
    border: none;
    transition: all .3s;
    border-radius: 0.7rem; }
    #contacto-section form input[type="submit"]:hover {
      background: #125f6b; }

.map {
  padding: .5rem;
  margin-top: 1rem;
  background: white;
  box-shadow: 0 0 2px grey; }

.section-testimonios {
  padding-bottom: 2rem; }
  .section-testimonios .item-testimonio {
    border-bottom: 1px solid #156E7C; }
    .section-testimonios .item-testimonio .image {
      height: 180px;
      background-size: cover;
      background-position: center center; }
    .section-testimonios .item-testimonio p {
      color: #3a3a3a;
      text-align: justify;
      padding-right: 1rem; }
    .section-testimonios .item-testimonio h2 {
      color: #156E7C;
      text-transform: uppercase;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  .section-testimonios .panel-testimonio {
    display: none;
    position: fixed;
    padding: 1rem;
    background: white;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 888;
    height: auto;
    max-height: 500px;
    width: 70%; }
    .section-testimonios .panel-testimonio .video {
      width: 100%; }
      .section-testimonios .panel-testimonio .video iframe {
        height: 260px; }
    .section-testimonios .panel-testimonio h1, .section-testimonios .panel-testimonio h2 {
      margin-top: 0;
      text-transform: uppercase;
      padding-top: 0;
      line-height: 3rem; }
    .section-testimonios .panel-testimonio .detail-container {
      overflow: auto;
      height: 235px; }
      .section-testimonios .panel-testimonio .detail-container p {
        height: auto; }
    .section-testimonios .panel-testimonio .image-test {
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 280px; }
      .section-testimonios .panel-testimonio .image-test img {
        max-width: 100%; }
  .section-testimonios .back-test {
    display: none;
    background: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
    z-index: 500;
    position: fixed;
    left: 0;
    top: 0; }

.section-sumate div.container {
  padding: 2em 0;
  display: block; }
  .section-sumate div.container h1, .section-sumate div.container h2, .section-sumate div.container h3 {
    margin-top: 0; }
  .section-sumate div.container p {
    text-align: justify; }
  .section-sumate div.container img {
    max-width: 100%; }
  .section-sumate div.container .list h1 {
    padding-top: 0;
    margin-top: -15px; }
    @media (max-width: 900px) and (min-width: 480px) {
      .section-sumate div.container .list h1 {
        margin-top: 10px; } }
  .section-sumate div.container .list li {
    list-style: none !important; }
    .section-sumate div.container .list li:before {
      display: none; }
    .section-sumate div.container .list li small {
      padding-left: 17px; }

body .entry-content li {
  margin-left: 1rem;
  list-style: disc !important; }
