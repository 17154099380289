#contacto-section{
  padding-bottom: 2rem;
  form{
    .form-control{
      border:1px solid #a9a9a9;
      border-radius: .4rem;
      text-indent: 1rem;
      font-size: 1.1rem;
    }
    textarea{
      text-indent: 0 !important;
      height: 180px;
    }
    input[type="submit"]{
      padding: 1rem 2rem;
      text-transform: uppercase;
      float: right;
      background: $color_celi;
      color:white;
      font-weight: 700;
      letter-spacing:1px;
      border:none;
      transition: all .3s;
      border-radius: 0.7rem;
      &:hover{
        background: darken($color_celi,4);
      }
    }
  }
}


.map{
  padding: .5rem;
  margin-top:1rem;
  background: white;
  box-shadow: 0 0 2px grey;
}