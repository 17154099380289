@mixin breakMobile(){
  @media (max-width:480px){
    @content;
  }
}

@mixin breakTablet(){
  @media (max-width:900px) and (min-width: 480px){
    @content;
  }
}

