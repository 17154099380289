.solicitar-info {
  form {

    textarea {
      text-indent: 0 !important;
      height: 100px;
    }

    input.form-control {
      text-indent: .8rem;
    }
    label {
      color: white;
      font-weight: 400;
    }
    input[type='submit'] {
      padding: .8rem 2rem;
      text-transform: uppercase;
      float: right;
      background: $color_celi;
      color: white;
      font-weight: 700;
      letter-spacing: 1px;
      border: none;
      transition: all .3s;
      border-radius: 0.4rem;
    }
  }
}

.tp-bullets {
  display: none;
}

section#home {
  //height: 750px;
  position: relative;
  overflow: hidden;

  @media (max-width: $break-tablet) {
    height: 200px;
  }

  .video-cont {
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
    z-index: -1;

    video {
      //position: absolute;
      transform: translateY(-10%);
      width: 100%;
      height: 100%;
    }
  }
}

section#redes {
  ul {
    display: flex;
    justify-content: center;
    text-align: center;

    @media (max-width: $break-mobile) {
      flex-direction: column;
    }

    h2, h3, h4 {
      display: block;
      width: 100%;
      margin: 0 auto;
      text-align: center;
    }

    li {
      padding: 0 2rem;
      font-size: 7em;

      @media (max-width: $break-mobile) {
        padding: 0;
      }

      i {
        color: $color_celi;
      }

    }
  }
}