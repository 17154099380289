div.post-item {
  width: auto !important;
  background: white !important;

  .excpert {
    height: 100px;
    display: block;
  }

  h4 {

  }
  .event-list-details{
    min-height: 177px;
  }

  .thumb {
    min-height: 233px;
    text-align: center;
    background-color: white;
    border-top: 1px solid #eeeeee;
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
  }
  .entry-content {
    margin-top: 0;
  }
}