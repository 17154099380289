.line-footer {
  height: 25px;
  width: 100%;
  background: $color_celi;
}

footer {
  .opening-hours{
    li{
      a{
        //text-transform: uppercase;
        font-size: 1rem;
        color:grey;
      }
    }
  }
  .chat {
    position: fixed;
    width: 300px;
    bottom: 0;
    right: 5%;
    background: $color_celi;

    .wpcf7-validation-errors,.wpcf7-response-output{
      padding: 0;
      text-align: center;
      margin: 3px;
      color:white;
    }

    span.wpcf7-not-valid-tip{
      color:white;
      font-size: .9rem;
    }
    p {
      line-height: 1.2;
      padding: 0;
      margin: 3px 0;
    }

    .chat-header {
      background: white;
      padding: 5px;
      border:1px solid $color_celi;
      display: block;
      cursor: pointer;

      img {
        height: 20px;
      }
      span{
        text-transform: uppercase;
        padding-left: 5px;
        color:$color_celi;
      }
    }

    .chat-body {
      padding: 10px;
      display: none;

      input[type='text'], input[type='email'] {
        height: 30px;
        border-radius: 0;
        border: none;
        text-indent: 6px;
      }
      textarea {
        height: 85px;
        border: none;
      }
      .btn {
        width: 100%;
        background: transparent;
        color:white;
        border-radius: 0;
      }
    }
  }

  .widget {
    figure {
      img {
        width: auto;
        max-height: 80px;
      }
    }
  }

  .newsletter {
    text-align: center;
    padding: 1rem 0;
    h4 {
      color: white;
    }
    #result_mailchimp {
      width: 100%;
      display: block;
      color: white;
    }
    input[type='email'] {
      width: 50%;

    }

    button {
      padding: .2rem 1rem;
      //line-height: auto;
      background: $color_celi;
      color: white;
      border: none;
    }

  }
}

.panel-contacto {
  background: $gris-footer;
  margin: 10px 0;
  border-radius: .6rem;
  padding: 1rem;

  .informate {

    p, h1, h2 {
      margin-top: 0;
      color: white;
    }
    a {
      color: $color_celi;
    }
  }
  .contact-form {
    label {
      color: white;
      font-size: .8rem;
      text-transform: uppercase;
      font-weight: 400;
    }

    .ajax-loader {
      display: none;
    }
    .wpcf7-validation-errors {
      margin: 0;
      border-radius: .3rem;
      text-align: center;
    }
    p {
      position: relative;
      display: inline-block;

      .wpcf7-form-control-wrap {
        position: relative;
        display: block;
      }

      .wpcf7-not-valid-tip {
        position: absolute;
        left: 0;
        width: auto;
        //border:1px solid red;
        right: 0;
        text-align: right;
        //display: block;
      }
      &:nth-child(5), &:nth-child(6) {
        display: block;
      }
      &:nth-child(6) {
        margin-bottom: 0;
      }
    }
    input, textarea {
      padding: 1rem;
      border-radius: .3rem;
      color: $color_celi;
    }
    input[type='submit'] {
      width: 100%;
      background: $color_celi;
      color: white;
      border: none;
      margin-bottom: .5rem;
      @extend .btn;
      @extend .btn-primary;
    }
  }
}