/* --------------------------------------
@Author: ThemeMascot
@URL: http://themeforest.net/user/ThemeMascot

Default COLOR :  Color Set 1
COLOR CODE1: $color_gris_oscuro
COLOR CODE2: $color_celi
----------------------------------------- */
/*Theme Colored Red Text-color*/

.text-theme-color-2, .work-gallery .gallery-bottom-part .title {
  color: $color_celi;
}

/*Theme Colored Red Bg-color*/
/* background-theme-color */

.bg-theme-colored {
  background-color: $color_gris_oscuro !important;
}

.bg-theme-colored-lighter2 {
  background-color: #272a3a !important;
}

.bg-theme-colored-lighter3 {
  background-color: #2b2e40 !important;
}

.bg-theme-colored-lighter4 {
  background-color: #2f3246 !important;
}

.bg-theme-colored-darker2 {
  background-color: #1b1c28 !important;
}

.bg-theme-colored-darker3 {
  background-color: #171822 !important;
}

.bg-theme-colored-darker4 {
  background-color: #13141c !important;
}

/* background-color-transparent */

.bg-theme-colored-transparent-9 {
  background-color: rgba(33, 35, 49, 0.9);
}

.bg-theme-colored-transparent-8 {
  background-color: rgba(33, 35, 49, 0.8);
}

.bg-theme-colored-transparent-7 {
  background-color: rgba(33, 35, 49, 0.7);
}

.bg-theme-colored-transparent-6 {
  background-color: rgba(33, 35, 49, 0.6);
}

.bg-theme-colored-transparent-5 {
  background-color: rgba(33, 35, 49, 0.5);
}

.bg-theme-colored-transparent-4 {
  background-color: rgba(33, 35, 49, 0.4);
}

.bg-theme-colored-transparent-3 {
  background-color: rgba(33, 35, 49, 0.3);
}

.bg-theme-colored-transparent-2 {
  background-color: rgba(33, 35, 49, 0.2);
}

.bg-theme-colored-transparent-1 {
  background-color: rgba(33, 35, 49, 0.1);
}

.bg-theme-colored-transparent {
  background-color: rgba(33, 35, 49, 0.75) !important;
}

.bg-theme-color-2 {
  background: $color_celi !important;
}

.team-block .team-thumb .styled-icons a:hover {
  background: $color_celi;
}

.work-gallery {
  .gallery-thumb .styled-icons a:hover, &:hover .gallery-bottom-part {
    background: $color_celi;
  }
}

.double-line-bottom-theme-colored-2 {
  &:after, &:before {
    background: $color_celi;
  }
}

.line-bottom-theme-colored-2:after, .panel-group .panel-title a.active::after, .line-bottom-centered::after, .event-block .event-date, .team-social, .event-small .event-date, .portfolio-filter a.active {
  background: $color_celi;
}

.testimonial-content::after, .top-course-thumb .desc-box .off, .breadcrumb.white li.active, .widget .post-title a:hover {
  color: $color_celi;
}

.about-video:hover .video-button {
  background-color: rgba(255, 153, 0, 0.9);
}

.panel-group .panel-title a.active::after {
  background-color: $color_celi !important;
}

.btn-theme-color-2 {
  background-color: $color_celi;
}

/*Theme Colored Red Bg-transparent-color*/
/*Theme Colored Red Border-top-color*/

.border-theme-color-2-1px {
  border: 1px solid $color_celi !important;
}

.border-top-theme-color-2-1px {
  border-top: 1px solid $color_celi !important;
}

.border-top-theme-color-2-2px {
  border-top: 2px solid $color_celi !important;
}

.border-top-theme-color-2-3px {
  border-top: 3px solid $color_celi !important;
}

.border-top-theme-color-2-4px {
  border-top: 4px solid $color_celi !important;
}

.border-top-theme-color-2-5px {
  border-top: 5px solid $color_celi !important;
}

.border-top-theme-color-2-6px {
  border-top: 6px solid $color_celi !important;
}

.border-top-theme-color-2-7px {
  border-top: 7px solid $color_celi !important;
}

.border-top-theme-color-2-8px {
  border-top: 8px solid $color_celi !important;
}

.border-top-theme-color-2-9px {
  border-top: 9px solid $color_celi !important;
}

.border-top-theme-color-2-10px {
  border-top: 10px solid $color_celi !important;
}

/*Theme Colored Red Border-bottom-color*/

.border-bottom-theme-color-2-1px {
  border-bottom: 1px solid $color_celi !important;
}

.border-bottom-theme-color-2-2px {
  border-bottom: 2px solid $color_celi !important;
}

.border-bottom-theme-color-2-3px {
  border-bottom: 3px solid $color_celi !important;
}

.border-bottom-theme-color-2-4px {
  border-bottom: 4px solid $color_celi !important;
}

.border-bottom-theme-color-2-5px {
  border-bottom: 5px solid $color_celi !important;
}

.border-bottom-theme-color-2-6px {
  border-bottom: 6px solid $color_celi !important;
}

.border-bottom-theme-color-2-7px {
  border-bottom: 7px solid $color_celi !important;
}

.border-bottom-theme-color-2-8px {
  border-bottom: 8px solid $color_celi !important;
}

.border-bottom-theme-color-2-9px {
  border-bottom: 9px solid $color_celi !important;
}

.border-bottom-theme-color-2-10px {
  border-bottom: 10px solid $color_celi !important;
}

/*Theme Colored Red Border-left-color*/

.border-left-theme-color-2-1px {
  border-left: 1px solid $color_celi !important;
}

.border-left-theme-color-2-2px {
  border-left: 2px solid $color_celi !important;
}

.border-left-theme-color-2-3px {
  border-left: 3px solid $color_celi !important;
}

.border-left-theme-color-2-4px {
  border-left: 4px solid $color_celi !important;
}

.border-left-theme-color-2-5px {
  border-left: 5px solid $color_celi !important;
}

.border-left-theme-color-2-6px {
  border-left: 6px solid $color_celi !important;
}

.border-left-theme-color-2-7px {
  border-left: 7px solid $color_celi !important;
}

.border-left-theme-color-2-8px {
  border-left: 8px solid $color_celi !important;
}

.border-left-theme-color-2-9px {
  border-left: 9px solid $color_celi !important;
}

.border-left-theme-color-2-10px {
  border-left: 10px solid $color_celi !important;
}

/*Theme Colored Red Border-right-color*/

.border-right-theme-color-2-1px {
  border-right: 1px solid $color_celi !important;
}

.border-right-theme-color-2-2px {
  border-right: 2px solid $color_celi !important;
}

.border-right-theme-color-2-3px {
  border-right: 3px solid $color_celi !important;
}

.border-right-theme-color-2-4px {
  border-right: 4px solid $color_celi !important;
}

.border-right-theme-color-2-5px {
  border-right: 5px solid $color_celi !important;
}

.border-right-theme-color-2-6px {
  border-right: 6px solid $color_celi !important;
}

.border-right-theme-color-2-7px {
  border-right: 7px solid $color_celi !important;
}

.border-right-theme-color-2-8px {
  border-right: 8px solid $color_celi !important;
}

.border-right-theme-color-2-9px {
  border-right: 9px solid $color_celi !important;
}

.border-right-theme-color-2-10px {
  border-right: 10px solid $color_celi !important;
}

/*Theme Colored 2 styles*/

.review-stars i {
  color: $color_celi;
}

.price-tag {
  background: $color_celi;
}

/*
 * background: theme-color
 * -----------------------------------------------
*/

.tab-slider .nav.nav-pills a {
  &:hover, &.active {
    background: $color_gris_oscuro;
  }
}

.custom-nav-tabs > li > a:hover, .widget .tags a:hover, .progress-item .progress-bar, .small-title .title::after, .testimonial .item::after {
  background: $color_gris_oscuro;
}

.drop-caps {
  &.colored-square p:first-child:first-letter, &.colored-rounded p:first-child:first-letter {
    background: $color_gris_oscuro;
  }
}

.list-icon.theme-colored {
  &.square li i, &.rounded li i {
    background: $color_gris_oscuro;
  }
}

.working-process.theme-colored a, .blog-posts .post .entry-content .post-date.right {
  background: $color_gris_oscuro;
}

.horizontal-tab-centered .nav-pills > li {
  > a:hover {
    background: $color_gris_oscuro;
  }
  &.active > a {
    background: $color_gris_oscuro;
    &:hover, &:focus {
      background: $color_gris_oscuro;
    }
  }
}

.owl-theme.dot-theme-colored .owl-controls .owl-dot span, .pagination.theme-colored li.active a {
  background: $color_gris_oscuro;
}

.section-title {
  .both-side-line {
    &::after, &::before {
      background: $color_gris_oscuro;
    }
  }
  .top-side-line::after, .left-side-line::before, .right-side-line::before {
    background: $color_gris_oscuro;
  }
}

.product .tag-sale, .owl-theme .owl-dots .owl-dot.active span, .line-bottom:after, .line-bottom-center:after, .portfolio-filter a:hover, .gallery-isotope .gallery-item .overlay-shade {
  background: $color_gris_oscuro;
}

.panel-group .panel-title a.active {
  background-color: $color_gris_oscuro !important;
}

.ui-state-highlight {
  background: $color_gris_oscuro !important;
  color: #fff !important;
}

/*
 * text: theme-color
 * -----------------------------------------------
*/

ul.list.theme-colored {
  li:before, &.angle-double-right li:before, &.angle-right li:before, &.check-circle li:before, &.check li:before {
    color: $color_gris_oscuro;
  }
}

.list-icon.theme-colored li i, .pricing-table .table-list li i, .testimonial-carousel.boxed .content::after, .blog-posts .post .entry-meta li i, .widget .address li i, .icon-box.box-style1.practice-style3 i, .attorney-carousel .content .contact-area i, .attorney-address li i, .icon-box.services-style1:hover .heading, .small-title i, .drop-caps.text-colored p:first-child:first-letter, .schedule-box:hover .schedule-details .title a, .widget.dark .nav-tabs li.active a, .pagination.theme-colored li a, .pager.theme-colored a, .volunteer .info .name a, .donation-form .form-group label, .team-block .team-thumb .team-overlay .styled-icons a:hover i, .section-title .title-icon i, .portfolio-filter a, .panel-group .panel-title a, .testimonials-details::after {
  color: $color_gris_oscuro;
}

.menuzord .menuzord-menu {
  > li {
    &.active > a, &:hover > a {
      background: $color_celi none repeat scroll 0 0;
      color: #fff;
    }
  }
  ul.dropdown li:hover {
    > a {
      background: $color_celi none repeat scroll 0 0;
      color: #fff;
    }
    > a i {
      color: #fff;
    }
  }
  > li {
    &.active > a i, &:hover > a i {
      color: #fff;
    }
  }
}

/* text-theme-color */

.text-theme-colored, .text-hover-theme-colored:hover {
  color: $color_gris_oscuro !important;
}

/* text-hover-theme-color */

/* background-theme-color */

.bg-theme-colored {
  background-color: $color_gris_oscuro !important;
}

/* bg-hover-theme-color */

.bg-hover-theme-colored:hover {
  background: $color_gris_oscuro !important;
  border-color: $color_gris_oscuro !important;
  color: #fff !important;
  h1, h2, h3, h4, h5, h6, p, a, i {
    color: #fff !important;
  }
}

.hover-text-theme-color-2:hover {
  color: $color_celi;
}

/* border-theme-color */

.border-theme-colored {
  border-color: $color_gris_oscuro !important;
}

.widget .services-list li.active {
  &::after {
    border-color: transparent $color_gris_oscuro transparent transparent;
  }
  background-color: $color_gris_oscuro !important;
  border-right: 6px solid $color_celi !important;
}

/* background-color-transparent */

.bg-theme-colored-transparent, .piechart-block {
  background-color: rgba(33, 35, 49, 0.75) !important;
}

.service-block .thumb {
  .price, .desc {
    background: rgba(33, 35, 49, 0.7);
  }
}

.team-block .team-thumb {
  .team-overlay, .styled-icons {
    background: rgba(33, 35, 49, 0.7);
  }
}

.work-gallery .gallery-thumb .gallery-overlay {
  background: rgba(33, 35, 49, 0.7);
}

.team-block .team-thumb {
  .team-overlay::after {
    border-color: rgba(33, 35, 49, 0.75) transparent transparent rgba(33, 35, 49, 0.75);
  }
  .styled-icons::after {
    border-color: transparent rgba(33, 35, 49, 0.75) rgba(33, 35, 49, 0.75) transparent;
  }
}

/* Layer-overlay */

.overlay-theme-colored-1:before {
  background-color: rgba(33, 35, 49, 0.1) !important;
}

.overlay-theme-colored-2:before {
  background-color: rgba(33, 35, 49, 0.2) !important;
}

.overlay-theme-colored-3:before {
  background-color: rgba(33, 35, 49, 0.3) !important;
}

.overlay-theme-colored-4:before {
  background-color: rgba(33, 35, 49, 0.4) !important;
}

.overlay-theme-colored-5:before {
  background-color: rgba(33, 35, 49, 0.5) !important;
}

.overlay-theme-colored-6:before {
  background-color: rgba(33, 35, 49, 0.6) !important;
}

.overlay-theme-colored-7:before {
  background-color: rgba(33, 35, 49, 0.7) !important;
}

.overlay-theme-colored-8:before {
  background-color: rgba(33, 35, 49, 0.8) !important;
}

.overlay-theme-colored-9:before, .post .entry-meta.meta-absolute, .volunteer .overlay, .bg-theme-colored-transparent-deep {
  background-color: rgba(33, 35, 49, 0.9) !important;
}

/* Layeroverlay */

.icon-box.box-style1.practice-style3:hover .icon-wrapper, .zeus .tp-bullet::after {
  background-color: $color_gris_oscuro;
}

.preloader-dot-loading .cssload-loading i {
  background: $color_gris_oscuro !important;
}

/*
 * border: theme-color
 * -----------------------------------------------
*/

.icon-box.icon-rounded-bordered i, .widget .line-bottom:after, .pager.theme-colored a {
  border: 1px solid $color_gris_oscuro;
}

.border-theme-colored, .owl-theme .owl-dots .owl-dot span {
  border-color: $color_gris_oscuro;
}

.icon-box.box-style1.practice-style3:hover .icon-wrapper::after {
  border-color: $color_gris_oscuro transparent transparent;
}

.opening-hours ul li {
  border-bottom: 1px dashed $color_gris_oscuro;
}

/* border-left-color */

blockquote {
  &.theme-colored {
    border-left: 3px solid $color_gris_oscuro;
  }
  &.bg-theme-colored {
    border-left: 3px solid #0c0d13;
    color: #fff;
    footer {
      color: #eeeeee;
    }
  }
  &.gray.bg-theme-colored {
    background-color: #eeeeee !important;
    border-left: 3px solid $color_gris_oscuro;
    color: $color_gris_oscuro;
    footer {
      color: #777777;
    }
  }
}

.attorney-address li {
  border-left: 1px solid $color_gris_oscuro;
}

.section-title.title-border {
  border-left: 4px solid $color_gris_oscuro;
}

/* border-top-color */

.member-info {
  border-top: 3px outset $color_gris_oscuro;
}

.cssload-tri {
  border-top: 27px solid $color_gris_oscuro;
}

/* border-right-color */

.border-right-theme-color-2 {
  border-right: 1px solid $color_gris_oscuro;
}

.section-title.title-border.title-right {
  border-right: 4px solid $color_gris_oscuro;
}

/* border-bottom-color */

.event {
  border-bottom-color: $color_gris_oscuro;
}

.cssload-tri.cssload-invert {
  border-bottom: 27px solid $color_gris_oscuro;
}

/* box-shadow */
/*
 * btn-default: theme-color
 * -----------------------------------------------
*/

.btn-default.btn-theme-colored {
  color: $color_gris_oscuro;
  background-color: #fff;
  border-color: $color_gris_oscuro;
  &:focus, &.focus {
    color: $color_gris_oscuro;
    background-color: #e6e6e6;
    border-color: #000000;
  }
  &:hover, &:active, &.active {
    color: $color_gris_oscuro;
    background-color: #e6e6e6;
    border-color: #08090c;
  }
}

.open > .dropdown-toggle.btn-default.btn-theme-colored {
  color: $color_gris_oscuro;
  background-color: #e6e6e6;
  border-color: #08090c;
}

.btn-default.btn-theme-colored {
  &:active:hover, &.active:hover {
    color: $color_gris_oscuro;
    background-color: #d4d4d4;
    border-color: #000000;
  }
}

.open > .dropdown-toggle.btn-default.btn-theme-colored:hover {
  color: $color_gris_oscuro;
  background-color: #d4d4d4;
  border-color: #000000;
}

.btn-default.btn-theme-colored {
  &:active:focus, &.active:focus {
    color: $color_gris_oscuro;
    background-color: #d4d4d4;
    border-color: #000000;
  }
}

.open > .dropdown-toggle.btn-default.btn-theme-colored:focus {
  color: $color_gris_oscuro;
  background-color: #d4d4d4;
  border-color: #000000;
}

.btn-default.btn-theme-colored {
  &:active.focus, &.active.focus {
    color: $color_gris_oscuro;
    background-color: #d4d4d4;
    border-color: #000000;
  }
}

.open > .dropdown-toggle.btn-default.btn-theme-colored.focus {
  color: $color_gris_oscuro;
  background-color: #d4d4d4;
  border-color: #000000;
}

.btn-default.btn-theme-colored {
  &:active, &.active {
    background-image: none;
  }
}

.open > .dropdown-toggle.btn-default.btn-theme-colored {
  background-image: none;
}

.btn-default.btn-theme-colored {
  &.disabled:hover, &[disabled]:hover {
    background-color: #fff;
    border-color: $color_gris_oscuro;
  }
}

fieldset[disabled] .btn-default.btn-theme-colored:hover {
  background-color: #fff;
  border-color: $color_gris_oscuro;
}

.btn-default.btn-theme-colored {
  &.disabled:focus, &[disabled]:focus {
    background-color: #fff;
    border-color: $color_gris_oscuro;
  }
}

fieldset[disabled] .btn-default.btn-theme-colored:focus {
  background-color: #fff;
  border-color: $color_gris_oscuro;
}

.btn-default.btn-theme-colored {
  &.disabled.focus, &[disabled].focus {
    background-color: #fff;
    border-color: $color_gris_oscuro;
  }
}

fieldset[disabled] .btn-default.btn-theme-colored.focus {
  background-color: #fff;
  border-color: $color_gris_oscuro;
}

.btn-default.btn-theme-colored {
  .badge {
    color: #fff;
    background-color: $color_gris_oscuro;
  }
  &:hover, &:active, &:focus {
    background-color: $color_gris_oscuro;
    border-color: $color_gris_oscuro;
    color: #fff;
  }
}

/*
 * btn-border
 * -----------------------------------------------
*/

.btn-border.btn-theme-colored {
  color: $color_gris_oscuro;
  background-color: #fff;
  border-color: $color_gris_oscuro;
  &:focus, &.focus {
    color: $color_gris_oscuro;
    background-color: #e6e6e6;
    border-color: #000000;
  }
  &:hover, &:active, &.active {
    color: $color_gris_oscuro;
    background-color: #e6e6e6;
    border-color: #08090c;
  }
}

.open > .dropdown-toggle.btn-border.btn-theme-colored {
  color: $color_gris_oscuro;
  background-color: #e6e6e6;
  border-color: #08090c;
}

.btn-border.btn-theme-colored {
  &:active:hover, &.active:hover {
    color: $color_gris_oscuro;
    background-color: #d4d4d4;
    border-color: #000000;
  }
}

.open > .dropdown-toggle.btn-border.btn-theme-colored:hover {
  color: $color_gris_oscuro;
  background-color: #d4d4d4;
  border-color: #000000;
}

.btn-border.btn-theme-colored {
  &:active:focus, &.active:focus {
    color: $color_gris_oscuro;
    background-color: #d4d4d4;
    border-color: #000000;
  }
}

.open > .dropdown-toggle.btn-border.btn-theme-colored:focus {
  color: $color_gris_oscuro;
  background-color: #d4d4d4;
  border-color: #000000;
}

.btn-border.btn-theme-colored {
  &:active.focus, &.active.focus {
    color: $color_gris_oscuro;
    background-color: #d4d4d4;
    border-color: #000000;
  }
}

.open > .dropdown-toggle.btn-border.btn-theme-colored.focus {
  color: $color_gris_oscuro;
  background-color: #d4d4d4;
  border-color: #000000;
}

.btn-border.btn-theme-colored {
  &:active, &.active {
    background-image: none;
  }
}

.open > .dropdown-toggle.btn-border.btn-theme-colored {
  background-image: none;
}

.btn-border.btn-theme-colored {
  &.disabled:hover, &[disabled]:hover {
    background-color: #fff;
    border-color: $color_gris_oscuro;
  }
}

fieldset[disabled] .btn-border.btn-theme-colored:hover {
  background-color: #fff;
  border-color: $color_gris_oscuro;
}

.btn-border.btn-theme-colored {
  &.disabled:focus, &[disabled]:focus {
    background-color: #fff;
    border-color: $color_gris_oscuro;
  }
}

fieldset[disabled] .btn-border.btn-theme-colored:focus {
  background-color: #fff;
  border-color: $color_gris_oscuro;
}

.btn-border.btn-theme-colored {
  &.disabled.focus, &[disabled].focus {
    background-color: #fff;
    border-color: $color_gris_oscuro;
  }
}

fieldset[disabled] .btn-border.btn-theme-colored.focus {
  background-color: #fff;
  border-color: $color_gris_oscuro;
}

.btn-border.btn-theme-colored {
  .badge {
    color: #fff;
    background-color: $color_gris_oscuro;
  }
  &:hover, &:active, &:focus {
    background-color: #0c0d13;
    border-color: #0c0d13;
    color: #fff;
  }
}

/*
 * btn-dark
 * -----------------------------------------------
*/

.btn-dark.btn-theme-colored {
  color: #fff;
  background-color: $color_gris_oscuro;
  border-color: $color_gris_oscuro;
  &:focus, &.focus {
    color: #fff;
    background-color: #0c0d13;
    border-color: #000000;
  }
  &:hover, &:active, &.active {
    color: #fff;
    background-color: #0c0d13;
    border-color: #08090c;
  }
}

.open > .dropdown-toggle.btn-dark.btn-theme-colored {
  color: #fff;
  background-color: #0c0d13;
  border-color: #08090c;
}

.btn-dark.btn-theme-colored {
  &:active:hover, &.active:hover {
    color: #fff;
    background-color: #000000;
    border-color: #000000;
  }
}

.open > .dropdown-toggle.btn-dark.btn-theme-colored:hover {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}

.btn-dark.btn-theme-colored {
  &:active:focus, &.active:focus {
    color: #fff;
    background-color: #000000;
    border-color: #000000;
  }
}

.open > .dropdown-toggle.btn-dark.btn-theme-colored:focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}

.btn-dark.btn-theme-colored {
  &:active.focus, &.active.focus {
    color: #fff;
    background-color: #000000;
    border-color: #000000;
  }
}

.open > .dropdown-toggle.btn-dark.btn-theme-colored.focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}

.btn-dark.btn-theme-colored {
  &:active, &.active {
    background-image: none;
  }
}

.open > .dropdown-toggle.btn-dark.btn-theme-colored {
  background-image: none;
}

.btn-dark.btn-theme-colored {
  &.disabled:hover, &[disabled]:hover {
    background-color: $color_gris_oscuro;
    border-color: $color_gris_oscuro;
  }
}

fieldset[disabled] .btn-dark.btn-theme-colored:hover {
  background-color: $color_gris_oscuro;
  border-color: $color_gris_oscuro;
}

.btn-dark.btn-theme-colored {
  &.disabled:focus, &[disabled]:focus {
    background-color: $color_gris_oscuro;
    border-color: $color_gris_oscuro;
  }
}

fieldset[disabled] .btn-dark.btn-theme-colored:focus {
  background-color: $color_gris_oscuro;
  border-color: $color_gris_oscuro;
}

.btn-dark.btn-theme-colored {
  &.disabled.focus, &[disabled].focus {
    background-color: $color_gris_oscuro;
    border-color: $color_gris_oscuro;
  }
}

fieldset[disabled] .btn-dark.btn-theme-colored.focus {
  background-color: $color_gris_oscuro;
  border-color: $color_gris_oscuro;
}

.btn-dark.btn-theme-colored .badge {
  color: $color_gris_oscuro;
  background-color: #fff;
}

/*
 * btn-gray
 * -----------------------------------------------
*/

.btn-gray.btn-theme-colored {
  color: #fff;
  background-color: #36394f;
  border-color: #36394f;
  &:focus, &.focus {
    color: #fff;
    background-color: $color_gris_oscuro;
    border-color: #020203;
  }
  &:hover, &:active, &.active {
    color: #fff;
    background-color: $color_gris_oscuro;
    border-color: #1d1f2b;
  }
}

.open > .dropdown-toggle.btn-gray.btn-theme-colored {
  color: #fff;
  background-color: $color_gris_oscuro;
  border-color: #1d1f2b;
}

.btn-gray.btn-theme-colored {
  &:active:hover, &.active:hover {
    color: #fff;
    background-color: #13141c;
    border-color: #020203;
  }
}

.open > .dropdown-toggle.btn-gray.btn-theme-colored:hover {
  color: #fff;
  background-color: #13141c;
  border-color: #020203;
}

.btn-gray.btn-theme-colored {
  &:active:focus, &.active:focus {
    color: #fff;
    background-color: #13141c;
    border-color: #020203;
  }
}

.open > .dropdown-toggle.btn-gray.btn-theme-colored:focus {
  color: #fff;
  background-color: #13141c;
  border-color: #020203;
}

.btn-gray.btn-theme-colored {
  &:active.focus, &.active.focus {
    color: #fff;
    background-color: #13141c;
    border-color: #020203;
  }
}

.open > .dropdown-toggle.btn-gray.btn-theme-colored.focus {
  color: #fff;
  background-color: #13141c;
  border-color: #020203;
}

.btn-gray.btn-theme-colored {
  &:active, &.active {
    background-image: none;
  }
}

.open > .dropdown-toggle.btn-gray.btn-theme-colored {
  background-image: none;
}

.btn-gray.btn-theme-colored {
  &.disabled:hover, &[disabled]:hover {
    background-color: #36394f;
    border-color: #36394f;
  }
}

fieldset[disabled] .btn-gray.btn-theme-colored:hover {
  background-color: #36394f;
  border-color: #36394f;
}

.btn-gray.btn-theme-colored {
  &.disabled:focus, &[disabled]:focus {
    background-color: #36394f;
    border-color: #36394f;
  }
}

fieldset[disabled] .btn-gray.btn-theme-colored:focus {
  background-color: #36394f;
  border-color: #36394f;
}

.btn-gray.btn-theme-colored {
  &.disabled.focus, &[disabled].focus {
    background-color: #36394f;
    border-color: #36394f;
  }
}

fieldset[disabled] .btn-gray.btn-theme-colored.focus {
  background-color: #36394f;
  border-color: #36394f;
}

.btn-gray.btn-theme-colored .badge {
  color: #36394f;
  background-color: #fff;
}

/*
 * btn-primary: theme-color
 * -----------------------------------------------
*/

.btn-theme-colored {
  color: #fff;
  background-color: $color_gris_oscuro;
  border-color: $color_gris_oscuro;
  &:focus, &.focus {
    color: #fff;
    background-color: #0c0d13;
    border-color: #000000;
  }
  &:hover, &:active, &.active {
    color: #fff;
    background-color: #0c0d13;
    border-color: #08090c;
  }
}

.open > .dropdown-toggle.btn-theme-colored {
  color: #fff;
  background-color: #0c0d13;
  border-color: #08090c;
}

.btn-theme-colored {
  &:active:hover, &.active:hover {
    color: #fff;
    background-color: #000000;
    border-color: #000000;
  }
}

.open > .dropdown-toggle.btn-theme-colored:hover {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}

.btn-theme-colored {
  &:active:focus, &.active:focus {
    color: #fff;
    background-color: #000000;
    border-color: #000000;
  }
}

.open > .dropdown-toggle.btn-theme-colored:focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}

.btn-theme-colored {
  &:active.focus, &.active.focus {
    color: #fff;
    background-color: #000000;
    border-color: #000000;
  }
}

.open > .dropdown-toggle.btn-theme-colored.focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}

.btn-theme-colored {
  &:active, &.active {
    background-image: none;
  }
}

.open > .dropdown-toggle.btn-theme-colored {
  background-image: none;
}

.btn-theme-colored {
  &.disabled:hover, &[disabled]:hover {
    background-color: $color_gris_oscuro;
    border-color: $color_gris_oscuro;
  }
}

fieldset[disabled] .btn-theme-colored:hover {
  background-color: $color_gris_oscuro;
  border-color: $color_gris_oscuro;
}

.btn-theme-colored {
  &.disabled:focus, &[disabled]:focus {
    background-color: $color_gris_oscuro;
    border-color: $color_gris_oscuro;
  }
}

fieldset[disabled] .btn-theme-colored:focus {
  background-color: $color_gris_oscuro;
  border-color: $color_gris_oscuro;
}

.btn-theme-colored {
  &.disabled.focus, &[disabled].focus {
    background-color: $color_gris_oscuro;
    border-color: $color_gris_oscuro;
  }
}

fieldset[disabled] .btn-theme-colored.focus {
  background-color: $color_gris_oscuro;
  border-color: $color_gris_oscuro;
}

.btn-theme-colored .badge {
  color: $color_gris_oscuro;
  background-color: #fff;
}

/*
 * btn-transparent
 * -----------------------------------------------
*/

.btn-transparent {
  background-color: transparent;
  color: #fff;
  &.btn-theme-colored {
    background-color: transparent;
  }
  &.btn-dark {
    background-color: #111111;
    border-color: #111111;
    &:hover {
      background-color: #000000;
      border-color: #000000;
    }
    &.btn-theme-colored {
      background-color: $color_gris_oscuro;
      border-color: $color_gris_oscuro;
      &:hover {
        background-color: #0c0d13;
        border-color: #0c0d13;
      }
    }
  }
  &.btn-border {
    background-color: transparent;
    border-color: #eeeeee;
    &:hover {
      background-color: #eeeeee;
      color: #000;
    }
    &.btn-theme-colored {
      background-color: transparent;
      border-color: $color_gris_oscuro;
      &:hover {
        background-color: $color_gris_oscuro;
        color: #fff;
      }
    }
  }
}

.btn-gray.btn-transparent {
  background-color: #d3d3d3;
  color: #333333;
  &:hover {
    background-color: #ececec;
    color: #1a1a1a;
  }
  &.btn-theme-colored {
    background-color: #36394f;
    color: #fff;
    &:hover {
      background-color: #0c0d13;
      color: #e6e6e6;
    }
  }
}

.btn-hover-theme-colored:hover {
  background-color: $color_gris_oscuro;
  border-color: $color_gris_oscuro;
  color: #fff;
}

/*
 * Shortcode: styled-icons Theme Colored
 * -----------------------------------------------
*/

.icon-theme-colored {
  a {
    color: $color_gris_oscuro;
  }
  &.icon-bordered a {
    border-color: $color_gris_oscuro;
    &:hover {
      background-color: $color_gris_oscuro;
      color: #fff;
    }
  }
  &.icon-dark {
    a {
      background-color: $color_gris_oscuro;
      color: #fff;
      &:hover {
        background-color: #0c0d13;
        color: #e6e6e6;
      }
    }
    &.icon-bordered a {
      border-color: $color_gris_oscuro;
      color: $color_gris_oscuro;
      &:hover {
        background-color: $color_gris_oscuro;
        border-color: $color_gris_oscuro;
        color: #fff;
      }
    }
  }
  &.icon-gray {
    a {
      color: $color_gris_oscuro;
      &:hover {
        color: #0c0d13;
      }
    }
    &.icon-bordered a {
      color: $color_gris_oscuro;
      &:hover {
        background-color: #eeeeee;
        border-color: #eeeeee;
        color: $color_gris_oscuro;
      }
    }
  }
}

/*
 * Shortcode: styled-icons Theme Color 2
 * -----------------------------------------------
*/

.icon-theme-color-2 {
  a {
    color: $color_celi;
  }
  &.icon-bordered a {
    border-color: $color_celi;
    &:hover {
      background-color: $color_celi;
      color: #fff;
    }
  }
  &.icon-dark {
    a {
      background-color: $color_celi;
      color: #fff;
      &:hover {
        background-color: darken($color_celi,5);
        color: #e6e6e6;
      }
    }
    &.icon-bordered a {
      border-color: $color_celi;
      color: $color_celi;
      &:hover {
        background-color: $color_celi;
        border-color: $color_celi;
        color: #fff;
      }
    }
  }
}

.icon-theme-colored a:hover {
  color: #0c0d13;
}

.icon-hover-theme-colored a:hover, .styled-icons.icon-hover-theme-colored a:hover {
  background-color: $color_gris_oscuro;
  border-color: $color_gris_oscuro;
  color: #fff;
}

/*
 * Shortcode: Icon Boxes Theme Colored
 * -----------------------------------------------
*/

.icon-box.iconbox-theme-colored {
  .icon {
    color: $color_gris_oscuro;
    &.icon-border-effect::after {
      box-shadow: 0 0 0 3px $color_gris_oscuro;
    }
    &.icon-bordered {
      border-color: $color_gris_oscuro;
      &:hover {
        background-color: $color_gris_oscuro;
        color: #fff;
      }
    }
    &.icon-gray {
      &:hover {
        background-color: $color_gris_oscuro;
        color: #fff;
      }
      &.icon-bordered {
        border-color: #eeeeee;
        &:hover {
          background-color: $color_gris_oscuro;
          border-color: $color_gris_oscuro;
          color: #fff;
        }
      }
    }
    &.icon-dark {
      background-color: $color_gris_oscuro;
      color: #fff;
      &:hover {
        color: #fff;
      }
      &.icon-bordered {
        background-color: transparent;
        border-color: $color_gris_oscuro;
        color: $color_gris_oscuro;
        &:hover {
          background-color: $color_gris_oscuro;
          border-color: $color_gris_oscuro;
          color: #fff;
        }
      }
    }
    &.icon-white {
      background-color: #fff;
      color: $color_gris_oscuro;
      &:hover {
        background-color: $color_gris_oscuro;
        color: #fff;
      }
      &.icon-bordered {
        background-color: transparent;
        border-color: $color_gris_oscuro;
        color: #fff;
        &:hover {
          background-color: $color_gris_oscuro;
          border-color: $color_gris_oscuro;
          color: #fff;
        }
      }
    }
  }
  &.iconbox-border {
    border-color: $color_gris_oscuro;
  }
}

/*# sourceMappingURL=theme-skin-color-set-1.css.map */