.slider-l {
  h3{
    display: block;
    margin: 0 auto;
    text-align: center;
    text-transform: uppercase;
  }
  .slider-logos {
    padding: 3rem 0 4rem 0;
    a {
      img {
        transition: all .3s;
        display: block;
        margin: 0 auto;
      }

      &:hover {
        img {
          transform: scale(1.05);
        }
      }
    }

    button {
      &:before {
        color: $color_celi;
      }
    }
  }
}
