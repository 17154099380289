#single-post {
  main.single-post {
    padding: 0.5rem 1rem;
  }
}

.data-centro{
  padding: 1rem;
  background: white;
  box-shadow: 0 0 2px rgba(144, 144, 144, 0.33);
  margin: 1rem 0;
  h2,h3,h4{
    margin-top: 0;
    margin-bottom: 0;
  }
}