.custom-logo-link {
  margin-top: .2rem;
  margin-left: 0;
  img {
    width: auto;
    max-height: 70px;
  }
}

.styled-icons,.list-inline{
  margin-bottom: 0;
  font-size: 12px;
}

.header-nav-wrapper{

  #menu-item-296{
    display: none;
  }

  @include breakMobile{
    padding: 1rem 0;
    box-shadow: 0 0 2px 1px grey;
  }
  .menuzord-brand{
    @media (max-width: 480px){
      display: none;
    }
  }

  ul.menu-header-site {
    //@extend .menuzord-menu;

    li{
      position: relative;
    }


    .current-menu-item {
      a {
        background: $color_celi;
        color: whitesmoke;
      }
      .sub-menu {
        a {
          background: transparent;
        }
      }
    }

    li.menu-item-has-children {
      &:hover {
        ul.sub-menu {
          display: block;
          min-width: 180px;
          .sub-menu{
            width: 130%;
            margin-left: -5px !important;
            //background:red;
          }
        }
      }

      //Submenu
      ul.sub-menu {
        display: none;
        position: absolute;
        z-index: 200;
        left: auto;
        width: 100%;
        margin-left: 0;
        background: white;
        margin-top: .3rem;
        padding: 1rem;
        box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);

        .current-menu-item {
          a {
            color: $color_celi !important;
            background: transparent;
          }
        }

        li {
          a {
            //border-bottom: 1px solid grey;
            width: 100%;
            display: block;
            color: black;
            position: relative;

            &:before {
              transition: all .3s;
              content: '';
              height: 1px;
              width: 0;
              position: absolute;
              left: 0;
              bottom: 0;
              background: lightgrey;
            }

            &:hover {
              &:before {
                content: '';
                height: 1px;
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
                background: lightgrey;
              }
            }
          }
        }

        //Sub sub menu
        li.menu-item-has-children {

          &:hover {
            .sub-menu {
              display: block;
            }
          }

          ul.sub-menu {
            display: none;
            position: absolute;
            left: 100%;
            top: -8% !important;
            margin-top: 0;
            margin-left: -8px !important;
          }
        }
      }
    }

  }

}


