@charset "utf-8";

/*------------------------------------------------------------------
[Master Stylesheet]

Project:        Learnpro
Version:        1.0
Created Date:   09.28.2017
Primary use:    Learnpro - Education University School Kindergarten Learning HTML Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/
/*
This file contains the common styles of this theme.
*/
/*------------------------------------------------------------------
[table of contents]

		* typography.less
		* common.less
		* extra.less
		* overlay.less
		* header.less
		* nav.less
		* topbar.less
		* inner-header-title.less
		* vertical-nav.less
		* menu-full-page.less
		* boxed-layout.less
		* form.less
		* side-push-panel.less
		* box-hover-effect.less
		* gallery-isotope.less
		* slider.less
		* home.less
		* about.less
		* causes.less
		* volunteer.less
		* contact.less
		* event.less
		* practice-area.less
		* services.less
		* job.less
		* shop.less
		* blog.less
		* shortcodes.less
			* alerts.less
			* blockquote.less
			* buttons.less
			* clients.less
			* countdown-Timer.less
			* countdown.less
			* dividers.less
			* facebook-disqus-comments.less
			* faqs.less
			* flex-slider.less
			* flip-box.less
			* font-icons.less
			* funfacts.less
			* infinitescroll.less
			* instagram.less
			* lightbox.less
			* map.less
			* pegination.less
			* pricing.less
			* process-steps.less
			* progress-bar.less
			* promo.less
			* section-title.less
			* separators.less
			* styled-icons.less
			* tabs.less
			* teams.less
			* testimonials.less
			* toggles-accordions.less
			* vertical-timeline.less
			* working-process.less

	* Footer
* ===============================================
*/

@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700|Roboto:100,300,400,500,700,900');

/*
 * Typography.less
 * -----------------------------------------------
*/

::selection {
  background: #333333;
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  background: #333333;
  /* Firefox */
  color: #fff;
  text-shadow: none;
}

::-webkit-selection {
  background: #333333;
  /* Safari */
  color: #fff;
  text-shadow: none;
}

:active, :focus {
  outline: none !important;
}

html {
  font-size: 100%;
}

body {
  line-height: 1.7;
  color: #666666;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
}

a {
  color: #808080;
  text-decoration: none;
  &:hover, &:focus {
    color: #595959;
    text-decoration: none;
  }
  img {
    border: none;
  }
}

img {
  max-width: 100%;
}

iframe {
  border: none !important;
}

/* -------- Headings ---------- */

h1, .h1 {
  font-size: 3.4rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 24px;
}

h4, .h4 {
  font-size: 18px;
}

h5, .h5 {
  font-size: 14px;
}

h6, .h6 {
  font-size: 12px;
}

h1, h2, .h1, .h2 {
  font-family: 'Roboto Slab', serif;
  font-weight: 600;
  line-height: 1.42857143;
  color: #333333;
}

h1 small, h2 small, .h1 small, .h2 small, h1 .small, h2 .small, .h1 .small, .h2 .small {
  font-weight: normal;
  line-height: 1;
  color: #333333;
}

h1 a, h2 a, .h1 a, .h2 a {
  color: #333333;
}

h3, h4, h5, h6, .h3, .h4, .h5, .h6 {
  font-family: 'Roboto Slab', serif;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333333;
}

h3 small, h4 small, h5 small, h6 small, .h3 small, .h4 small, .h5 small, .h6 small, h3 .small, h4 .small, h5 .small, h6 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #333333;
}

h3 a, h4 a, h5 a, h6 a, .h3 a, .h4 a, .h5 a, .h6 a {
  color: #333333;
}

/* -------- Body Text ---------- */

p, pre, ul, ol, dl, dd, blockquote, address, table, fieldset, form {
  margin-bottom: 10px;
}

/* -------- Custom Font Family ---------- */

.font-roboto {
  font-family: 'Roboto', sans-serif;
}

.font-roboto-slab {
  font-family: 'Roboto Slab', serif;
}

/* breadcrumb */

.breadcrumb {
  background-color: transparent;
  padding-left: 0;
  > li + li::before {
    content: "\f04b";
    font-family: fontawesome;
    font-size: 9px;
    margin-right: 5px;
  }
  li.active {
    font-weight: 600;
  }
  &.white li {
    &.active, a {
      color: #fff;
    }
  }
}

@media only screen and (min-width: 768px) {
  .breadcrumb.text-right {
    text-align: right;
  }
}

/*Drop Caps*/

.drop-caps {
  p {
    text-align: justify;
    &:first-child:first-letter {
      color: #111111;
      display: block;
      float: left;
      font-size: 48px;
      line-height: 48px;
      margin: 6px 3px;
      padding: 10px 18px;
    }
  }
  &.text-colored p:first-child:first-letter {
    display: block;
    float: left;
    font-size: 48px;
    line-height: 48px;
    margin: 6px 3px;
    padding: 10px 18px;
  }
  &.border p:first-child:first-letter {
    border: 1px solid;
    margin: 8px 16px 0 0;
    padding: 15px 20px;
  }
  &.border-rounded p:first-child:first-letter {
    border: 1px solid;
    border-radius: 50%;
    margin: 8px 16px 0 0;
    padding: 15px 20px;
  }
  &.colored-square p:first-child:first-letter {
    color: #fff;
    margin: 8px 16px 0 0;
    padding: 15px 20px;
  }
  &.colored-rounded p:first-child:first-letter {
    color: #fff;
    margin: 8px 16px 0 0;
    padding: 15px 20px;
    border-radius: 50%;
  }
  &.dark-square p:first-child:first-letter {
    background: #222222;
    color: #fff;
    margin: 8px 16px 0 0;
    padding: 15px 20px;
  }
  &.dark-rounded p:first-child:first-letter {
    background: #222222;
    color: #fff;
    margin: 8px 16px 0 0;
    padding: 15px 20px;
    border-radius: 50%;
  }
}

/*Text Highlight*/

.text-highlight {
  background: none repeat scroll 0 0 #111111;
  border-radius: 4px;
  color: #fff;
  padding: 0 5px 3px;
  &.light {
    background: #808080;
  }
}

/*
 * common.less
 * -----------------------------------------------
*/
/* -------- Basic Structure ---------- */

html {
  font-size: 100%;
  max-width: 100%;
  -webkit-font-smoothing: antialiased;
  a {
    -webkit-font-smoothing: antialiased;
  }
}

@media only screen and (max-width: 991px) {
  html {
    font-size: 90%;
  }
}

@media only screen and (max-width: 767px) {
  html {
    font-size: 85%;
  }
}

@media only screen and (max-width: 479px) {
  html {
    font-size: 80%;
  }
}

body {
  max-width: 100%;
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.fluid-video-wrapper {
  padding: 0;
  position: relative;
  width: 100%;
}

iframe {
  border: none;
  width: 100%;
}

.lead {
  font-size: 18px;
}

.text-highlight {
  background: none repeat scroll 0 0 #111111;
  border-radius: 4px;
  color: #fff;
  padding: 0 5px 3px;
  &.light {
    background: #808080;
  }
}

.inline-block {
  display: inline-block;
}

.display-block {
  display: block;
}

.fullwidth {
  width: 100%;
}

.bg-hover-theme-colored {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/* Text-decoration */

.text-underline {
  text-decoration: underline;
}

/* -------- Line-Bottom ---------- */

.heading-line-bottom {
  border-bottom: 1px dashed #808080;
  display: inline-block;
  margin: 0;
  position: relative;
  margin: 20px 0;
  &::after {
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    top: 4px;
    width: 20px;
  }
}

.heading-title {
  margin-top: 10px;
}

.line-bottom {
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 10px;
  &:after {
    bottom: -1px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 50px;
  }
}

.line-bottom-theme-colored-2 {
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 10px;
  &:after {
    border-radius: 10px;
    bottom: -1px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 50px;
  }
}

.double-line-bottom-theme-colored-2 {
  margin-bottom: 20px;
  margin-top: 8px;
  padding-bottom: 5px;
  position: relative;
  &:after {
    border-radius: 8px;
    bottom: 1px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 64px;
  }
  &:before {
    border-radius: 8px;
    bottom: -1px;
    content: "";
    height: 6px;
    left: 10px;
    position: absolute;
    width: 24px;
  }
}

.line-bottom-centered {
  position: relative;
  margin: 10px 0 20px;
  &:after {
    bottom: -13px;
    content: "";
    height: 3px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    position: absolute;
    right: 0;
    width: 40px;
  }
}

.title-icon {
  [class^="flaticon-"] {
    &::after, &::before {
      font-size: 48px;
      margin-left: 10px;
    }
  }
  margin: 0 auto;
  max-width: 160px;
  position: relative;
  &::after {
    content: "";
    height: 2px;
    left: 24px;
    position: absolute;
    top: 10px;
    width: 30px;
  }
  &::before {
    content: "";
    height: 2px;
    position: absolute;
    right: 20px;
    top: 10px;
    width: 30px;
  }
}

/* -------- List ---------- */

ul, ol {

  //list-style: none;
  margin: 0;
  padding: 0;
}

ul ul, ol ul {
  margin-left: 15px;
}

ul {
  &.list {
    list-style: outside none none;
    margin-top: 13px;
    padding-left: 0;
    li {
      margin: 5px 0;
      font-weight: 500;
      position: relative;
      &:before {
        border: none;
        color: #a9a9a9;
        content: "\f111";
        display: inline-block;
        font-family: fontawesome;
        font-size: 9px;
        margin-right: 10px;
        padding: 0;
        left: 0;
        position: absolute;
        top: 1px;
      }
      &:hover:before {
        background: none;
        border-color: transparent;
      }
      a {
        text-decoration: none;
        -webkit-transition: all 300ms ease-in;
        -o-transition: all 300ms ease-in;
        transition: all 300ms ease-in;
      }
    }
    &.list-white li {
      &:before {
        color: #fff;
      }
      &:hover:before {
        background: none;
        border-color: transparent;
      }
      a {
        color: #fff;
        text-decoration: none;
        -webkit-transition: all 300ms ease-in;
        -o-transition: all 300ms ease-in;
        transition: all 300ms ease-in;
      }
    }
  }
  &.list-border-bottom li {
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 10px;
  }
  &.list.list-border-bottom li:last-child {
    border-bottom: none;
  }
  &.list-border-bottom.no-padding li {
    padding-bottom: 0px;
    &:last-child {
      border-bottom: none;
    }
  }
}

/* -------- List White ---------- */

/* -------- List border bottom ---------- */

/* -------- List Icons ---------- */

.list-icon {
  li {
    font-size: 14px;
    margin: 0;
    i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
    }
  }
  &.square li i {
    background: #c0c0c0;
    color: #555555;
    font-size: 12px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
  }
  &.rounded li i {
    color: #555555;
    background: #c0c0c0;
    border-radius: 50%;
    font-size: 12px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
  }
  &.theme-colored {
    &.square li i, &.rounded li i {
      color: #fff;
    }
  }
}

/* -------- Personal Info List ---------- */

.list-info li {
  clear: both;
  position: relative;
  label {
    display: inline-block;
    font-weight: bold;
    padding: 5px 0 0;
  }
  span {
    left: 110px;
    padding: 5px 0;
    position: absolute;
  }
}

.list-features {
  -moz-column-count: 3;
  column-count: 3;
}

.list-dashed {
  > {
    article, div, li {
      border-bottom: 1px dashed #d3d3d3;
      margin-bottom: 7px;
      padding-bottom: 7px;
    }
  }
  &.dark-dash > {
    article, div, li {
      border-bottom: 1px dashed #333333;
    }
  }
}

ul.list {
  &.check li:before {
    content: "\f00c";
    font-family: fontawesome;
    font-size: 12px;
  }
  &.angle-double-right li:before {
    content: "\f101";
    font-family: fontawesome;
    font-size: 12px;
  }
  &.angle-right li:before {
    content: "\f105";
    font-family: fontawesome;
  }
  &.check-circle li:before {
    content: "\f058";
    font-family: fontawesome;
    font-size: 14px;
  }
}

/* -------- Call To Actions ---------- */

.call-to-action {
  display: inline-block;
  width: 100%;
  padding-bottom: 20px;
  padding-top: 20px;
}

/* -------- Section & Divider ---------- */

section {
  position: relative;
  > {
    .container, .container-fluid {
      padding-top: 70px;
      padding-bottom: 70px;
    }
  }
}

/* -------- Background Video ---------- */

.bg-video {
  height: 100%;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: -1;
  .video {
    height: 100%;
    left: 0;
    margin: 0;
    position: relative;
    top: 0;
    width: 100%;
    .mbYTP_wrapper {
      z-index: -1;
    }
  }
}

/* -------- Box Table ---------- */

.display-table-parent {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.display-table {
  display: table;
  height: 100%;
  position: relative;
  width: 100%;
}

.display-table-cell {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}

/* -------- Scroll To Top ---------- */

.scrollToTop {
  background: rgba(0, 0, 0, 0.1);
  bottom: 15px;
  display: none;
  height: 50px;
  padding: 2px;
  position: fixed;
  right: 15px;
  text-align: center;
  text-decoration: none;
  width: 50px;
  z-index: 99999;
  i {
    color: #fff;
    font-size: 42px;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.6);
    text-decoration: none;
  }
}

/* -------- magnific popup ---------- */

.mfp-close-btn-in .mfp-close {
  color: #eee;
  font-size: 64px;
}

.read-more {
  position: relative;
  &::after {
    content: "\f054";
    font-family: fontawesome;
    font-size: 11px;
    margin-left: 5px;
    vertical-align: middle;
  }
  &:hover::after {
    margin-left: 8px;
    -webkit-transition: all 200ms ease-in-out 0s;
    -o-transition: all 200ms ease-in-out 0s;
    transition: all 200ms ease-in-out 0s;
  }
}

/*
 * extra.less
 * -----------------------------------------------
*/
/* -------- Heading Style ---------- */

.heading-border {
  border-bottom: 1px solid #d3d3d3;
  margin: 30px 0;
  padding: 20px 0;
}

/* -------- FullCalendar - Plugin ---------- */

.fc-day-number {
  color: #333333;
  &.fc-other-month {
    opacity: 0.4;
  }
}

/* scrolltofixed-container */

.scrolltofixed-container {
  position: static;
}

@media only screen and (max-width: 767px) {
  .scrolltofixed-container .scrolltofixed {
    position: static !important;
    + div {
      display: none !important;
    }
  }
}

/* slider revolution */

.tp-bannertimer {
  z-index: 21;
}

.mfp-close-btn-in .mfp-close {
  color: #fff;
  font-size: 48px;
}

/*
 * overlays.less
 * -----------------------------------------------
*/

.layer-overlay {
  position: relative;
  /* overlay-dark */
  /* overlay-white */
  &::before {
    background: rgba(17, 17, 17, 0.5) none repeat scroll 0 0;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
  &.overlay-light::before {
    background-color: rgba(255, 255, 255, 0.4);
  }
  &.overlay-lighter::before {
    background-color: rgba(255, 255, 255, 0.3);
  }
  &.overlay-deep::before {
    background-color: rgba(255, 255, 255, 0.9);
  }
  &.overlay-dark::before {
    background-color: rgba(17, 17, 17, 0.85);
  }
  &.overlay-dark-1::before {
    background-color: rgba(17, 17, 17, 0.1);
  }
  &.overlay-dark-2::before {
    background-color: rgba(17, 17, 17, 0.2);
  }
  &.overlay-dark-3::before {
    background-color: rgba(17, 17, 17, 0.3);
  }
  &.overlay-dark-4::before {
    background-color: rgba(17, 17, 17, 0.4);
  }
  &.overlay-dark-5::before {
    background-color: rgba(17, 17, 17, 0.5);
  }
  &.overlay-dark-6::before {
    background-color: rgba(17, 17, 17, 0.6);
  }
  &.overlay-dark-7::before {
    background-color: rgba(17, 17, 17, 0.7);
  }
  &.overlay-dark-8::before {
    background-color: rgba(17, 17, 17, 0.8);
  }
  &.overlay-dark-9::before {
    background-color: rgba(17, 17, 17, 0.9);
  }
  &.overlay-white::before {
    background-color: rgba(255, 255, 255, 0.4);
  }
  &.overlay-white-1::before {
    background-color: rgba(255, 255, 255, 0.1);
  }
  &.overlay-white-2::before {
    background-color: rgba(255, 255, 255, 0.2);
  }
  &.overlay-white-3::before {
    background-color: rgba(255, 255, 255, 0.3);
  }
  &.overlay-white-4::before {
    background-color: rgba(255, 255, 255, 0.4);
  }
  &.overlay-white-5::before {
    background-color: rgba(255, 255, 255, 0.5);
  }
  &.overlay-white-6::before {
    background-color: rgba(255, 255, 255, 0.6);
  }
  &.overlay-white-7::before {
    background-color: rgba(255, 255, 255, 0.7);
  }
  &.overlay-white-8::before {
    background-color: rgba(255, 255, 255, 0.8);
  }
  &.overlay-white-9::before {
    background-color: rgba(255, 255, 255, 0.9);
  }
  &.layer-pattern::before {
    background-image: url(../images/pattern1.png);
    background-color: rgba(0, 0, 0, 0.6);
  }
  &.layer-pattern2::before {
    background-image: url(../images/pattern2.png);
    background-color: rgba(0, 0, 0, 0.8);
  }
  &.layer-pattern3::before {
    background-image: url(../images/pattern3.png);
    background-color: rgba(0, 0, 0, 0.8);
  }
  &.maximage-layer-overlay::before {
    z-index: 1;
  }
  &.overlay-blue::before {
    background-color: rgba(1, 162, 208, 0.9);
  }
  &.overlay-blue-light::before {
    background-color: rgba(1, 162, 208, 0.45);
  }
  &.overlay-pink::before {
    background-color: rgba(235, 110, 142, 0.9);
  }
  &.overlay-pink-light::before {
    background-color: rgba(235, 110, 142, 0.45);
  }
  &.overlay-brown::before {
    background-color: rgba(60, 171, 111, 0.9);
  }
  &.overlay-brown-light::before {
    background-color: rgba(60, 171, 111, 0.45);
  }
  &.overlay-yellow::before {
    background-color: rgba(255, 187, 34, 0.9);
  }
  &.overlay-yellow-light::before {
    background-color: rgba(255, 187, 34, 0.45);
  }
  &.overlay-green::before {
    background-color: rgba(16, 196, 92, 0.9);
  }
  &.overlay-green-light::before {
    background-color: rgba(16, 196, 92, 0.45);
  }
  &.overlay-theme-color-1::before {
    background-color: rgba(173, 216, 230, 0.9);
  }
  &.overlay-theme-color-2::before {
    background-color: rgba(230, 173, 188, 0.9);
  }
  &.overlay-theme-color-3::before {
    background-color: rgba(230, 216, 173, 0.9);
  }
}

.half-divider {
  .overlay-theme-color-1, .overlay-theme-color-2, .overlay-theme-color-3 {
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
  }
}

/*
 * header.less
 * -----------------------------------------------
*/

.header-top {
  padding: 5px 0;
  .header-widget {
    .social-icons a i:hover {
      background: none;
    }
    .list-border li {
      border-right: 1px solid rgba(0, 0, 0, 0.06);
      padding: 5px 15px;
      &:last-child {
        border-right: 0;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .header-top .header-widget .contact-info {
    margin-bottom: 20px;
    li {
      display: block;
      text-align: center;
    }
  }
}

.menuzord-brand img {
  max-height: 36px;
}

/* -------- Inner Header ---------- */

.inner-header .title {
  text-transform: uppercase;
}

/* -------- Custom theme style ---------- */

.header-nav {
  .header-nav-wrapper .menuzord {
    padding: 0;
  }
  &.navbar-transparent.animated-active {
    background-color: rgba(0, 0, 0, 0.25);
  }
}

/*
 * nav.less
 * -----------------------------------------------
*/
/* -------- nav sidebar ---------- */

.nav-sidebar li {
  background: #f1f1f1;
  padding-bottom: 0;
  a {
    border-radius: 0;
  }
}

.nav-pills li {
  a:hover {
    background: #d3d3d3;
    color: #777777;
  }
  &.active > a {
    background: #d3d3d3;
    color: #777777;
    &:hover, &:focus {
      background: #d3d3d3;
      color: #777777;
    }
  }
}

/* -------- Header Nav ---------- */

.header-nav {
  position: relative;
  .header-nav-absolute-wrapper {
    width: 100%;
  }
  .header-nav-wrapper .menuzord {
    padding: 0;
  }
}

/* -------- Menuzord ---------- */

.menuzord-menu {
  > li {
    padding: 24px 0;
  }
  ul.dropdown {
    top: 83px;
  }
  > li > a {
    font-size: 13px;
    color: #333333;
  }
}

.menuzord .menuzord-menu {
  > li {
    &.active > a i, &:hover > a i {
      color: #fff;
    }
  }
  ul.dropdown li:hover > a i {
    color: #fff;
  }
}

.menuzord-menu {
  ul.dropdown {
    border: 1px solid #eee;
    li {
      margin: 0;
      a {
        color: #666666;
        font-size: 12px;
        font-weight: 600;
        padding: 7px 25px 7px 22px;
      }
      .indicator {
        top: 4px;
      }
    }
  }
  > li > {
    .megamenu .megamenu-row li {
      border: 0 none;
      clear: both;
      font-size: 12px;
      margin: 0;
      padding: 2px;
      position: relative;
      width: 100%;
      a {
        display: block;
        padding: 8px 10px;
        -webkit-transition: padding 0.2s linear;
        -o-transition: padding 0.2s linear;
        transition: padding 0.2s linear;
      }
      &.active > a, &:hover > a {
        color: #111;
        padding-left: 15px;
      }
      a i {
        color: #444;
      }
      &.active a i {
        color: #fff;
      }
    }
    .menuzord-menu > li > .megamenu .megamenu-row li:hover a i {
      color: #fff;
    }
  }
}

/* -------- Megamenu-Row ---------- */

@media only screen and (max-width: 900px) {
  .menuzord-menu {
    background: #fff;
  }
}

/* -------- Scrolltofixed ---------- */

.header-nav {
  .header-nav-wrapper.scroll-to-fixed-fixed.scroll-to-fixed-fixed {
    border-bottom: 1px solid #eee;
  }
  .header-nav-absolute-wrapper.scroll-to-fixed-fixed.scroll-to-fixed-fixed .menuzord {
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;
  }
}

.header .inner-sticky-wrapper {
  height: 80px;
}

@media only screen and (min-width: 901px) {
  .header-nav .header-nav-absolute-wrapper {
    bottom: -36px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    z-index: 1111;
    &.scroll-to-fixed-fixed.scroll-to-fixed-fixed {
      bottom: auto;
    }
  }
}

/* -------- Navbar Fixed  ---------- */

.header-nav {
  -webkit-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  &.navbar-fixed-top {
    left: 0;
    position: fixed;
    right: 0;
    width: 100%;
    z-index: 1030;
  }
}

/* -------- Navbar Sticky Animated ---------- */

.navbar-sticky-animated .header-nav-wrapper .container {
  -webkit-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

/* -------- Header White ---------- */

.header-nav {
  &.navbar-white {
    background-color: rgba(255, 255, 255, 0.95);
    border: medium none;
    .menuzord {
      background: transparent;
    }
  }
  &.navbar-transparent {
    .menuzord {
      background: transparent;
    }
    .header-nav-wrapper {
      border-bottom: 1px solid #eee;
    }
    &.animated-active {
      background-color: rgba(0, 0, 0, 0.1);
      .header-nav-wrapper {
        border-bottom: none;
      }
      .menuzord-menu > li > a {
        color: #fff;
      }
    }
  }
}

/* -------- Header Transparent ---------- */

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1199px) {
  .menuzord-brand {
    margin: 18px 15px 0 0;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 991px) {
  .menuzord .showhide {
    padding: 26px 0 0;
  }
}

@media only screen and (max-width: 900px) {
  .header-nav.navbar-transparent.animated-active .menuzord-menu > li > a {
    color: #888;
  }
}

/*
 * inner-header-title.less
 * -----------------------------------------------
*/

.inner-header .title {
  font-size: 36px;
  text-transform: uppercase;
}

/*
 *  boxed-layout.less
 * -----------------------------------------------
*/

.boxed-layout {
  .container {
    max-width: 1100px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .header, .main-content, .footer {
    margin: 0 auto;
    max-width: 1100px;
  }
  .header, section, .footer {
    background-color: #fff;
  }
  .header {
    margin-bottom: 15px;
    .navbar-default {
      background-color: #fff;
      border-color: #fff;
      padding: 0;
      .navbar-nav > li > a {
        padding: 30px 5px;
      }
      .navbar-collapse {
        background-color: #fff;
        border-color: #fff;
        margin-right: 30px;
      }
    }
    #header-logo {
      margin: 0;
      padding-top: 27px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .boxed-layout {
    padding-top: 0;
    .header .navbar-default {
      .navbar-nav > li > a {
        padding: 10px;
      }
      .navbar-collapse {
        margin-right: 15px;
      }
    }
    .navbar-header {
      padding: 15px 0;
    }
    .navbar-collapse .navbar-nav li a .caret {
      margin-right: 0;
    }
  }
}

/*
 * form.less
 * -----------------------------------------------
*/
/* -------- Form ---------- */

.input-group-lg > {
  .form-control, .input-group-addon, .input-group-btn > .btn {
    font-size: 14px;
  }
}

.form-control {
  border-radius: 0;
  box-shadow: none;
  height: 45px;
  border: 1px solid #eeeeee;
}

label {
  font-weight: normal;
}

.form-control:focus {
  color: #404040;
  border-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  outline: none;
}

form {
  input {
    &[type="text"]:focus, &[type="email"]:focus, &[type="number"]:focus, &[type="url"]:focus, &[type="search"]:focus, &[type="tel"]:focus, &[type="password"]:focus, &[type="date"]:focus, &[type="color"]:focus {
      color: #404040;
      border-color: rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
      outline: none;
    }
  }
  select:focus, textarea:focus {
    color: #404040;
    border-color: rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    outline: none;
  }
}

.form-transparent {
  .form-control {
    background: transparent;
    color: #f7f7f7;
    &:focus {
      color: #f7f7f7;
    }
  }
  form {
    input {
      &[type="text"]:focus, &[type="email"]:focus, &[type="number"]:focus, &[type="url"]:focus, &[type="search"]:focus, &[type="tel"]:focus, &[type="password"]:focus, &[type="date"]:focus, &[type="color"]:focus {
        color: #f7f7f7;
      }
    }
    select:focus, textarea:focus {
      color: #f7f7f7;
    }
  }
  textarea.form-control {
    height: 150px;
  }
}

.form-white {
  textarea.form-control {
    height: 150px;
  }
  .form-control {
    background: #fff;
  }
}

.form-transparent.form-line {
  .form-control {
    border: none;
    border-bottom: 1px solid #c0c0c0;
    padding-left: 0;
  }
  textarea.form-control {
    height: 70px;
  }
}

form {
  label {
    font-weight: bold;
  }
  .form-control.error {
    border: 2px solid #ee163b;
  }
  .error {
    color: #ee163b;
  }
}

/* form error */

/* -------- Donation Form ---------- */

.donation-form .form-group label.radio-inline {
  color: #808080;
}

/*-------- form-text-white ---------*/

.form-text-white .form-group label {
  color: #fff;
}

/*
 *  side-push-panel.less
 * -----------------------------------------------
*/

.side-push-panel {
  overflow-x: hidden;
  &.fullwidth-page {
    #wrapper {
      right: 0;
      position: relative;
    }
    header .header-nav-wrapper.scroll-to-fixed-fixed .container {
      right: 0;
    }
    &.side-panel-left {
      #wrapper, header .header-nav-wrapper.scroll-to-fixed-fixed .container {
        left: 0;
        right: auto;
      }
    }
    &.side-panel-open {
      #wrapper, header .header-nav-wrapper.scroll-to-fixed-fixed .container {
        right: 300px;
      }
    }
    &.side-panel-left.side-panel-open {
      #wrapper, header .header-nav-wrapper.scroll-to-fixed-fixed .container {
        left: 300px;
        right: auto;
      }
    }
  }
  &.has-side-panel .menuzord .showhide {
    float: left;
  }
}

/* -------- Has Side Panel ---------- */

/* -------- Body Overlay ---------- */

.has-side-panel {
  .body-overlay {
    -webkit-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
  }
  &.side-panel-open .body-overlay {
    opacity: 1;
    pointer-events: auto;
    z-index: 1111;
  }
}

.side-panel-open:not(.device-xxs):not(.device-xs):not(.device-sm) .body-overlay:hover {
  cursor: url('../images/close.png') 15 15, default;
}

/* -------- Side Panel ---------- */

#side-panel {
  -webkit-backface-visibility: hidden;
  background-color: #f3f3f3;
  height: 100%;
  overflow: hidden;
  position: fixed;
  right: -300px;
  top: 0;
  width: 300px;
  z-index: 1112;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  .side-panel-wrap {
    bottom: 0;
    height: 100%;
    left: 0;
    overflow: auto;
    padding: 50px 70px 50px 40px;
    position: absolute;
    top: 0;
    width: 330px;
  }
  .widget {
    border-top: 1px solid #eeeeee;
    margin-top: 40px;
    padding-top: 40px;
    width: 220px;
    &:first-child {
      border-top: 0;
      margin: 0;
      padding-top: 0;
    }
  }
}

.side-panel-open #side-panel {
  right: 0;
}

.side-panel-left {
  #side-panel {
    left: -300px;
    right: auto;
  }
  &.side-panel-open #side-panel {
    left: 0;
    right: auto;
  }
}

.device-touch #side-panel .side-panel-wrap {
  overflow-y: scroll;
}

/* -------- Side-Panel-Nav ---------- */

#side-panel .side-panel-nav .nav {
  li a {
    padding: 5px 0;
    &:hover {
      background-color: transparent;
      color: #a9a9a9;
    }
  }
  ul.tree {
    display: none;
    margin-left: 15px;
  }
}

/* -------- Side-Panel-Trigger And Close ---------- */

#side-panel-trigger {
  cursor: pointer;
  display: block;
  float: right;
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 25px;
  z-index: 11;
}

@media only screen and (max-width: 767px) {
  #side-panel-trigger {
    margin-top: 15px;
  }
}

#side-panel-trigger-close {
  left: 0;
  margin: 10px;
  position: absolute;
  top: 0;
}

/* -------- Transition ---------- */

.side-push-panel.fullwidth-page {
  #wrapper, header .header-nav-wrapper.scroll-to-fixed-fixed .container {
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
}

/*
 *  box-hover-effect.less
 * -----------------------------------------------
*/

.box-hover-effect {
  display: inline-block;
  vertical-align: middle;
  .effect-wrapper {
    position: relative;
    overflow: hidden;
    margin: 0;
    width: 100%;
    /* Icons Holder */
    /* Icons Holder middle */
    /* Icons Holder top left */
    /* Icons Holder top right */
    /* Icons Holder bottom left */
    /* Icons Holder bottom right */
    /* Text Holder */
    /* Text Holder middle */
    /* Text Holder top */
    /* Text Holder bottom */
    .thumb {
      position: relative;
      overflow: hidden;
      backface-visibility: hidden;
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
      -webkit-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease;
      &.gradient-effect:after {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(360deg, rgba(0, 0, 0, 0.6) 10px, transparent);
      }
    }
    .hover-link {
      bottom: 0;
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      background: none !important;
      color: transparent !important;
      z-index: 11;
    }
    .overlay-shade {
      bottom: 0;
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10;
      background-color: #000;
      opacity: 0;
      filter: alpha(opacity = 0);
      -webkit-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      &.shade-white {
        background-color: #fff;
      }
    }
    .icons-holder {
      display: inline-block;
      opacity: 0;
      position: absolute;
      z-index: 13;
      -webkit-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease;
      .icons-holder-inner {
        position: relative;
        margin: 0;
        a {
          -webkit-transition: all 0.4s ease;
          -o-transition: all 0.4s ease;
          transition: all 0.4s ease;
        }
      }
    }
    .icons-holder-middle {
      left: 0;
      margin: 0 auto;
      right: 0;
      text-align: center;
      top: 40%;
      vertical-align: middle;
      width: 80%;
      .icons-holder-inner {
        display: inline-block;
        vertical-align: middle;
        a {
          -webkit-transition: all 0.4s ease;
          -o-transition: all 0.4s ease;
          transition: all 0.4s ease;
        }
      }
    }
    .icons-holder-top-left {
      top: -20%;
      left: 0;
      padding: 15px;
      .icons-holder-inner {
        display: block;
      }
    }
    .icons-holder-top-right {
      top: -20%;
      right: 0;
      padding: 15px;
      .icons-holder-inner {
        display: block;
      }
    }
    .icons-holder-bottom-left {
      bottom: -20%;
      left: 0;
      padding: 5px 15px;
      .icons-holder-inner {
        display: block;
      }
    }
    .icons-holder-bottom-right {
      bottom: -20%;
      right: 0;
      padding: 5px 15px;
      .icons-holder-inner {
        display: block;
      }
    }
    .text-holder {
      display: inline-block;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      -webkit-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      z-index: 13;
      .title {
        color: #fff;
      }
      .text_holder_inner {
        display: table;
        height: 100%;
        position: relative;
        width: 100%;
      }
      .text_holder_inner2 {
        display: table-cell;
        height: 100%;
        position: relative;
        text-align: center;
        vertical-align: middle;
        width: 100%;
      }
    }
    .overlay-shade.shade-white + .text-holder .title {
      color: #333;
    }
    .text-holder-middle {
      display: block;
      left: 0;
      margin: 0 auto;
      right: 0;
      text-align: center;
      top: 30%;
      vertical-align: middle;
      width: 80%;
    }
    .text-holder-top {
      display: block;
      left: 0;
      margin: 0 auto;
      right: 0;
      text-align: center;
      top: -40px;
      vertical-align: middle;
      width: 80%;
    }
    .text-holder-bottom {
      display: block;
      left: 0;
      margin: 0 auto;
      right: 0;
      text-align: center;
      bottom: -40px;
      vertical-align: middle;
      width: 80%;
    }
    .text-holder-top-left {
      left: 0;
      padding: 5px 15px;
      top: -40px;
      width: auto;
    }
    .text-holder-top-right {
      right: 0;
      padding: 5px 15px;
      top: -40px;
      width: auto;
    }
    .text-holder-bottom-left {
      left: 0;
      padding: 5px 15px;
      bottom: -40px;
      width: auto;
    }
    .text-holder-bottom-right {
      right: 0;
      padding: 5px 15px;
      bottom: -40px;
      width: auto;
    }
  }
  &:hover .effect-wrapper {
    .thumb {
      -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
      -o-transform: scale(1.05);
      transform: scale(1.05);
    }
    .overlay-shade {
      opacity: 0.8;
      filter: alpha(opacity = 80);
    }
    .icons-holder {
      opacity: 1;
    }
    .icons-holder-middle {
      top: 45%;
    }
    .icons-holder-top-left, .icons-holder-top-right {
      top: 0;
    }
    .icons-holder-bottom-left, .icons-holder-bottom-right {
      bottom: 2%;
    }
    .text-holder {
      opacity: 1;
    }
    .text-holder-middle {
      top: 40%;
    }
    .text-holder-top {
      top: 20px;
    }
    .text-holder-bottom {
      bottom: 20px;
    }
    .text-holder-top-left, .text-holder-top-right {
      top: 0;
    }
    .text-holder-bottom-left, .text-holder-bottom-right {
      bottom: 0;
    }
  }
}

.shadow-overlay {
  background-image: url("../images/shadow-overlay.png");
  background-position: center bottom;
  background-repeat: repeat-x;
  bottom: 0;
  color: #fff;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
}

/* Individual effects */
/* Individual effect = image-zoom-in */

.box-hover-effect {
  &.image-zoom-in {
    .thumb {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
    &:hover .thumb {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -o-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
  &.image-zoom-out {
    .thumb {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -o-transform: scale(1.1);
      transform: scale(1.1);
    }
    &:hover .thumb {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  &.play-button {
    /* Text Holder */
    .thumb {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
    &:hover .thumb {
      -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
      -o-transform: scale(1.05);
      transform: scale(1.05);
    }
    .text-holder {
      opacity: 1;
    }
    .text-holder-middle {
      top: 34%;
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
    &:hover .text-holder-middle {
      top: 34%;
      -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);
    }
    .video-button {
      z-index: 11;
      position: absolute;
      top: 50%;
      left: 50%;
      background: rgba(255, 255, 255, 0.8);
      width: 60px;
      height: 60px;
      border-radius: 100%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
      -webkit-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #666;
        -webkit-transform: translate(-15%, -50%);
        transform: translate(-15%, -50%);
        -webkit-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease;
      }
    }
    &:hover .video-button {
      background: rgba(255, 51, 51, 0.9);
      width: 80px;
      height: 80px;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.3);
      &:before {
        border-left: 10px solid #fff;
      }
    }
  }
  &.effect-alaska {
    .bottom-info-box {
      background: rgba(0, 0, 0, 0.9) none repeat scroll 0 0;
      bottom: 0;
      left: 0;
      padding: 15px 30px 0;
      position: absolute;
      right: 0;
      top: 75%;
      -webkit-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      .invisible-parts {
        display: none;
      }
    }
    &:hover .bottom-info-box {
      padding-top: 38px;
      top: 0;
      .invisible-parts {
        display: block;
      }
    }
  }
  &.effect-siberia {
    .info-box {
      .info-title {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 15px 70px 15px 15px;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        &:before {
          color: #fff;
          content: "\f054";
          font-family: "fontAwesome";
          position: absolute;
          text-align: center;
          line-height: 50px;
          bottom: 5px;
          top: 7px;
          right: 7px;
          width: 50px;
          height: 50px;
          background-color: rgba(9, 9, 9, 0.3);
          text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
          font-size: 18px;
        }
      }
      .info-content {
        color: #ffffff;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        padding: 30px;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
        position: absolute;
        visibility: hidden;
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transform: translateX(-100%);
        -webkit-opacity: 0;
        -khtml-opacity: 0;
        -moz-opacity: 0;
        opacity: 0;
        -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
        filter: alpha(opacity = 0);
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }
    }
    &:hover .info-box {
      .info-title {
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
      }
      .info-content {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        visibility: visible;
        -webkit-opacity: 1;
        -khtml-opacity: 1;
        -moz-opacity: 1;
        opacity: 1;
        -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
        filter: alpha(opacity = 100);
      }
    }
  }
  &.effect-florida {
    .info-box {
      .info-title {
        position: absolute;
        left: 15px;
        right: 15px;
        bottom: 15px;
        padding: 15px 70px 15px 15px;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        &:before {
          color: #fff;
          content: "\f054";
          font-family: "fontAwesome";
          position: absolute;
          text-align: center;
          line-height: 50px;
          bottom: 5px;
          top: 7px;
          right: 7px;
          width: 50px;
          height: 50px;
          background-color: rgba(9, 9, 9, 0.3);
          text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
          font-size: 18px;
        }
      }
      .info-content {
        color: #ffffff;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        padding: 30px;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
        position: absolute;
        visibility: hidden;
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        transform: translateY(-100%);
        -webkit-opacity: 0;
        -khtml-opacity: 0;
        -moz-opacity: 0;
        opacity: 0;
        -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
        filter: alpha(opacity = 0);
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }
    }
    &:hover .info-box {
      .info-title {
        -webkit-transform: translateY(100%);
        -moz-transform: translateY(100%);
        -ms-transform: translateY(100%);
        -o-transform: translateY(100%);
        transform: translateY(100%);
      }
      .info-content {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        visibility: visible;
        -webkit-opacity: 1;
        -khtml-opacity: 1;
        -moz-opacity: 1;
        opacity: 1;
        -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
        filter: alpha(opacity = 100);
      }
    }
  }
  &.effect-london {
    .text-holder {
      top: -30px;
      -webkit-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
    }
    &:hover .text-holder {
      top: 0;
    }
  }
  &.effect-rome:hover .text-holder {
    top: auto;
    bottom: 0;
  }
  &.effect-paris {
    &:hover .thumb {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
      -webkit-transform: translate(0, -70px);
      -ms-transform: translate(0, -70px);
      -o-transform: translate(0, -70px);
      transform: translate(0, -70px);
    }
    .text-holder {
      background: #333;
      padding: 5px 15px 5px;
      top: auto;
      bottom: -30px;
      width: 100%;
      -webkit-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
    }
    &:hover .text-holder {
      top: auto;
      bottom: 0;
    }
  }
  &.effect-barlin {
    &:hover .thumb {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
    .text-holder-middle {
      bottom: 0;
      padding: 50px;
      top: 0;
      .text_holder_inner .text_holder_inner2 {
        .title1 {
          opacity: 0;
          -webkit-transform: translate(0, 10px);
          -ms-transform: translate(0, 10px);
          -o-transform: translate(0, 10px);
          transform: translate(0, 10px);
          -webkit-transition: all 0.4s ease 0.2s;
          -o-transition: all 0.4s ease 0.2s;
          transition: all 0.4s ease 0.2s;
        }
        .title2 {
          opacity: 0;
          -webkit-transform: translate(0, 10px);
          -ms-transform: translate(0, 10px);
          -o-transform: translate(0, 10px);
          transform: translate(0, 10px);
          -webkit-transition: all 0.4s ease 0.3s;
          -o-transition: all 0.4s ease 0.3s;
          transition: all 0.4s ease 0.3s;
        }
      }
    }
    &:hover .text-holder-middle {
      top: 0;
      .text_holder_inner .text_holder_inner2 {
        .title1, .title2 {
          opacity: 1;
          -webkit-transform: translate(0, 0);
          -ms-transform: translate(0, 0);
          -o-transform: translate(0, 0);
          transform: translate(0, 0);
        }
      }
    }
    .hover-link {
      z-index: 13;
    }
  }
}

/* Individual effect = image-zoom-out */

/* Individual effect = play button */

/* Individual effect = alaska */

/* Individual effect = siberia */

/* Individual effect = florida */

/* Individual effect = london */

/* Individual effect = rome */

/* Individual effect = paris */

/* Individual effect = barlin */

/*
 * gallery-isotope.less
 * -----------------------------------------------
*/
/* -------- Portfolio Isotop Filter ---------- */

.portfolio-filter {
  margin-bottom: 30px;
  a {
    border-left: 3px solid transparent;
    background-color: #f8f8f8;
    display: inline-block;
    float: none;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin-right: 5px;
    outline: medium none;
    padding: 5px 15px;
    -webkit-transition: all 100ms ease-in-out 0s;
    -o-transition: all 100ms ease-in-out 0s;
    transition: all 100ms ease-in-out 0s;
    &.active, &:hover {
      color: #fff;
    }
  }
}

@media only screen and (max-width: 479px) {
  .portfolio-filter a {
    margin-bottom: 10px;
  }
}

/* -------- Portfolio Isotop Gallery ---------- */

.gallery-isotope {
  /*gutter*/
  /* by default 4 grids */
  /*portfolio 2 grids*/
  /*portfolio 2 grids*/
  /*portfolio 3 grids*/
  /*portfolio 4 grids*/
  /*portfolio 5 grids*/
  /*portfolio 6 grids*/
  /*portfolio 7 grids*/
  /*portfolio 8 grids*/
  /*portfolio 9 grids*/
  /*portfolio 10 grids*/
  &.gutter .gallery-item {
    padding-right: 5px;
    padding-bottom: 5px;
  }
  &.gutter-small .gallery-item {
    padding-right: 2px;
    padding-bottom: 2px;
  }
  &.gutter-30 .gallery-item {
    padding-right: 30px;
    padding-bottom: 30px;
  }
  &.gutter-40 .gallery-item {
    padding-right: 40px;
    padding-bottom: 40px;
  }
  &.gutter-50 .gallery-item {
    padding-right: 50px;
    padding-bottom: 50px;
  }
  &.gutter-60 .gallery-item {
    padding-right: 60px;
    padding-bottom: 60px;
  }
  .gallery-item {
    width: 25%;
    &.wide {
      width: 50%;
    }
  }
  &.grid-1 .gallery-item {
    width: 100%;
    &.wide {
      width: 100%;
    }
  }
  &.grid-2 .gallery-item {
    width: 50%;
    &.wide {
      width: 100%;
    }
  }
  &.grid-3 .gallery-item {
    width: 33.333%;
    &.wide {
      width: 66.6667%;
    }
  }
  &.grid-4 .gallery-item {
    width: 25%;
    &.wide {
      width: 50%;
    }
  }
  &.grid-5 .gallery-item {
    width: 20%;
    &.wide {
      width: 40%;
    }
  }
  &.grid-6 .gallery-item {
    width: 16.666666667%;
    &.wide {
      width: 33.333%;
    }
  }
  &.grid-7 .gallery-item {
    width: 14.2857143%;
    &.wide {
      width: 28.5714286%;
    }
  }
  &.grid-8 .gallery-item {
    width: 12.5%;
    &.wide {
      width: 25%;
    }
  }
  &.grid-9 .gallery-item {
    width: 11.11%;
    &.wide {
      width: 22.22%;
    }
  }
  &.grid-10 .gallery-item {
    width: 10%;
    &.wide {
      width: 20%;
    }
  }
  .gallery-item {
    overflow: hidden;
    .thumb {
      position: relative;
      overflow: hidden;
    }
    .hover-link {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      height: 100%;
      background: none !important;
      color: transparent !important;
      z-index: 11;
    }
    .overlay-shade {
      position: absolute;
      display: inline-block;
      vertical-align: middle;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 12;
      opacity: 0;
      filter: alpha(opacity = 0);
      -webkit-transition: opacity 0.3s ease-in-out;
      -o-transition: opacity 0.3s ease-in-out;
      transition: opacity 0.3s ease-in-out;
    }
    &:hover .overlay-shade {
      opacity: 0.8;
      filter: alpha(opacity = 90);
    }
    .icons-holder {
      position: absolute;
      top: 0%;
      left: 50%;
      display: inline-block;
      width: auto;
      height: 36px;
      vertical-align: middle;
      z-index: 13;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      .icons-holder-inner {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin: -18px 0 0 -50%;
        a {
          opacity: 0;
          filter: alpha(opacity = 0);
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }
      }
    }
    &:hover .icons-holder {
      top: 50%;
      .icons-holder-inner a {
        opacity: 1;
        filter: alpha(opacity = 1);
      }
    }
    .portfolio-description {
      padding: 10px 15px;
      background-color: #f8f8f8;
      .title {
        margin: 0;
        a {
          color: #333333;
        }
      }
    }
    .text-holder {
      box-sizing: border-box;
      display: block;
      height: 100%;
      left: 0;
      opacity: 0;
      overflow: hidden;
      padding: 25px 30px 60px;
      position: absolute;
      top: 0;
      -webkit-transition: opacity 0.3s ease-in-out 0s;
      -o-transition: opacity 0.3s ease-in-out 0s;
      transition: opacity 0.3s ease-in-out 0s;
      width: 100%;
      z-index: 13;
      .title {
        color: #fff;
      }
    }
    &:hover .text-holder {
      opacity: 1;
    }
  }
  .flex-control-nav {
    z-index: 111;
  }
}

/* -------- Portfolio Isotop Item Media Query ---------- */
@media only screen and (max-width: 991px) {
  .gallery-isotope .gallery-item {
    width: 33.333% !important;
    &.wide {
      width: 66.6667% !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .gallery-isotope .gallery-item {
    width: 50% !important;
    &.wide {
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 479px) {
  .gallery-isotope .gallery-item {
    width: 100% !important;
    &.wide {
      width: 100% !important;
    }
  }
}

/* -------- Portfolio Isotop Overlay Effect ---------- */

/*
 * slider.less
 * -----------------------------------------------
*/
/* -------- Rev Slider ---------- */

#home .revslider-fullscreen {
  min-height: auto;
}

/* -------- Home Revolution Slider For Minimal Layouts ---------- */
/* -------- Tab-Slider ---------- */

.tab-slider {
  .tab-content {
    padding: 0;
    border: 0;
  }
  .nav.nav-pills {
    > div {
      background-color: #fff;
      float: left;
      margin-bottom: 15px;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
    i {
      color: #d3d3d3;
    }
    a {
      display: block;
      padding: 40px 30px;
      background: #f3f3f3;
      &:hover {
        i, h6 {
          color: #fff;
        }
      }
      &.active {
        i, h6 {
          color: #fff;
        }
      }
    }
  }
}

/* -------- MaxImage Slider ---------- */

.maximage-slider .fullscreen-controls {
  .img-prev, .img-next {
    background: rgba(0, 0, 0, 0.3);
  }
}

/* -------- Testimonial-Carousel ---------- */

.testimonial-carousel, .testimonial-carousel-style2 {
  /* fullwidth */
}

.testimonial-carousel .quote, .testimonial-carousel-style2 .quote, .testimonial-carousel .thumb, .testimonial-carousel-style2 .thumb {
  margin-bottom: 40px;
}

.testimonial-carousel .quote i, .testimonial-carousel-style2 .quote i {
  background: #555555;
  border-radius: 50%;
  color: #fff;
  font-size: 25px;
  height: 60px;
  line-height: 1;
  padding: 20px 0 0;
  text-align: center;
  width: 60px;
}

.testimonial-carousel .author, .testimonial-carousel-style2 .author {
  margin-top: 30px;
  text-transform: uppercase;
}

.testimonial-carousel.owl-theme .owl-controls, .testimonial-carousel-style2.owl-theme .owl-controls {
  margin-top: 10px;
}

.testimonial-carousel.fullwidth .content p, .testimonial-carousel-style2.fullwidth .content p {
  font-size: 15px;
  font-style: italic;
  color: #fff;
}

.testimonial-carousel.fullwidth .content p::after, .testimonial-carousel-style2.fullwidth .content p::after {
  content: "\f10e";
  font-family: fontawesome;
  font-size: 100px;
  left: 42%;
  opacity: 0.1;
  position: absolute;
}

.testimonial-carousel.bullet-white .owl-dots span, .testimonial-carousel-style2.bullet-white .owl-dots span {
  background: #fff;
}

.testimonial-carousel .owl-item img, .testimonial-carousel-style2 .owl-item img {
  display: inline-block;
  width: auto;
  max-width: 90px;
}

.testimonial-carousel.boxed .quote, .testimonial-carousel-style2.boxed .quote, .testimonial-carousel.boxed .thumb, .testimonial-carousel-style2.boxed .thumb {
  margin-bottom: 20px;
}

.testimonial-carousel.boxed .owl-item img, .testimonial-carousel-style2.boxed .owl-item img {
  display: inline-block;
  width: auto;
  max-width: 65px;
}

.testimonial-carousel.boxed .content::after, .testimonial-carousel-style2.boxed .content::after {
  bottom: 12px;
  content: "\f10e";
  font-family: fontawesome;
  font-size: 55px;
  opacity: 0.2;
  position: absolute;
  right: 10px;
}

.testimonial-carousel.boxed .owl-controls, .testimonial-carousel-style2.boxed .owl-controls {
  margin-top: 0;
}

.testimonial-carousel.boxed.owl-theme .owl-dots .owl-dot.active span, .testimonial-carousel-style2.boxed.owl-theme .owl-dots .owl-dot.active span, .testimonial-carousel.boxed.owl-theme .owl-dots .owl-dot:hover span, .testimonial-carousel-style2.boxed.owl-theme .owl-dots .owl-dot:hover span {
  opacity: 1;
}

/* -------- Widget-Testimonial-Carousel ---------- */

.widget-testimonial-carousel .item img {
  max-width: 75px;
  float: right;
}

/*
 * owl-carousel.less
 * -----------------------------------------------
*/

.owl-carousel {
  /* owl dots */
  .owl-item img {
    display: inline-block;
    width: auto;
  }
  .owl-dots {
    text-align: center;
    margin-top: 10px;
    .owl-dot {
      display: inline-block;
      zoom: 1;
      span {
        background: #D6D6D6;
        border-radius: 50%;
        display: block;
        height: 12px;
        margin: 5px 3px;
        opacity: 0.8;
        -webkit-transition: all 0.4s ease 0s;
        -o-transition: all 0.4s ease 0s;
        transition: all 0.4s ease 0s;
        width: 12px;
      }
      &.active span, &:hover span {
        background: #869791;
      }
    }
  }
  .owl-nav button {
    border-radius: 0;
    display: block;
    margin: -32px auto;
    position: absolute;
    opacity: 0.4;
    top: 50%;
    z-index: 6;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    i {
      color: #555;
      vertical-align: middle;
      font-size: 16px;
    }
    &.owl-prev {
      left: 0;
      background: rgba(240, 240, 240, 0.8);
      padding: 10px;
    }
    &.owl-next {
      right: 0;
      background: rgba(240, 240, 240, 0.8);
      padding: 10px;
    }
  }
  &:hover .owl-nav > button {
    opacity: 1;
  }
}

/* Owl Dots	*/

.owl-dots-bottom-right .owl-dots {
  bottom: -10px;
  position: absolute;
  right: 0;
}

.owl-dots-center-bottom .owl-dots {
  bottom: 15px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
}

/* Image Carousel */

.image-carousel {
  .owl-controls .owl-nav > div {
    opacity: 0;
  }
  &:hover .owl-controls .owl-nav > div {
    opacity: 1;
  }
}

/* Owl top nav style */

.owl-carousel {
  &.owl-nav-top {
    &.bullet-white .owl-dots span {
      background: #fff;
    }
    .owl-controls .owl-nav {
      > div {
        background-color: transparent;
        top: -60px;
        right: 0;
        &:hover {
          background-color: #d3d3d3;
        }
      }
      .owl-prev {
        right: 35px;
      }
      .owl-next {
        right: 0;
      }
      .owl-prev, .owl-next {
        background: #eeeeee;
        border: none;
        line-height: 28px;
        padding: 0 12px;
        text-align: center;
      }
      .owl-prev i, .owl-next i {
        color: #888;
        font-size: 14px;
      }
    }
  }
  &.no-bullet .owl-nav {
    display: none;
  }
}

.recent-project .owl-controls .owl-nav {
  > div {
    top: 38%;
  }
  .owl-prev i, .owl-next i {
    padding: 2px 16px;
  }
}

.featured-gallery.style2 {
  float: right;
  width: 92%;
  .owl-controls {
    bottom: 58px;
    left: -80px;
    margin: 0;
    position: absolute;
    .owl-nav {
      > div {
        top: 95%;
      }
      .owl-prev {
        left: 0px;
      }
      .owl-next {
        display: inline-table;
        left: 40px;
      }
      .owl-prev i, .owl-next i {
        color: #fff;
        font-size: 35px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .featured-gallery.style2 {
    float: none;
    margin-top: 58px;
    width: 100%;
    .owl-controls {
      bottom: auto;
      left: auto;
      top: -45px;
    }
  }
}

.image-carousel {
  .owl-controls .owl-nav > div {
    opacity: 0;
  }
  &:hover .owl-controls .owl-nav > div {
    opacity: 1;
  }
}

/* -------- Owl Fullwidth Carousel ---------- */

.fullwidth-carousel {
  .owl-controls .owl-nav > div {
    top: 45%;
  }
  .carousel-item {
    min-height: 615px;
    width: 100%;
  }
  &.blog-fullwidth-carousel {
    .carousel-item {
      height: 600px;
      min-height: auto;
      width: 100%;
    }
    .owl-controls .owl-nav > div {
      background: none;
    }
  }
}

/* -------- Twitter Feed Carousel ---------- */

.owl-dots-bottom-right .owl-dots {
  bottom: -10px;
  position: absolute;
  right: 0;
}

/* -------- Twitter Feed Carousel ---------- */

.twitter-feed {
  &.twitter-carousel {
    color: #111111;
    font-size: 16px;
    a {
      color: #111111;
    }
  }
  .date {
    font-size: 12px;
  }
  &.twitter-white {
    color: #fff;
    a {
      color: #fff;
    }
  }
}

/* -------- BX Slider ---------- */

.bx-wrapper {
  margin: 0 auto 7px;
  padding: 0;
  position: relative;
  .bx-viewport {
    background: inherit;
    border: 0 solid #fff;
    box-shadow: 0 0 0 #ccc;
    left: 0;
    transform: translateZ(0px);
  }
}

/* bx top nav style */

.bx-controls .bx-controls-direction {
  > a {
    background: #eee none repeat scroll 0 0;
    border: medium none;
    height: auto;
    line-height: 28px;
    padding: 0 12px;
    width: auto;
    text-indent: 0;
    top: -30px;
    z-index: 0;
    &:hover {
      background: #ddd;
    }
    i {
      color: #888;
    }
  }
  .bx-prev {
    right: 35px;
    left: auto;
  }
  .bx-next {
    right: 0;
  }
}

/* -------- Typed Animation Slider ---------- */

.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@-moz-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


/*
 * home.less
 * -----------------------------------------------
*/
/* -------- Setion-Bottom-Absolute-Div ---------- */

.setion-bottom-absolute-div {
  background: rgba(255, 255, 255, 0.5) none repeat scroll 0 0;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
}

/*
 * about.less
 * -----------------------------------------------
*/

.top-course-thumb {
  position: relative;
  &::after {
    background: #000 none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all 200ms ease-in-out 0s;
    -o-transition: all 200ms ease-in-out 0s;
    transition: all 200ms ease-in-out 0s;
    width: 100%;
  }
  &:hover::after {
    opacity: 0.8;
    border: 8px solid rgba(255, 255, 255, 0.15);
  }
  .desc-box {
    left: 0;
    margin: -15px auto;
    position: absolute;
    right: 0;
    height: 100%;
    text-align: center;
    top: 30px;
    .title {
      color: #fff;
      font-size: 22px;
      font-weight: 600;
      left: 0;
      margin: -15px auto;
      position: absolute;
      right: 0;
      text-align: center;
      top: 35%;
      z-index: 999;
    }
    .off {
      font-size: 42px;
      font-weight: 600;
      left: 0;
      margin: -15px auto;
      position: absolute;
      right: 0;
      text-align: center;
      top: 45%;
      z-index: 999;
    }
    .signup {
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      left: 0;
      margin: -15px auto;
      position: absolute;
      right: 0;
      text-align: center;
      text-decoration: underline;
      top: 64%;
      z-index: 999;
    }
  }
}

/* Individual effect = play button */

.about-video {
  /* Text Holder */
  .thumb, &:hover .thumb {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  .text-holder {
    opacity: 1;
  }
  .text-holder-middle {
    top: 34%;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  &:hover .text-holder-middle {
    top: 34%;
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  .video-button {
    z-index: 11;
    position: absolute;
    top: 50%;
    left: 50%;
    background: rgba(255, 255, 255, 0.8);
    width: 60px;
    height: 60px;
    border-radius: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    -webkit-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid #666;
      -webkit-transform: translate(-15%, -50%);
      transform: translate(-15%, -50%);
      -webkit-transition: all 200ms ease;
      -o-transition: all 200ms ease;
      transition: all 200ms ease;
    }
  }
  &:hover .video-button {
    width: 65px;
    height: 65px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.3);
    &:before {
      border-left: 10px solid #fff;
    }
  }
}

/*
 * features.less
 * -----------------------------------------------
*/

.feature-icon {
  display: block;
  margin: 0 auto;
  width: 100%;
  > img {
    width: 65px;
  }
}

.feature-title {
  //padding-left: 90px;
  text-align: center;
  padding-left: 0;
}

.feature-box {
  width: auto;
  &:hover .feature-icon {
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-name: bounce;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 767px) {
  .feature-box {
    width: 100%;
  }
}

/*
 * causes.less
 * -----------------------------------------------
*/

.course-thumb {
  position: relative;
}

.price-tag {
  border-radius: 0;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 0 10px;
  position: absolute;
  right: -7px;
  text-transform: capitalize;
  top: 16px;
  -webkit-transition: all 150ms ease-in-out 0s;
  -o-transition: all 150ms ease-in-out 0s;
  transition: all 150ms ease-in-out 0s;
}

.course-single-item:hover .price-tag {
  padding: 2px 16px;
}

.price-tag::before {
  border-left: 7px solid rgba(0, 0, 0, 0.45);
  border-top: 7px solid transparent;
  content: "";
  height: 0;
  position: absolute;
  right: -1px;
  top: 0;
  transform: translateY(-100%);
  width: 0;
}

.course-meta li {
  border-right: 1px solid #ddd;
  padding: 0 18px;
  &:last-child {
    border-right: medium none;
  }
  h6 {
    color: #444;
    font-size: 14px;
    font-weight: 600;
    margin: 5px 0 0;
  }
  span {
    font-size: 13px;
    margin: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .top-course-thumb .desc-box {
    .title {
      font-size: 18px;
      top: 35%;
    }
    .off {
      font-size: 35px;
      top: 46%;
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 991px) {
  .top-course-thumb {
    max-width: 430px;
    .desc-box {
      .off {
        font-size: 46px;
        top: 46%;
      }
      .title {
        font-size: 24px;
        top: 38%;
      }
      .signup {
        top: 60%;
      }
    }
  }
}

/*
 * volunteer.less
 * -----------------------------------------------
*/

.volunteer {
  position: relative;
  .overlay {
    height: 0;
    left: 0;
    margin: 0 auto;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  .content {
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all 0.2s ease-in-out 0s;
    -o-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
  }
  &:hover {
    .overlay {
      opacity: 1;
      height: 100%;
    }
    .content {
      opacity: 1;
      top: 30%;
    }
  }
  &.border {
    border: 3px solid #fff;
  }
  .info {
    padding: 15px 0;
    padding-bottom: 0;
    text-align: center;
    .name {
      font-weight: 700;
      margin: 0px;
      a {
        text-transform: uppercase;
      }
    }
    .occupation {
      color: #999;
      margin-bottom: 10px;
      margin-top: 0px;
      text-transform: uppercase;
    }
  }
}

/*
 * contact.less
 * -----------------------------------------------
*/

.contact-wrapper {
  padding: 50px 50px 50px 100px;
}

.contact-form {
  .form-group {
    margin-bottom: 20px;
  }
  .form-control {
    background-image: none;
    border: 1px solid #c0c0c0;
    border-radius: 0;
    box-shadow: none;
    height: 48px;
    &:focus, &:active {
      box-shadow: none;
      color: #333333;
    }
  }
  .form-control2 {
    background-color: rgba(0, 0, 0, 0.8);
    color: #c0c0c0;
  }
  .form-group button {
    margin-top: 0;
  }
  textarea.form-control {
    height: 110px;
    resize: none;
  }
}

textarea {
  resize: none;
}

/*
 * event.less
 * -----------------------------------------------
*/

.event-block {
  margin: 10px 0;
  position: relative;
  .event-date {
    padding: 6px 18px;
    position: absolute;
    top: 24px;
    -webkit-transition: all 200ms ease-in-out 0s;
    -o-transition: all 200ms ease-in-out 0s;
    transition: all 200ms ease-in-out 0s;
  }
  .event-meta {
    background: #f7f6f2 none repeat scroll 0 0;
    display: inline-block;
    margin-left: 40px;
    padding: 14px 16px 10px 14px;
    z-index: -1;
  }
  &:hover .event-date {
    top: 16px;
  }
}

.event-small .event-date {
  float: left;
  margin-right: 20px;
  padding: 0 16px 5px 20px;
  text-align: center;
}

/* -------- event Style ---------- */

.event-list {
  .thumb {
    position: relative;
    overflow: hidden;
    img {
      transition: all 500ms ease 0s;
    }
  }
  &:hover .thumb img {
    transform: scale(1.1);
  }
  .thumb .overlay-donate-now {
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: -50px;
    transition: all 400ms ease 0s;
  }
  &:hover .thumb .overlay-donate-now {
    top: 10%;
  }
  .thumb .entry-date {
    bottom: 0;
    position: absolute;
    right: -44px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }
  &:hover .thumb .entry-date {
    right: 0;
  }
}

/*
 * practice-area.less
 * -----------------------------------------------
*/

.practice-area {
  overflow: hidden;
  position: relative;
  &:hover .practice-name {
    opacity: 1;
    right: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
}

/*
 * Services.less
 * -----------------------------------------------
*/

.service-block {
  margin: 0 auto 30px;
  max-width: 400px;
  &:hover .thumb .desc {
    bottom: 0;
  }
  .thumb {
    overflow: hidden;
    position: relative;
    .price {
      left: -1px;
      padding: 5px 10px;
      position: absolute;
      top: -1px;
    }
    .desc {
      bottom: -31%;
      padding: 10px 6px 5px 20px;
      position: absolute;
      -webkit-transition: all 400ms ease-in-out 0s;
      -o-transition: all 400ms ease-in-out 0s;
      transition: all 400ms ease-in-out 0s;
      width: 100%;
      p {
        color: #fff;
        font-size: 13px;
      }
    }
  }
  .content {
    border: 1px solid #eeeeee;
    padding: 12px 20px 20px;
  }
}

/*
 * Work-Gallery.less
 * -----------------------------------------------
*/

.work-gallery {
  margin: 0 auto 30px;
  max-width: 400px;
  .gallery-thumb {
    overflow: hidden;
    position: relative;
  }
  .gallery-bottom-part {
    background-color: #f7f7f7;
    padding: 10px 10px 16px;
  }
  .gallery-thumb .gallery-overlay {
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    -webkit-transform: scale(0.2);
    -ms-transform: scale(0.2);
    -o-transform: scale(0.2);
    transform: scale(0.2);
    -webkit-transition: all 300ms ease-in-out 0s;
    -o-transition: all 300ms ease-in-out 0s;
    transition: all 300ms ease-in-out 0s;
    width: 100%;
  }
  &:hover .gallery-thumb .gallery-overlay {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

.gallery-contect {
  height: 100%;
  opacity: 0;
  padding: 30px;
  position: absolute;
  top: 0;
  -webkit-transform: scale(0.2);
  -ms-transform: scale(0.2);
  -o-transform: scale(0.2);
  transform: scale(0.2);
  -webkit-transition: all 100ms ease-in-out 0s;
  -o-transition: all 100ms ease-in-out 0s;
  transition: all 100ms ease-in-out 0s;
  width: 100%;
}

.work-gallery {
  &:hover .gallery-thumb .gallery-contect {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  .gallery-thumb {
    .styled-icons a {
      border-color: #fff;
      height: 36px;
      width: 36px;
      line-height: 34px;
      i {
        color: #fff;
      }
      &:hover {
        i {
          color: #fff;
        }
        color: #fff;
        -webkit-transition: all 100ms ease-in-out 0s;
        -o-transition: all 100ms ease-in-out 0s;
        transition: all 100ms ease-in-out 0s;
      }
    }
    .gallery-contect .styled-icons {
      left: 0;
      position: absolute;
      right: 0;
      text-align: center;
      top: 45%;
    }
  }
  &:hover .gallery-bottom-part {
    -webkit-transition: all 300ms ease-in-out 0s;
    -o-transition: all 300ms ease-in-out 0s;
    transition: all 300ms ease-in-out 0s;
    .title, .sub-title {
      color: #fff;
    }
  }
}

/*
 * job.less
 * -----------------------------------------------
*/

.job-overview {
  box-shadow: 0 0 0 2px #eee;
  padding: 20px 0 20px 20px;
  .dl-horizontal {
    dt {
      width: auto;
    }
    dd {
      margin-left: 40px;
    }
  }
}

/*
 * shop.less
 * -----------------------------------------------
*/
/* -------- Product Details Page ---------- */

.product {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  position: relative;
}

.products .product {
  border: 1px solid #eee;
  &:hover {
    border: 1px solid #eee;
  }
}

.btn-add-to-cart-wrapper {
  opacity: 0.9;
  height: auto;
  margin: 0 0 15px;
  text-align: center;
  width: 100%;
  background: #ffffff;
  -webkit-transition: 400ms;
  -o-transition: 400ms;
  transition: 400ms;
}

/* -------- Price ---------- */

.product {
  .price {
    color: #333038;
    font-size: 15px;
    font-weight: 500;
    padding: 5px 0 15px;
    del {
      color: #9e9d9e;
      font-size: 13px;
      margin-right: 5px;
    }
    ins {
      color: #333333;
      font-size: 16px;
      text-decoration: none;
      background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
      font-weight: 600;
    }
  }
  .product-summary {
    .product-title {
      margin-top: 0;
    }
    .short-description {
      margin-bottom: 15px;
    }
    .cart-form-wrapper .btn {
      margin-top: 0;
    }
    .variations {
      width: 100%;
    }
  }
  .product-tab {
    margin-top: 30px;
  }
  .commentlist .comment {
    margin-top: 30px;
    position: relative;
    .meta {
      font-size: 12px;
    }
    .author {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .product-thumb {
    position: relative;
    &:hover .overlay {
      opacity: 1;
    }
    .overlay .add-to-wishlist {
      color: #fff;
      right: 10px;
      top: 10px;
      position: absolute;
    }
  }
  .product-summary .cart-form-wrapper .quantity {
    display: inline-block;
    margin-right: .8571em;
    position: relative;
    width: 4.1429em;
    vertical-align: top;
  }
}

/* -------- Product-Summary ---------- */

.tbl-shopping-cart .quantity {
  display: inline-block;
  margin-right: .8571em;
  position: relative;
  width: 4.1429em;
  vertical-align: top;
}

.product .product-summary .cart-form-wrapper .quantity .minus, .tbl-shopping-cart .quantity .minus, .product .product-summary .cart-form-wrapper .quantity .plus, .tbl-shopping-cart .quantity .plus {
  background: none repeat scroll 0 0 transparent;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-family: 'Open Sans', sans-serif;
  height: 18px;
  left: 3em;
  line-height: 1;
  outline: medium none;
  padding: 0;
  position: absolute;
  width: 18px;
}

.product .product-summary .cart-form-wrapper .quantity .minus, .tbl-shopping-cart .quantity .minus {
  bottom: 0;
}

.product .product-summary .cart-form-wrapper .quantity .plus, .tbl-shopping-cart .quantity .plus {
  top: 0;
}

.product .product-summary .cart-form-wrapper .quantity .qty, .tbl-shopping-cart .quantity .qty {
  -moz-appearance: textfield;
  border: 1px solid #ccc;
  height: 41px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  width: 40px;
}

/* -------- Star-Rating ---------- */

.star-rating {
  font-size: 14px;
  height: 16px;
  line-height: 1;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  top: 5px;
  width: 75px;
  &::before {
    color: #a9a9a9;
    content: "";
    float: left;
    font-family: fontawesome;
    left: 0;
    letter-spacing: 2px;
    position: absolute;
    top: 0;
  }
  span {
    &::before {
      color: #a9a9a9;
      content: "";
      float: left;
      font-family: fontawesome;
      left: 0;
      letter-spacing: 2px;
      position: absolute;
      top: 0;
    }
    float: left;
    left: 0;
    overflow: hidden;
    padding-top: 1.5em;
    position: absolute;
    top: 0;
  }
}

/* -------- Tag Sale ---------- */

.product .tag-sale {
  margin: 0;
  top: 0;
  display: block;
  left: auto;
  right: 0;
  -webkit-transition: 400ms;
  -o-transition: 400ms;
  transition: 400ms;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  padding: 4px 10px 4px 16px;
  position: absolute;
  text-align: right;
  text-transform: uppercase;
  z-index: 9;
  .tag-sale::before {
    border-color: transparent transparent transparent #ddd;
    border-style: solid;
    border-width: 12px 13px 13px 8px;
    content: "";
    height: 0;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 1px;
    width: 0;
  }
}

/* -------- Cart Page ---------- */

.tbl-shopping-cart {
  .product-thumbnail img {
    max-width: 72px;
    max-height: 72px;
  }
  .product-remove {
    text-align: center;
  }
  .variation .variation-size {
    font-size: 12px;
    span {
      font-size: 12px;
    }
  }
  tr {
    th, td {
      vertical-align: middle !important;
    }
  }
  .coupon {
    label, input, button {
      vertical-align: middle !important;
    }
  }
  tr td {
    padding: 10px 12px !important;
  }
}

table.no-border td {
  border: none !important;
}

/* -------- Checkout Page ---------- */

.payment-method .radio {
  border: 1px solid #eee;
  padding: 10px;
  > label {
    color: #111;
    font-size: 17px;
  }
  input {
    margin-top: 8px;
    vertical-align: initial;
  }
  > p {
    margin-left: 20px;
  }
}

#checkout-shipping-address, .top-nav-search-box .indicator, .top-nav-cart-box .indicator {
  display: none;
}

/* -------- Top Nav Search And Cart ---------- */

.dropdown-cart {
  padding: 10px;
  .cart-table-list {
    color: #222;
    border: none;
    i {
      color: #222;
      border: none;
    }
    a {
      line-height: normal;
      color: #222;
    }
    td img {
      width: 50px;
      height: auto;
    }
    &.table > tbody > tr > td {
      border: none;
      line-height: 20px;
      height: 20px;
      vertical-align: middle;
    }
  }
  .total-cart {
    display: inline-block;
    width: 100%;
    padding-top: 20px;
    color: #222;
    text-align: right;
    ul {
      float: right;
      list-style: none;
      padding: 0;
      width: 100%;
      li {
        display: block;
        overflow: hidden;
        span {
          width: 100px;
          display: inline-block;
          text-align: right;
          white-space: nowrap;
        }
      }
    }
    .table > {
      tbody > tr > {
        td, th {
          border: 0;
        }
      }
      tfoot > tr > {
        td, th {
          border: 0;
        }
      }
      thead > tr > {
        td, th {
          border: 0;
        }
      }
    }
  }
  .cart-btn .btn {
    width: auto;
    float: none;
    padding: 5px 15px;
  }
}

/*
 * blog.less
 * -----------------------------------------------
*/

.blog-posts .post {
  position: relative;
  .entry-title {
    text-transform: uppercase;
  }
  .post-thumb {
    overflow: hidden;
    position: relative;
  }
  .entry-content {
    position: relative;
    .post-date, .post-visited {
      color: #a9a9a9;
      font-size: 13px;
    }
    .post-date {
      &.right {
        top: -10px;
        height: 60px;
        padding: 6px 8px;
        position: absolute;
        right: 0;
        text-align: center;
        width: 60px;
      }
      background: #222222 none repeat scroll 0 0;
      display: inline-block;
      padding: 0px 14px;
      top: 0;
    }
    ul li {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
  .entry-meta span {
    i {
      margin-right: 5px;
    }
    font-size: 12px;
    margin-right: 15px;
  }
  .post-date {
    p {
      font-size: 14px;
      color: #a9a9a9;
    }
    a {
      font-size: 14px;
      color: #a9a9a9;
      &:hover {
        color: #333333;
      }
    }
  }
  .like-comment li {
    margin-bottom: 0;
    margin-top: 0;
  }
  .post-thumb .owl-dots {
    bottom: 0;
    position: absolute;
    right: 10px;
    .owl-dot span {
      border-radius: 50%;
      height: 10px;
      width: 10px;
    }
  }
}

.like-comment {
  li i {
    font-size: 20px;
    margin-right: 3px;
    vertical-align: middle;
  }
  &.small-font li i {
    font-size: 16px;
  }
}

.blog-pull-right {
  float: right;
}

@media only screen and (max-width: 767px) {
  .blog-pull-right {
    float: none;
  }
}

.media-post {
  .post-thumb {
    float: left;
    margin: 0 10px 10px 0;
  }
  .post-right {
    overflow: hidden;
  }
  .post-title a {
    font-size: 13px;
    letter-spacing: 1px;
  }
}

@media only screen and (max-width: 991px) {
  .blog-posts .post {
    margin-bottom: 30px;
  }
}

/* -------- Entry-Meta Absolute ---------- */

.post {
  .entry-header {
    position: relative;
    overflow: hidden;
  }
  .entry-meta.meta-absolute {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    i {
      display: block;
      font-size: 28px;
    }
  }
  &:hover .entry-header .entry-date {
    right: 0;
    transform: rotate(0deg);
  }
  .entry-header .entry-date {
    position: absolute;
    right: -47px;
    top: 0;
    transform: rotate(45deg);
    transition: all 200ms ease-in-out 0s;
  }
}

.post-overlay::after {
  background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
  content: "";
  height: 100%;
  opacity: 0;
  position: absolute;
  top: -100%;
  transition: all 300ms ease-in-out 0s;
  width: 100%;
}

.post {
  &:hover .post-overlay::after {
    opacity: 1;
    top: 0;
  }
  .entry-header .post-link {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    top: 0;
    transition: all 300ms ease-in-out 0s;
    opacity: 0;
  }
  &:hover .entry-header .post-link {
    opacity: 1;
    top: 45%;
  }
  .entry-header .post-link {
    a {
      border: 1px solid #fff;
      display: inline-block;
      height: 32px;
      line-height: 32px;
      width: 32px;
    }
    i {
      color: #fff;
      font-size: 14px;
    }
    a:hover i {
      color: #fff;
    }
  }
}

/* -------- Blog Single ---------- */

.single-post {
  article {
    border-bottom: 1px solid #d3d3d3;
    margin-bottom: 20px;
    padding-bottom: 30px;
    .entry-title {
      font-family: "Open Sans", sans-serif;
      padding: 15px 0 0;
      text-transform: capitalize;
    }
  }
  .entry-content {
    border: none;
  }
  .author-details {
    border-top: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
    margin: 20px 0;
    padding: 20px 0;
  }
  .post-share ul {
    margin-bottom: 15px;
    li {
      display: inline-block;
      margin-right: 5px;
      a {
        background: none repeat scroll 0 0 #eeeeee;
        border: 1px solid #eeeeee;
        border-radius: 0;
        i {
          color: #555555;
        }
        &:hover i {
          color: #111111;
        }
      }
    }
  }
  .media-heading {
    margin-bottom: 5px;
    margin-top: 0;
  }
  .comments-title {
    margin-bottom: 15px;
  }
  .comment-list {
    padding-left: 0;
    h6 {
      font-family: helvetica;
    }
    li {
      list-style: none;
    }
    .comment-author {
      margin-top: 30px;
    }
    .comment-date, .comment-heading {
      font-size: 13px;
    }
  }
  .reply-box {
    h4 {
      margin-bottom: 15px;
    }
    .form-group {
      input {
        border-radius: 0;
        height: 45px;
        margin-bottom: 28px;
      }
      textarea {
        border-radius: 0;
      }
    }
    #comment-form button.btn, .btn-primary {
      margin-right: 0;
    }
    .tagline {
      border-top: 1px solid #d3d3d3;
    }
  }
}

.reply-box .form-group input {
  border-radius: 0;
  height: 45px;
  margin-bottom: 30px;
}

.post {
  .entry-content {
    border: 1px solid #f7f8f7;
    transition: all 300ms ease-in-out 0s;
  }
  &:hover .entry-content {
    box-shadow: 2px 10px 28px -15px #aaa;
  }
}

.blog-posts.single-post .post {
  .entry-content {
    border: medium none;
  }
  &:hover .entry-content {
    box-shadow: none;
  }
}

/*
 * shortcode.less
 * -----------------------------------------------
*/
/*
 * Shortcode: Alerts.less
 * -----------------------------------------------
*/
/*
 * Shortcode: Buttons.less
 * -----------------------------------------------
*/
/* -------- Btn-Default ---------- */

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  &:focus, &.focus {
    color: #333;
    background-color: #e6e6e6;
    border-color: #8c8c8c;
  }
  &:hover, &:active, &.active {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
}

.open > .dropdown-toggle.btn-default {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-default {
  &:active:hover, &.active:hover {
    color: #333;
    background-color: #d4d4d4;
    border-color: #8c8c8c;
  }
}

.open > .dropdown-toggle.btn-default:hover {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.btn-default {
  &:active:focus, &.active:focus {
    color: #333;
    background-color: #d4d4d4;
    border-color: #8c8c8c;
  }
}

.open > .dropdown-toggle.btn-default:focus {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.btn-default {
  &:active.focus, &.active.focus {
    color: #333;
    background-color: #d4d4d4;
    border-color: #8c8c8c;
  }
}

.open > .dropdown-toggle.btn-default.focus {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.btn-default {
  &:active, &.active {
    background-image: none;
  }
}

.open > .dropdown-toggle.btn-default {
  background-image: none;
}

.btn-default {
  &.disabled:hover, &[disabled]:hover {
    background-color: #fff;
    border-color: #ccc;
  }
}

fieldset[disabled] .btn-default:hover {
  background-color: #fff;
  border-color: #ccc;
}

.btn-default {
  &.disabled:focus, &[disabled]:focus {
    background-color: #fff;
    border-color: #ccc;
  }
}

fieldset[disabled] .btn-default:focus {
  background-color: #fff;
  border-color: #ccc;
}

.btn-default {
  &.disabled.focus, &[disabled].focus {
    background-color: #fff;
    border-color: #ccc;
  }
}

fieldset[disabled] .btn-default.focus {
  background-color: #fff;
  border-color: #ccc;
}

.btn-default {
  .badge {
    color: #fff;
    background-color: #333;
  }
  &:hover {
    background-color: #eeeeee;
    border-color: #eeeeee;
  }
}

/* -------- Btn-Border ---------- */

.btn-border {
  color: #222222;
  background-color: #fff;
  border-color: #222222;
  &:focus, &.focus {
    color: #222222;
    background-color: #e6e6e6;
    border-color: #000000;
  }
  &:hover, &:active, &.active {
    color: #222222;
    background-color: #e6e6e6;
    border-color: #040404;
  }
}

.open > .dropdown-toggle.btn-border {
  color: #222222;
  background-color: #e6e6e6;
  border-color: #040404;
}

.btn-border {
  &:active:hover, &.active:hover {
    color: #222222;
    background-color: #d4d4d4;
    border-color: #000000;
  }
}

.open > .dropdown-toggle.btn-border:hover {
  color: #222222;
  background-color: #d4d4d4;
  border-color: #000000;
}

.btn-border {
  &:active:focus, &.active:focus {
    color: #222222;
    background-color: #d4d4d4;
    border-color: #000000;
  }
}

.open > .dropdown-toggle.btn-border:focus {
  color: #222222;
  background-color: #d4d4d4;
  border-color: #000000;
}

.btn-border {
  &:active.focus, &.active.focus {
    color: #222222;
    background-color: #d4d4d4;
    border-color: #000000;
  }
}

.open > .dropdown-toggle.btn-border.focus {
  color: #222222;
  background-color: #d4d4d4;
  border-color: #000000;
}

.btn-border {
  &:active, &.active {
    background-image: none;
  }
}

.open > .dropdown-toggle.btn-border {
  background-image: none;
}

.btn-border {
  &.disabled:hover, &[disabled]:hover {
    background-color: #fff;
    border-color: #222222;
  }
}

fieldset[disabled] .btn-border:hover {
  background-color: #fff;
  border-color: #222222;
}

.btn-border {
  &.disabled:focus, &[disabled]:focus {
    background-color: #fff;
    border-color: #222222;
  }
}

fieldset[disabled] .btn-border:focus {
  background-color: #fff;
  border-color: #222222;
}

.btn-border {
  &.disabled.focus, &[disabled].focus {
    background-color: #fff;
    border-color: #222222;
  }
}

fieldset[disabled] .btn-border.focus {
  background-color: #fff;
  border-color: #222222;
}

.btn-border {
  .badge {
    color: #fff;
    background-color: #222222;
  }
  border-width: 2px;
  &:hover, &:active, &:focus {
    background-color: #222222;
    border-color: #222222;
    color: #fff;
  }
}

/* -------- Btn-Dark ---------- */

.btn-dark {
  color: #fff;
  background-color: #222222;
  border-color: #222222;
  &:focus, &.focus {
    color: #fff;
    background-color: #090909;
    border-color: #000000;
  }
  &:hover, &:active, &.active {
    color: #fff;
    background-color: #090909;
    border-color: #040404;
  }
}

.open > .dropdown-toggle.btn-dark {
  color: #fff;
  background-color: #090909;
  border-color: #040404;
}

.btn-dark {
  &:active:hover, &.active:hover {
    color: #fff;
    background-color: #000000;
    border-color: #000000;
  }
}

.open > .dropdown-toggle.btn-dark:hover {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}

.btn-dark {
  &:active:focus, &.active:focus {
    color: #fff;
    background-color: #000000;
    border-color: #000000;
  }
}

.open > .dropdown-toggle.btn-dark:focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}

.btn-dark {
  &:active.focus, &.active.focus {
    color: #fff;
    background-color: #000000;
    border-color: #000000;
  }
}

.open > .dropdown-toggle.btn-dark.focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}

.btn-dark {
  &:active, &.active {
    background-image: none;
  }
}

.open > .dropdown-toggle.btn-dark {
  background-image: none;
}

.btn-dark {
  &.disabled:hover, &[disabled]:hover {
    background-color: #222222;
    border-color: #222222;
  }
}

fieldset[disabled] .btn-dark:hover {
  background-color: #222222;
  border-color: #222222;
}

.btn-dark {
  &.disabled:focus, &[disabled]:focus {
    background-color: #222222;
    border-color: #222222;
  }
}

fieldset[disabled] .btn-dark:focus {
  background-color: #222222;
  border-color: #222222;
}

.btn-dark {
  &.disabled.focus, &[disabled].focus {
    background-color: #222222;
    border-color: #222222;
  }
}

fieldset[disabled] .btn-dark.focus {
  background-color: #222222;
  border-color: #222222;
}

.btn-dark .badge {
  color: #222222;
  background-color: #fff;
}

/* -------- Btn-Gray ---------- */

.btn-gray {
  color: #333333;
  background-color: #eeeeee;
  border-color: #eeeeee;
  &:focus, &.focus {
    color: #333333;
    background-color: #d5d5d5;
    border-color: #afafaf;
  }
  &:hover, &:active, &.active {
    color: #333333;
    background-color: #d5d5d5;
    border-color: #d0d0d0;
  }
}

.open > .dropdown-toggle.btn-gray {
  color: #333333;
  background-color: #d5d5d5;
  border-color: #d0d0d0;
}

.btn-gray {
  &:active:hover, &.active:hover {
    color: #333333;
    background-color: #c3c3c3;
    border-color: #afafaf;
  }
}

.open > .dropdown-toggle.btn-gray:hover {
  color: #333333;
  background-color: #c3c3c3;
  border-color: #afafaf;
}

.btn-gray {
  &:active:focus, &.active:focus {
    color: #333333;
    background-color: #c3c3c3;
    border-color: #afafaf;
  }
}

.open > .dropdown-toggle.btn-gray:focus {
  color: #333333;
  background-color: #c3c3c3;
  border-color: #afafaf;
}

.btn-gray {
  &:active.focus, &.active.focus {
    color: #333333;
    background-color: #c3c3c3;
    border-color: #afafaf;
  }
}

.open > .dropdown-toggle.btn-gray.focus {
  color: #333333;
  background-color: #c3c3c3;
  border-color: #afafaf;
}

.btn-gray {
  &:active, &.active {
    background-image: none;
  }
}

.open > .dropdown-toggle.btn-gray {
  background-image: none;
}

.btn-gray {
  &.disabled:hover, &[disabled]:hover {
    background-color: #eeeeee;
    border-color: #eeeeee;
  }
}

fieldset[disabled] .btn-gray:hover {
  background-color: #eeeeee;
  border-color: #eeeeee;
}

.btn-gray {
  &.disabled:focus, &[disabled]:focus {
    background-color: #eeeeee;
    border-color: #eeeeee;
  }
}

fieldset[disabled] .btn-gray:focus {
  background-color: #eeeeee;
  border-color: #eeeeee;
}

.btn-gray {
  &.disabled.focus, &[disabled].focus {
    background-color: #eeeeee;
    border-color: #eeeeee;
  }
}

fieldset[disabled] .btn-gray.focus {
  background-color: #eeeeee;
  border-color: #eeeeee;
}

.btn-gray .badge {
  color: #eeeeee;
  background-color: #333333;
}

.btn-circled {
  border-radius: 25px;
}

.btn-flat {
  border-radius: 0;
}

/* -------- Btn-Default ---------- */

.btn {
  font-size: 14px;
  padding: 8px 22px;
  line-height: 1.38;
}

/* -------- Btn-xs ---------- */

.btn-xs {
  font-size: 11px;
  padding: 3px 14px;
}

/* -------- Btn-sm ---------- */

.btn-sm {
  font-size: 12px;
  padding: 5px 17px;
}

/* -------- Btn-lg ---------- */

.btn-lg {
  font-size: 14px;
  padding: 10px 26px;
}

/* -------- Btn-xl ---------- */

.btn-xl {
  font-size: 16px;
  padding: 13px 32px;
}

/* -------- Btn-transparent ---------- */

.btn-transparent {
  background-color: transparent;
  color: #fff;
}

.hvr-shutter-out-horizontal::before {
  background: #000;
}

/*
 * Blockquote.less
 * -----------------------------------------------
*/

blockquote {
  border-left: 3px solid #eeeeee;
  margin: 20px 0;
  padding: 30px;
  &.gray {
    border-left: 3px solid #666666;
    background-color: #eeeeee;
    color: #333333;
    &.bg-theme-colored {
      background-color: #eeeeee;
    }
  }
  &.dark {
    border-left: 3px solid #3c3c3c;
    background-color: #555555;
    color: #fff;
    footer {
      color: #d3d3d3;
    }
  }
  p {
    font-family: "Playfair Display", serif;
    font-size: 16px;
    font-style: italic;
  }
}

/*
 * Shortcode: clients.less
 * -----------------------------------------------
*/

.clients-logo {
  .item {
    padding: 10px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }
  img {
    max-height: 90px;
    width: auto;
    display: inline;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
  &.carousel {
    text-align: center;
    .item {
      box-shadow: none;
      margin: 0 10px;
    }
  }
  &.style2 .item {
    box-shadow: none;
    img {
      max-height: 120px;
    }
  }
}

.client-img {
  opacity: 0.6;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  &:hover {
    opacity: 1;
  }
  img {
    max-height: 90px;
  }
}

.clients-logo.carousel {
  text-align: center;
  .item {
    box-shadow: none;
    margin: 0 10px;
  }
}

/*
 * Shortcode: Countdown.less
 * -----------------------------------------------
*/

.countdown-timer {
  li {
    color: #808080;
    display: inline-block;
    padding: 30px 20px;
    background-color: #f1f1f1;
    margin-right: 15px;
    font-size: 40px;
    text-align: center;
    min-width: 120px;
    min-height: 120px;
    span {
      color: #c0c0c0;
      display: block;
      font-size: 15px;
      margin-top: 5px;
    }
  }
  &.timer-box {
    padding: 20px;
    padding-top: 40px;
    text-align: center;
    li {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.05);
      margin-bottom: 15px;
      span {
        color: #eeeeee;
      }
    }
  }
  &.timer-deep-bg li {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

@media only screen and (max-width: 767px) {
  .timer-responsive li {
    margin-top: 30px;
    font-size: 20px;
    min-width: 60px;
    min-height: 60px;
    padding: 20px 10px;
  }
  .countdown-timer li span {
    font-size: 12px;
  }
}

/*
 * Shortcode: Countdown-Timer.less
 * -----------------------------------------------
*/
/*
 * Shortcode: Dividers.less
 * -----------------------------------------------
*/

.divider {
  background-repeat: repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.parallax {
  background-repeat: repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: repeat-y;
  background-position: top center;
}

/* -------- Parallax ---------- */

.mobile-parallax {
  background-size: cover;
  background-attachment: scroll;
  background-position: center center;
}

.divider .container {
  padding-top: 60px;
  padding-bottom: 60px;
  &.small-padding {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  &.extra-padding {
    padding-top: 180px;
    padding-bottom: 180px;
  }
}

/*
 * Shortcode: Facebook-Disqus-Comments.less
 * -----------------------------------------------
*/

.fb-comments, .fb_iframe_widget, .fb-comments > span, .fb_iframe_widget > span, .fb-comments > span > iframe, .fb_iframe_widget > span > iframe {
  display: block;
  margin: 0;
  width: 100%;
}

/*
 * Shortcode: faqs.less
 * -----------------------------------------------
*/
/*
 * Shortcode: Flexslider.less
 * -----------------------------------------------
*/

.flex-container a:active, .flexslider a:active, .flex-container a:focus, .flexslider a:focus {
  outline: none;
  border: none;
}

.flex-direction-nav {
  display: none;
}

.flexslider {
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
}

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.flex-control-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  width: auto;
  position: absolute;
  z-index: 10;
  text-align: center;
  bottom: 15px;
  right: 15px;
  li {
    float: left;
    display: block;
    margin: 0 3px;
    width: 10px;
    height: 10px;
    a {
      display: block;
      cursor: pointer;
      text-indent: -9999px;
      width: 10px;
      height: 10px;
      border: 1px solid #fff;
      border-radius: 50%;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
    &:hover a, a.flex-active {
      background-color: #fff;
    }
  }
}

/*
 * Shortcode: Flip Box
 * -----------------------------------------------
*/

.card {
  float: left;
  min-height: 300px;
  padding-bottom: 25%;
  position: relative;
  text-align: center;
  width: 100%;
  &:nth-child(1) {
    margin-left: -3px;
    margin-right: 1px;
    margin-right: 1px;
  }
  .card:nth-child(3) {
    margin-right: 1px;
  }
  .card__front {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
  }
  .card__back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
  }
  .card__text {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    line-height: 20px;
  }
  &.effect__hover:hover, &.effect__click.flipped, &.effect__random.flipped {
    .card__front {
      -webkit-transform: rotateY(-180deg);
      transform: rotateY(-180deg);
    }
    .card__back {
      -webkit-transform: rotateY(0);
      transform: rotateY(0);
    }
  }
}

/*
 * Responsive : Flip Box
 * -----------------------------------------------
*/
@media only screen and (max-width: 767px) {
  .card {
    margin-bottom: 30px;
    min-height: 489px;
  }
}

@media only screen and (min-width: 501px) and (max-width: 600px) {
  .card {
    margin-bottom: 30px;
    min-height: 400px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 500px) {
  .card {
    margin-bottom: 30px;
    min-height: 350px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 449px) {
  .card {
    margin-bottom: 30px;
    min-height: 300px;
  }
}

/*
 * Shortcode: funfacts.less
 * -----------------------------------------------
*/

.funfact {
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  &.border {
    border: 1px solid #d3d3d3;
  }
  &.no-border {
    border: 0;
  }
  &.border {
    border: 1px solid #d3d3d3;
  }
  i {
    font-size: 45px;
  }
  h2 {
    position: relative;
    &:after {
      bottom: 0;
    }
  }
  span {
    color: #eeeeee;
    font-size: 18px;
  }
  .animate-number {
    line-height: 1;
  }
  &.style-1 {
    i {
      -webkit-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
    }
    .funfact-title {
      -webkit-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      color: #999999;
      font-size: 14px;
      text-transform: uppercase;
    }
    &:hover {
      i {
        font-size: 40px;
        opacity: 0.3;
      }
      .funfact-title {
        color: #333333;
      }
    }
  }
}

/* funfact style-1*/

/*
 * Shortcode: Icon-Boxes.less
 * -----------------------------------------------
*/

.icon-box {
  padding: 40px 17px;
  .icon {
    display: inline-block;
    height: 68px;
    margin-bottom: 20px;
    text-align: center;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    width: 68px;
    i {
      line-height: 68px;
    }
    &.icon-gray {
      background-color: #eeeeee;
      color: #555555;
      &:hover {
        background-color: #d5d5d5;
        color: #bbbbbb;
      }
      &.icon-bordered {
        background-color: transparent;
        border: 2px solid #eeeeee;
        &:hover {
          background-color: #eeeeee;
          color: #555555;
        }
      }
    }
    &.icon-dark {
      background-color: #111111;
      color: #fff;
      &:hover {
        color: #5e5e5e;
      }
      &.icon-bordered {
        background-color: transparent;
        border: 2px solid #111111;
        color: #111111;
        &:hover {
          background-color: #111111;
          border-color: #111111;
          color: #fff;
        }
      }
    }
    &.icon-white {
      background-color: #fff;
      color: #333333;
      &:hover {
        background-color: #b3b3b3;
        color: #000000;
      }
      &.icon-bordered {
        background-color: transparent;
        border: 2px solid #fff;
        color: #fff;
        &:hover {
          background-color: #fff;
          border-color: #fff;
          color: #111111;
        }
      }
      &.icon-border-effect {
        position: relative;
        &::after {
          box-shadow: 0 0 0 3px #fff;
        }
      }
    }
    &.icon-bordered {
      border: 1px solid #777777;
      &:hover {
        background-color: #777777;
        color: #fff;
      }
    }
    &.icon-rounded {
      border-radius: 3px;
    }
    &.icon-circled {
      border-radius: 50%;
    }
    &.icon-xs {
      height: 30px;
      width: 30px;
      i {
        font-size: 18px;
        line-height: 30px;
      }
    }
    &.icon-sm {
      height: 50px;
      width: 50px;
      i {
        font-size: 22px;
        line-height: 50px;
      }
    }
    &.icon-md {
      height: 75px;
      width: 75px;
      i {
        font-size: 36px;
        line-height: 75px;
      }
    }
    &.icon-lg {
      height: 90px;
      width: 90px;
      i {
        font-size: 48px;
        line-height: 90px;
      }
    }
    &.icon-xl {
      height: 120px;
      width: 120px;
      i {
        font-size: 60px;
        line-height: 120px;
      }
    }
    &.icon-border-effect {
      position: relative;
      &::after {
        border-radius: 50%;
        box-shadow: 0 0 0 3px #eeeeee;
        box-sizing: content-box;
        content: "";
        height: 100%;
        left: -4px;
        opacity: 0;
        padding: 4px;
        top: -4px;
        transform: scale(0.8);
        transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
        pointer-events: none;
        position: absolute;
        width: 100%;
      }
      &.effect-flat::after {
        border-radius: 0;
      }
      &.effect-rounded::after {
        border-radius: 3px;
      }
      &.effect-circled::after {
        border-radius: 50%;
      }
      &.icon-gray::after {
        box-shadow: 0 0 0 3px #eeeeee;
      }
    }
    &.icon-top {
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      top: -32px;
    }
  }
  i {
    display: inline-block;
    font-size: 40px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &[class*="pe-7s-"] {
      vertical-align: text-bottom;
    }
  }
  &.left {
    text-align: left;
    a {
      margin-right: 30px;
      padding: 0;
    }
  }
  &.iconbox-border {
    border: 1px solid #dcdcdc;
  }
  &.iconbox-bg {
    background-color: #fafafa;
    &.iconbox-bg-dark {
      background-color: #333333;
    }
  }
  .icon-box-title {
    margin-bottom: 15px;
  }
  .icon-border-effect:hover::after, &:hover .icon-border-effect::after {
    opacity: 1;
    transform: scale(1);
  }
  .icon-box-details {
    margin-left: 80px;
  }
}

/*
 * Shortcode: Infinitescroll.less
 * -----------------------------------------------
*/

#infscr-loading {
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 3px;
  color: #fff;
  font-size: 24px;
  height: 50px;
  line-height: 50px;
  left: 48%;
  position: fixed;
  text-align: center;
  top: 50%;
  width: 50px;
  z-index: 100;
  img {
    display: none;
  }
}

/*
 * Shortcode: Instagram.less
 * -----------------------------------------------
*/
/* -------- Instagram-Feed ---------- */

.instagram-feed-carousel figure, .instagram-feed figure {
  position: relative;
}

.instagram-feed-carousel figure .link-out, .instagram-feed figure .link-out {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background: rgba(0, 0, 0, 0.45);
  color: #f8f8f8;
  line-height: 1;
  padding: 10px;
  font-weight: 300;
  opacity: 0;
}

.instagram-feed-carousel figure .link-out:hover, .instagram-feed figure .link-out:hover {
  background: rgba(0, 0, 0, 0.7);
  color: #f8f8f8;
}

.instagram-feed-carousel figure:hover .link-out {
  opacity: 1;
}

.instagram-feed {
  figure:hover .link-out {
    opacity: 1;
  }
  .item {
    width: 100px;
    float: left;
    margin-bottom: 2px;
    margin-right: 2px;
  }
}

/* -------- Instagram-Feed ---------- */

/* -------- Widget - Instagram-Feed ---------- */

.widget .instagram-feed .item {
  margin-bottom: 5px;
  margin-right: 5px;
  width: 70px;
}

/*
 * Shortcode: Light-Box.less
 * -----------------------------------------------
*/

.nivo-lightbox-theme-default .nivo-lightbox-nav {
  height: 100%;
  top: 0;
}

.lightbox .lb-dataContainer {
  left: 0;
  position: absolute;
  right: 0;
  top: -45px;
}

/*
 * Shortcode: Map.less
 * -----------------------------------------------
*/

.toggle-map {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 16px;
  padding: 30px 0;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  z-index: 200;
  -webkit-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
  &:hover {
    color: #fff;
  }
  span:after {
    content: "\f107";
    font-family: fontawesome;
    margin-left: 10px;
  }
  &.open span:after {
    content: "\f106";
  }
}

.map-canvas {
  height: 400px;
  -webkit-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  &.big {
    height: 720px;
  }
  &.map-hidden {
    height: 0;
  }
}

.btn-show-map {
  color: #111111;
  font-size: 18px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 45%;
  i {
    font-size: 48px;
  }
}

/*
 * Shortcode: opening-hours.less
 * -----------------------------------------------
*/

.opening-hours ul li {
  border-bottom: 1px solid #eeeeee;
  padding: 10px 0;
  margin-bottom: 0;
  span {
    float: left;
  }
  .value {
    float: right;
  }
}

/*
 * Shortcode: Pegination.less
 * -----------------------------------------------
*/

.pagination {
  &.theme-colored li {
    &.active a, a:hover {
      color: #fff;
      border: 1px solid transparent;
    }
  }
  &.dark li {
    a {
      color: #333333;
    }
    &.active a {
      background: #333333;
      color: #fff;
      border: 1px solid transparent;
    }
  }
}

.pager {
  &.theme-colored li.active a {
    color: #fff;
    border: 1px solid transparent;
  }
  &.dark li a {
    color: #fff;
    background-color: #333333;
    border: 1px solid transparent;
  }
}

/*
 * Shortcode: pricing.less
 * -----------------------------------------------
*/

.pricing-table {
  margin-bottom: 30px;
  position: relative;
  .table-price {
    color: #111111;
    font-size: 45px;
    span {
      font-size: 13px;
      vertical-align: middle;
    }
  }
  &.featured {
    border: 1px solid #111111;
  }
  .table-type {
    display: inline;
  }
  .btn-signup {
    margin-right: 0;
    width: 100%;
  }
  .table-list {
    list-style: outside none none;
    padding: 10px 0 10px 20px;
    li {
      border-bottom: 0;
      font-size: 12px;
      padding: 10px 0;
      i {
        margin-right: 8px;
      }
    }
  }
  .package-type span {
    position: relative;
    padding-left: 15px;
    margin-left: 10px;
    &::after {
      background: #2d915b;
      content: "";
      height: 20px;
      left: -20px;
      position: absolute;
      top: 11px;
      width: 20px;
    }
  }
}

/*
 * Shortcode: Process-Steps.less
 * -----------------------------------------------
*/
/*
 * Shortcode: Progress-Bar.less
 * -----------------------------------------------
*/

.progress-item {
  margin-top: 20px;
  .progress {
    background-color: #f8f8f8;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.4) inset;
    border-radius: 0;
    height: 10px;
    margin-bottom: 15px;
    overflow: visible;
  }
  .progress-bar {
    font-size: 11px;
    padding-left: 10px;
    position: relative;
    text-align: left;
    width: 0;
    line-height: 18px;
    -webkit-transition: all 1s ease 0s;
    -o-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    .percent {
      background: none repeat scroll 0 0 #555555;
      border-radius: 8px 0 0;
      padding: 3px 5px;
      position: absolute;
      right: 0;
      top: -30px;
    }
  }
  &.progress-white .progress {
    padding: 2px;
  }
  &.style2 {
    .progress {
      height: 25px;
    }
    .progress-bar .percent {
      background: none;
      color: #555555;
      font-size: 14px;
    }
  }
}

/*
 * Shortcode: Promo.less
 * -----------------------------------------------
*/

.modal-promo-box {
  max-width: 700px;
  padding: 90px 60px;
  margin: 0 auto;
}

/*
 * Shortcode: section-title.less
 * -----------------------------------------------
*/

.small-title {
  position: relative;
  i {
    font-size: 36px;
    margin-top: 7px;
    padding-top: 0px;
  }
  .title {
    margin-left: 15px;
    &:after {
      bottom: -6px;
      content: "";
      height: 1px;
      left: 45px;
      position: absolute;
      width: 45px;
    }
  }
}

.section-title {
  .both-side-line {
    &::after {
      content: "";
      height: 2px;
      position: absolute;
      right: -40px;
      top: 10px;
      width: 30px;
    }
    &::before {
      content: "";
      height: 2px;
      position: absolute;
      left: -40px;
      top: 10px;
      width: 30px;
    }
  }
  .top-side-line::after {
    content: "";
    height: 2px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: -8px;
    width: 40px;
  }
  .left-side-line::before {
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    top: -8px;
    width: 35px;
  }
  .right-side-line::before {
    content: "";
    height: 2px;
    position: absolute;
    right: 0;
    top: -8px;
    width: 35px;
  }
  .sub-title {
    margin: 0;
    position: relative;
    display: inline-block;
  }
  .title {
    margin-top: 0;
    position: relative;
    line-height: 1;
  }
  .icon i {
    font-size: 50px;
    position: absolute;
    top: -3px;
    left: 14px;
    opacity: 0.10;
  }
  p {
    font-size: 14px;
    &.left-bordered {
      border-left: 2px solid #c0c0c0;
      padding-left: 10px;
    }
    &.right-bordered {
      border-right: 2px solid #c0c0c0;
      padding-right: 10px;
    }
  }
  margin-bottom: 30px;
  &.title-border {
    padding-left: 30px;
    &.title-right {
      border-left: 0;
      padding-left: 0;
      padding-right: 30px;
      text-align: right;
    }
  }
  &.icon-bg {
    p {
      font-size: 14px;
      font-style: italic;
      margin-bottom: 0;
    }
    .page-title {
      font-size: 38px;
      line-height: 52px;
      i {
        font-size: 42px;
        line-height: 1.3;
        position: absolute;
        opacity: 0.15;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }
}

/*Theme Section Title*/

/*
 * Shortcode: Separators.less
 * -----------------------------------------------
*/

.title-separator {
  border-top-style: solid;
  border-top-width: 1px;
  font-size: 16px;
  height: auto;
  line-height: 20px;
  margin: 10px 0;
  width: 70%;
  span {
    background: #fff none repeat scroll 0 0;
    border-radius: 2px;
    letter-spacing: 0.2em;
    padding: 2px 10px;
    position: relative;
    text-transform: uppercase;
    top: -12px;
  }
}

.separator {
  color: #c0c0c0;
  line-height: 1.2em;
  margin: 30px auto;
  overflow: hidden;
  text-align: center;
  width: 100%;
  &::before {
    border-bottom: 1px solid #eeeeee;
    content: "";
    display: inline-block;
    height: .65em;
    margin: 0 -4px 0 -100%;
    vertical-align: top;
    width: 50%;
  }
  &::after {
    border-bottom: 1px solid #eeeeee;
    content: "";
    display: inline-block;
    height: .65em;
    margin: 0 -4px 0 -100%;
    vertical-align: top;
    width: 50%;
    margin: 0 -100% 0 0;
  }
  i, span, a {
    display: inline-block;
    margin: 0 20px 0 24px;
    font-size: 20px;
  }
  &.separator-left, &.left i {
    float: left;
    margin: 2px 50px 0 0;
  }
  &.right i {
    float: right;
    margin: 0 0 2px 50px;
  }
  &.separator-medium-line {
    margin: 10px auto;
    width: 20%;
  }
  &.separator-small-line {
    width: 10%;
  }
  &.separator-rouned i {
    background-color: #f5f5f5;
    border-radius: 50%;
    height: 40px;
    left: 0;
    line-height: 40px;
    margin: -12px auto auto;
    position: absolute;
    right: 0;
    width: 40px;
  }
  &.separator-border-rouned i {
    background-color: #f5f5f5;
    border-radius: 50%;
    height: 40px;
    left: 0;
    line-height: 40px;
    margin: -12px auto auto;
    position: absolute;
    right: 0;
    width: 40px;
    background: transparent;
    border: 1px solid #f5f5f5;
  }
  &.separator-rouned {
    &.separator-rouned-left i {
      margin: -12px auto auto;
      right: auto;
      left: 0;
    }
    &.separator-rouned-right i {
      margin: -12px auto auto;
      right: 0;
      left: auto;
    }
  }
  &.dotted {
    &::before, &::after {
      border-bottom: 2px dotted #c0c0c0;
    }
  }
}

/*
 * Shortcode: styled-icons.less
 * -----------------------------------------------
*/

.styled-icons {
  a {
    color: #333333;
    font-size: 18px;
    height: 32px;
    line-height: 32px;
    width: 32px;
    float: left;
    margin: 5px 7px 5px 0;
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: #666666;
    }
  }
  &.icon-gray {
    a {
      background-color: #eeeeee;
      color: #555555;
      display: block;
      font-size: 18px;
      height: 36px;
      line-height: 36px;
      width: 36px;
      &:hover {
        color: #bbbbbb;
      }
    }
    &.icon-bordered a {
      background-color: transparent;
      border: 2px solid #eeeeee;
      &:hover {
        border: 2px solid #d5d5d5;
      }
    }
  }
  &.icon-dark {
    a {
      background-color: #333333;
      color: #eeeeee;
      display: block;
      font-size: 18px;
      height: 36px;
      line-height: 36px;
      width: 36px;
      &:hover {
        color: #5e5e5e;
      }
    }
    &.icon-bordered a {
      background-color: transparent;
      border: 2px solid #111111;
      color: #111111;
      &:hover {
        background-color: #111111;
        border-color: #111111;
        color: #fff;
      }
    }
  }
  &.icon-bordered a {
    border: 1px solid #777777;
    &:hover {
      background-color: #777777;
      color: #fff;
    }
  }
  &.icon-rounded a {
    border-radius: 3px;
  }
  &.icon-circled a {
    border-radius: 50%;
  }
  &.icon-sm a {
    font-size: 13px;
    height: 30px;
    line-height: 30px;
    margin: 2px 7px 2px 0;
    width: 30px;
  }
  &.icon-md a {
    font-size: 24px;
    height: 50px;
    line-height: 50px;
    width: 50px;
  }
  &.icon-lg a {
    font-size: 32px;
    height: 60px;
    line-height: 60px;
    width: 60px;
  }
  &.icon-xl a {
    font-size: 60px;
    height: 120px;
    line-height: 120px;
    width: 120px;
  }
  li {
    display: inline-block;
    margin-bottom: 0;
    margin-top: 0;
  }
}

/*
 * Shortcode: Tabs.less
 * -----------------------------------------------
*/

.tab-content {
  padding: 15px;
  border: 1px solid #eeeeee;
}

.nav-tabs {
  border: none;
  > li {
    float: left;
    margin-bottom: -1px;
    &.active a {
      color: #fff;
      background-color: #a9a9a9;
      &:hover, &:focus {
        color: #fff;
        background-color: #a9a9a9;
      }
    }
    > a {
      background-color: #f5f5f5;
      border-radius: 0;
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      color: #555555;
      padding: 7px 15px;
      margin-right: 0;
      .fa {
        margin-right: 10px;
      }
      &:hover, &:focus {
        color: #333333;
        background-color: transparent;
      }
      i {
        font-size: 32px;
        margin-right: 20px;
        vertical-align: middle;
        display: block;
        font-size: 48px;
        margin-right: 0;
        margin-bottom: 10px;
        vertical-align: middle;
      }
    }
    text-align: center;
  }
}

/* -------- Nav Tabs ---------- */

/* -------- Horizontal-Tab-Centered ---------- */

.horizontal-tab-centered .nav-pills {
  display: inline-block;
  > li {
    > a {
      background: #eeeeee;
      color: #555555;
      font-size: 14px;
      padding: 8px 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      text-transform: capitalize;
      &:hover {
        color: #fff;
      }
      i {
        font-size: 14px;
        margin-right: 8px;
      }
    }
    &.active > a {
      color: #fff;
      &:hover, &:focus {
        color: #fff;
      }
    }
  }
}

/* -------- Horizontal-Tab Fullwide ---------- */

.horizontal-tab.fullwide .nav-tabs > li {
  width: 20%;
}

/* -------- Vertical Nav-Tabs ---------- */

.vertical-tab {
  .tab-content {
    padding: 5px 5px 20px 20px;
    border: none;
  }
  .nav-tabs > li {
    float: none;
    margin-bottom: 0;
    > a {
      background: none;
      border-radius: 0;
      border-bottom: 1px solid #d3d3d3;
      border-right: 1px solid #d3d3d3;
      border-top: 1px solid transparent;
      border-left: 1px solid transparent;
      font-size: 14px;
      padding: 30px 0 30px 15px;
    }
    &:first-child > a {
      border-top: 1px solid #d3d3d3;
    }
    &.active > a {
      background: none;
      border-top: 1px solid transparent;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      border-bottom: 1px solid #d3d3d3;
      &:hover, &:focus {
        background: none;
        border-top: 1px solid transparent;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        border-bottom: 1px solid #d3d3d3;
      }
    }
  }
  .small-padding .nav-tabs > li {
    a {
      padding: 20px 0 20px 15px;
    }
    i {
      font-size: 24px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .vertical-tab .tab-content {
    padding: 20px 5px;
  }
  .nav-tabs > li {
    > a {
      border-radius: 0;
      border-bottom: none;
      border-left: 2px solid #d3d3d3;
    }
    &.active > a {
      border-bottom: none;
      border-left: 2px solid #d3d3d3;
      &:hover, &:focus {
        border-bottom: none;
        border-left: 2px solid #d3d3d3;
      }
    }
  }
}

.custom-nav-tabs {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: -moz-use-text-color;
  border-image: none;
  border-style: none solid none none;
  border-width: medium 0px medium medium;
  margin-right: 53px;
  > li {
    float: none;
    margin: 0 0 1px;
    a {
      background: #eeeeee none repeat scroll 0 0;
      border: medium none;
      border-radius: 0;
      display: block;
      font-size: 17px;
      font-weight: 700;
      line-height: 59px;
      margin: 0;
      outline: medium none;
      padding: 0;
      text-align: center;
      text-transform: uppercase;
    }
    &.active > a {
      border: medium none;
      color: #000;
      padding: 0;
      &:hover, &:focus {
        border: medium none;
        color: #000;
        padding: 0;
      }
    }
    > a {
      &:hover, &:focus {
        border: medium none;
        color: #000;
        padding: 0;
      }
    }
  }
}

/* -------- Services Tab ---------- */

.services-tab {
  .nav-tabs {
    border-bottom: 1px solid #f1f1f1;
    text-align: center;
    > li {
      border-left: 1px solid #eeeeee;
      margin-bottom: 0;
      width: 20%;
      &:first-child {
        border-left: none;
      }
      > a {
        background-color: #fff;
        color: #a9a9a9;
        font-size: 13px;
        margin-right: 0;
        padding: 35px 20px;
        text-transform: uppercase;
        i {
          display: block;
          font-size: 36px;
          margin-right: 0;
          margin-bottom: 10px;
          vertical-align: middle;
        }
      }
      &.active > a {
        color: #fff;
        &:hover, &:focus {
          color: #fff;
        }
      }
      a {
        &:hover, &:focus {
          color: #fff;
        }
      }
      &.active > a {
        i, &:hover i, &:focus i {
          color: #fff;
        }
      }
      a {
        &:hover i, &:focus i {
          color: #fff;
        }
      }
    }
  }
  .tab-content .service-content {
    .sub-title {
      color: #c8c8c8;
      font-family: 'Playfair Display', sans-serif;
    }
    .title {
      color: #555555;
      font-family: 'Playfair Display', sans-serif;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .services-tab .service-content {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .services-tab .service-content {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .services-tab .nav.nav-tabs a {
    padding: 10px 0;
    font-size: 9px;
    i {
      font-size: 28px;
    }
  }
}

.vertical-tab .nav-tabs li a:active {
  color: #d3d3d3;
}

/*
 * Shortcode: Teams.less
 * -----------------------------------------------
*/

.team-members {
  overflow: hidden;
  position: relative;
  .team-details {
    background: #fff none repeat scroll 0 0;
    bottom: -100px;
    padding: 10px 15px;
    position: absolute;
    transition: all 300ms ease-in-out 0s;
    width: 100%;
  }
  &:hover .team-details {
    bottom: 0;
  }
}

.team-social {
  opacity: 0;
  padding: 14px 0;
  position: absolute;
  right: 0;
  top: -140px;
  transition: all 300ms ease-in-out 0s;
  li {
    display: block;
  }
}

.team-members {
  &:hover .team-social {
    opacity: 1;
    top: 0;
  }
  .team-social li a {
    margin: 0;
  }
}

.team-social li a {
  i {
    color: #fff;
  }
  &:hover i {
    color: #212331;
  }
}

@media only screen and (max-width: 1199px) {
  .team-members {
    .team-details {
      bottom: -45px;
    }
    &:hover .team-details {
      bottom: -18px;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 767px) {
  .team-members .team-details {
    bottom: -75px;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 991px) {
  .team-members .team-details {
    bottom: -100px;
  }
}

/*
 * Shortcode: testimonials.less
 * -----------------------------------------------
*/

.testimonial {
  .item {
    position: relative;
    overflow: hidden;
    .thumb img {
      margin: auto;
      max-width: 75px;
    }
  }
  .comment p {
    color: #a9a9a9;
    font-weight: 400;
  }
}

/* -------- Testimonial-Style1 ---------- */

.testimonials.attorney-details .thumb img {
  width: auto;
}

/* -------- Testimonial-Style2 ---------- */

.testimonial {
  &.style2 {
    text-align: center;
    .thumb img {
      display: inline-block;
      width: auto;
      width: 75px;
    }
  }
  &.style1 {
    .item {
      border: none;
      &::before {
        display: none;
      }
    }
    .quote, .thumb {
      margin-bottom: 30px;
    }
    .author {
      margin-bottom: 5px;
      text-transform: uppercase;
    }
    .title {
      color: #c0c0c0;
      margin-bottom: 20px;
    }
    .comment {
      padding: 35px 25px;
      p {
        font-weight: 500;
        margin-left: 30px;
      }
      background: #f8f8f8;
      color: #777777;
      border: 3px solid white;
      border-radius: 10px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
      margin: 1px;
      position: relative;
      &::before {
        color: #d3d3d3;
        content: "\f10d";
        font-family: fontawesome;
        font-size: 30px;
        left: 20px;
        position: absolute;
        top: 7px;
      }
      &::after {
        content: "";
        width: 14px;
        height: 14px;
        position: absolute;
        left: 30px;
        background: url('../images/testimonials/quote_arrow.png') 0px 0px no-repeat;
        top: 97%;
      }
    }
  }
}

/* -------- Testimonial-Style1 ---------- */

/*.testimonial.style1 .comment::after {
  bottom: -18px;
  right: 19px;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 15px;
  border-color: fade(@black, 04%) transparent transparent transparent;
}*/

.owl-carousel .owl-item .testimonial img {
  display: inline;
  width: auto;
}

.testimonial .signature {
  width: 60px;
}

.testimonial-content {
  &::after {
    content: "\f10d";
    font-family: fontawesome;
    font-size: 80px;
    left: 0;
    opacity: 0.1;
    position: absolute;
    top: -65px;
  }
  margin-top: 30px;
  margin-left: 140px;
  position: relative;
}

/*
 * Shortcode: Toggles-Accordions.less
 * -----------------------------------------------
*/

.panel-group {
  .panel {
    box-shadow: none;
    -webkit-border-radius: 0 0 0 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0 0 0 0;
    border-radius: 0 0 0 0;
    border: none;
    position: relative;
  }
  .panel-title {
    font-size: 13px;
    a {
      background: #f8f8f8;
      display: block;
      font-size: 14px;
      font-weight: 500;
      padding: 10px 15px 10px 48px;
      white-space: normal;
      &.active {
        background: #16174f none repeat scroll 0 0;
        border-bottom: 1px solid #d3d3d3;
        color: #fff;
        .open-sub:after {
          display: none;
        }
        &::after {
          color: #fff;
          content: "\f068";
        }
      }
      &::after {
        color: #888;
        content: "\f067";
        font-family: fontawesome;
        font-size: 14px;
        left: 0;
        padding: 10px 14px;
        position: absolute;
        top: 0;
      }
    }
    &.active.panel-group .panel-title {
      &:hover a, &:focus a {
        color: #333333;
      }
    }
    .open-sub {
      display: block;
      position: relative;
      &:before, &:after {
        content: "";
        display: block;
        position: absolute;
        height: 1px;
        top: 50%;
        margin-top: -0.5px;
        left: 50%;
      }
    }
  }
  .panel-content {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ddd;
    padding: 10px 20px 20px;
    > :last-child {
      margin-bottom: 0;
    }
  }
  &.white .panel-title a {
    background-color: #fff;
  }
  &.toggle {
    .panel-heading {
      padding: 0;
      border-bottom: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .panel-body {
      padding: 10px 0 5px 25px;
    }
  }
  &.accordion.transparent .panel-title a, &.toggle.transparent .panel-title a, &.accordion.transparent .panel-title a.active, &.toggle.transparent .panel-title a.active {
    background: transparent;
    border: none;
    color: #333333;
  }
  &.accordion.transparent .panel-title a::after, &.toggle.transparent .panel-title a::after, &.accordion.transparent .panel-title a.active::after, &.toggle.transparent .panel-title a.active::after {
    color: #333333;
    background: transparent;
  }
  &.accordion.transparent .panel-content {
    background: transparent;
  }
  &.toggle {
    &.transparent .panel-content {
      background: transparent;
    }
    .panel-heading + .panel-collapse > {
      .panel-body, .list-group {
        border-top: none;
      }
    }
  }
}

/* -------- Toggle ---------- */

/*
 * Shortcode: timetable-fullcalendar.less
 * -----------------------------------------------
*/

.fc-timetable-wrapper {
  .fc-scroller {
    height: auto;
    overflow: hidden;
  }
  .fc-toolbar {
    display: none;
    height: 38px;
    margin-bottom: 20px;
    h2 {
      color: #7f7f7f;
      font-family: 'Lato', sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 38px;
      text-transform: uppercase;
    }
    .fc-button {
      -moz-box-sizing: border-box;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -webkit-box-sizing: border-box;
      -webkit-transition: all 0.3s ease;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      box-shadow: none;
      box-sizing: border-box;
      height: 38px;
      line-height: 36px;
      transition: all 0.3s ease;
      width: 40px;
      &:hover {
        background: #5fc7ae;
        border-color: #5fc7ae;
        .fc-icon {
          &.fc-icon-left-single-arrow {
            background-position: -108px -50px;
          }
          &.fc-icon-right-single-arrow {
            background-position: -115px -50px;
          }
        }
      }
      .fc-icon {
        height: 11px;
        top: -1px;
        width: 6px;
        &:after {
          display: none;
        }
        &.fc-icon-left-single-arrow {
          background-position: -100px -50px;
        }
        &.fc-icon-right-single-arrow {
          background-position: -122px -50px;
        }
      }
    }
  }
  .fc-day-header {
    background: #ffffff;
    color: #7f7f7f;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 52px;
  }
  .fc-time-grid .fc-day.fc-widget-content, .fc-axis {
    background: #ffffff;
  }
  .filter-departments {
    margin-bottom: 20px;
    li {
      margin-bottom: 20px;
    }
    a {
      background: #eeeeee;
      border-color: #eeeeee;
      border-radius: 5px;
      color: #333333;
      padding: 5px;
      padding: 7px 15px;
    }
    .active {
      background: #888888;
      border-color: #888888;
      color: #ffffff;
    }
    .fc-departments-orthopaedics {
      background: #72a230;
      border-color: #72a230;
    }
    .fc-departments-cardiology {
      background: #ffab00;
      border-color: #ffab00;
    }
    .fc-departments-neurology {
      background: #fd4084;
      border-color: #fd4084;
    }
    .fc-departments-dental {
      background: #00a4ef;
      border-color: #00a4ef;
    }
    .fc-departments-haematology {
      background: #80acdd;
      border-color: #80acdd;
    }
  }
  .fc-event {
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    border-radius: 0;
    border: 0;
    border: 2px solid #5fc7ae;
    color: #ffffff;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 300;
    transition: all 0.3s ease;
    .fc-bg {
      opacity: 0;
    }
    .fc-content {
      padding-left: 10px;
      padding-top: 10px;
      div.fc-time {
        display: none;
      }
    }
    &.hide {
      opacity: 0;
      z-index: -2;
    }
    &.fc-departments-orthopaedics {
      background: #72a230;
      border-color: #72a230;
    }
    &.fc-departments-cardiology {
      background: #ffab00;
      border-color: #ffab00;
    }
    &.fc-departments-neurology {
      background: #fd4084;
      border-color: #fd4084;
    }
    &.fc-departments-dental {
      background: #00a4ef;
      border-color: #00a4ef;
    }
    &.fc-departments-haematology {
      background: #80acdd;
      border-color: #80acdd;
    }
  }
  td.fc-time {
    color: #7f7f7f;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 70px;
    text-align: center;
  }
  .fc-time-grid-container {
    height: auto;
  }
  .fc-content-skeleton .fc-event-container {
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  .fc-timetable-wrapper {
    .fc-day-header {
      font-size: 10px;
    }
    .fc-time-grid-event {
      .fc-time, .fc-title {
        font-size: 12px;
      }
    }
    .fc-event .fc-content {
      padding-left: 0;
      padding-top: 0;
    }
  }
}

/*
 * Shortcode: Vertical-Timeline.less
 * -----------------------------------------------
*/

.cd-timeline-content {
  background: #f1f1f1;
  &::before {
    border-color: transparent transparent transparent #eee;
  }
}

.cd-timeline-block:nth-child(2n) .cd-timeline-content::before {
  border-color: transparent #eee transparent transparent;
}

#cd-timeline.cd-timeline-simple {
  &::before {
    background: #ccc none repeat scroll 0 0;
    height: 82%;
    top: 65px;
    width: 2px;
  }
  &.time-line-media::before {
    height: 65%;
  }
}

.cd-timeline-simple .cd-timeline-block {
  margin: 38px 0 20px 0;
  .cd-timeline-content {
    &::before {
      display: none;
    }
    padding-top: 0;
    background: none;
    box-shadow: none;
    p {
      font-size: 14px;
    }
  }
  .cd-timeline-img {
    background: #333 none repeat scroll 0 0;
    box-shadow: 0 0 0 4px #ccc, 0 3px 0 rgba(0, 0, 0, 0.06) inset, 0 0px 0 3px rgba(0, 0, 0, 0.05);
  }
}

@media only screen and (max-width: 992px) {
  .cd-timeline-simple .cd-timeline-block .cd-timeline-content {
    text-align: left;
  }
}

@media only screen and (max-width: 768px) {
  .cd-timeline-simple .cd-timeline-block .cd-timeline-content {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 992px) {
  .cd-timeline-simple .cd-timeline-block .cd-timeline-content h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .cd-timeline-simple .cd-timeline-block .cd-timeline-content h2 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 768px) {
  .cd-timeline-simple .cd-timeline-block .cd-timeline-content .cd-date {
    font-size: 14px;
  }
}

/* Vertical Masonry Timeline */

.vertical-masonry-timeline .each-masonry-item {
  position: relative;
  width: 50%;
  margin-bottom: 30px;
  &:nth-child(2) {
    margin-top: 80px;
  }
  &:nth-child(2n) {
    padding-left: 50px;
  }
  &:nth-child(2n+1) {
    padding-right: 50px;
  }
  .timeline-block {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #d7e4ed;
    height: 100%;
    padding: 20px;
    position: relative;
    width: 100%;
    &:before, &:after {
      border-style: solid;
      border-width: 8px;
      content: "";
      display: block;
      position: absolute;
      top: 20px;
    }
  }
  &:nth-child(2n+1) .timeline-block {
    &:before {
      border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #c6d2db;
      left: auto;
      right: -16px;
    }
    &:after {
      border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #ffffff;
      left: auto;
      right: -15px;
    }
  }
  &:nth-child(2n) .timeline-block {
    &:before {
      border-color: rgba(0, 0, 0, 0) #c6d2db rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
      left: -16px;
      right: auto;
    }
    &:after {
      border-color: rgba(0, 0, 0, 0) #ffffff rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
      left: -15px;
      right: auto;
    }
  }
  &:nth-child(2n+1) .timeline-post-format {
    left: auto;
    right: -82px;
  }
  &:nth-child(2n) .timeline-post-format {
    left: -80px;
    right: auto;
  }
}

.timeline-post-format {
  border: 3px solid #bbb;
  border-radius: 100%;
  color: #bbb;
  height: 60px;
  overflow: hidden;
  position: absolute;
  top: -2px;
  width: 60px;
  z-index: 1;
  &:after {
    background: #fff none repeat scroll 0 0;
    color: #fff;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  i {
    font-size: 18px;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

.vertical-masonry-timeline-wrapper {
  &:before {
    background: #ddd none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 100%;
    left: 50%;
    margin-left: 0px;
    position: absolute;
    width: 4px;
  }
  &:after {
    background: rgba(0, 0, 0, 0) linear-gradient(#ddd, transparent) repeat scroll 0 0;
    bottom: -100px;
    content: "";
    height: 100px;
    left: 50%;
    margin-left: 0px;
    position: absolute;
    width: 4px;
  }
}

.vertical-masonry-timeline::after {
  background: rgba(0, 0, 0, 0) linear-gradient(transparent, #ddd) repeat scroll 0 0;
  content: "";
  height: 100px;
  left: 50%;
  position: absolute;
  top: -65px;
  width: 4px;
}

@media only screen and (min-width: 768px) {
  .vertical-masonry-timeline .each-masonry-item {
    &.item-right {
      padding-right: 0px;
      padding-left: 50px;
      .timeline-block {
        &:before {
          border-color: rgba(0, 0, 0, 0) #c6d2db rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
          left: -16px;
          right: auto;
        }
        &:after {
          border-color: rgba(0, 0, 0, 0) #ffffff rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
          left: -15px;
          right: auto;
        }
      }
      .timeline-post-format {
        left: -80px;
        right: auto;
      }
    }
    &.item-left {
      padding-right: 50px;
      padding-left: 0px;
      .timeline-block {
        &:before {
          border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #c6d2db;
          right: -16px;
          left: auto;
        }
        &:after {
          border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #ffffff;
          right: -15px;
          left: auto;
        }
      }
      .timeline-post-format {
        right: -81px;
        left: auto;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .vertical-masonry-timeline {
    .each-masonry-item {
      width: 100%;
      &:nth-child(2n+1) {
        padding-right: 0px;
        padding-left: 71px;
      }
      &:nth-child(2n) {
        padding-left: 71px;
        padding-right: inherit;
      }
      &:nth-child(2) {
        margin-top: 0;
      }
      &:nth-child(2n+1) .timeline-post-format {
        left: -72px;
        right: auto;
      }
      &:nth-child(2n) .timeline-post-format {
        left: -72px;
      }
      &:nth-child(2n+1) .timeline-block {
        &::before {
          border-color: rgba(0, 0, 0, 0) #c6d2db rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
          left: -16px;
          right: auto;
        }
        &::after {
          border-color: rgba(0, 0, 0, 0) #ffffff rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
          left: -15px;
          right: auto;
        }
      }
    }
    &:after {
      left: 29px;
    }
  }
  .vertical-masonry-timeline-wrapper {
    &:before, &:after {
      left: 44px;
    }
  }
}

/*
 * Shortcode: Working-Process.less
 * -----------------------------------------------
*/

.working-process {
  > li {
    float: left;
    overflow: hidden;
    position: relative;
    text-align: center;
    &::after {
      border-top: 1px dashed #333333;
      content: "";
      left: 6px;
      margin-left: -32px;
      overflow: hidden;
      position: absolute;
      top: 25px;
      width: 50%;
    }
    &::before {
      border-top: 1px dashed #333333;
      content: "";
      margin-right: -25px;
      position: absolute;
      right: 0;
      top: 25px;
      width: 50%;
    }
    :first-child::after, :last-child::before {
      border-top: medium none;
    }
  }
  li a {
    border: 1px solid;
    display: inline-block;
    height: 50px;
    line-height: 50px;
    overflow: hidden;
    width: 50px;
    border-radius: 50%;
    &:hover {
      background: #333333 none repeat scroll 0 0;
      border: 1px solid transparent;
      color: #fff;
    }
    i {
      font-size: 18px;
    }
  }
  &.square {
    li a, &.large-circle li a {
      border-radius: 0;
    }
  }
  &.large-circle {
    li a {
      height: 120px;
      line-height: 130px;
      width: 120px;
      border-radius: 50%;
      i {
        font-size: 28px;
      }
    }
    > li {
      &::before {
        margin-right: -60px;
        top: 60px;
      }
      &::after {
        margin-left: -64px;
        top: 60px;
      }
    }
  }
  &.theme-colored li a {
    color: #fff;
  }
}

/*
 * Widgets.less
 * -----------------------------------------------
*/
/* -------- Top Search Bar ---------- */

.search-form-wrapper {
  position: relative;
  /* form absolute */
  &.toggle {
    display: none;
    &.active {
      display: block;
    }
  }
  form {
    padding: 10px;
    position: relative;
    label {
      margin: 0px;
      position: absolute;
      right: 11px;
      top: 11px;
      &:before {
        color: #555555;
        cursor: pointer;
        content: "\f002";
        font-family: fontawesome;
        font-size: 16px;
        position: absolute;
        right: 13px;
        top: 6px;
      }
    }
    input {
      &[type="text"] {
        background-color: #fff;
        border: 1px solid #c0c0c0;
        height: 46px;
        padding: 8px 17px;
        width: 100%;
      }
      &[type="submit"] {
        background-color: #fff;
        border-left: 1px solid #c0c0c0;
        border: none;
        height: 44px;
        width: 50px;
        text-indent: -999px;
      }
    }
  }
  &.form-absolute form {
    background-color: #333333;
    position: absolute;
    right: 0px;
    top: 12px;
    width: 370px;
    z-index: 99;
  }
  &.round form {
    input {
      &[type="text"] {
        border-radius: 30px;
        border-width: 4px;
      }
      &[type="submit"] {
        height: auto;
        width: auto;
        margin-right: 10px;
        margin-top: 10px;
      }
    }
    label::before {
      color: #c0c0c0;
    }
  }
}

/* -------- Form Round ---------- */

.widget {
  .list-border li {
    border-bottom: 1px dashed #d3d3d3;
  }
  &.dark .list-border li {
    border-bottom: 1px dashed #404040;
  }
  margin-bottom: 30px;
  .widget-title {
    margin-top: 0;
    margin-bottom: 20px;
  }
  ul {
    li {
      margin-bottom: 5px;
      padding-bottom: 5px;
    }
    &.list li {
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
  }
  .post {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  &.brochures {
    > li {
      margin-bottom: 10px;
      padding: 10px;
    }
    i {
      color: #666666;
      font-size: 16px;
      margin-right: 10px;
    }
  }
  &.address > li {
    font-size: 13px;
    margin-bottom: 10px;
    padding: 5px 10px;
    i {
      font-size: 16px;
      margin-right: 8px;
      vertical-align: middle;
    }
  }
  .twitter-feed li {
    font-size: 13px;
    margin-bottom: 15px;
    margin-top: 0px;
    padding-left: 30px;
    &.item {
      position: relative;
    }
    &::after {
      content: "\f099";
      font-size: 24px;
      font-family: fontawesome;
      left: 0;
      position: absolute;
      top: 0;
    }
  }
  .styled-icons li {
    margin-bottom: 0;
    padding-bottom: 0;
    a {
      margin-bottom: 0;
    }
  }
  .tags a {
    border: 1px solid #404040;
    display: inline-block;
    font-size: 12px;
    margin: 5px 4px 5px -2px;
    padding: 5px 10px;
    &:hover {
      color: #fff;
    }
  }
  .search-input {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #c0c0c0;
    border-radius: initial;
    box-shadow: none;
    height: 45px;
    padding: 10px;
  }
  .search-button {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #c0c0c0;
    border-radius: 0;
    height: 45px;
  }
  .nav-tabs li {
    border-bottom: 1px solid transparent;
    margin-bottom: -2px;
    padding-bottom: 0;
    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
  .post-title a {
    font-size: 14px;
    letter-spacing: 0;
  }
  .quick-contact-form .form-control {
    background-color: rgba(241, 241, 241, 0.8);
    border-color: #d3d3d3;
    color: #a9a9a9;
  }
  .product-list .product-title {
    font-size: 14px;
    font-weight: 400;
  }
  &.dark {
    .widget-title {
      color: #fff;
    }
    .tags a {
      border: 1px solid #404040;
    }
    .widget-image-carousel .title {
      color: #fff;
    }
    .search-input {
      border: 1px solid #333333;
    }
    .styled-icons li a:hover i {
      color: #fff;
    }
    .search-button {
      border: 1px solid #333333;
    }
    .widget-subscribe {
      .subscribe-title {
        color: #fff;
      }
      .subscribe-sub-title {
        color: #666666;
      }
    }
    .nav-tabs li {
      border-bottom: 1px solid transparent;
      margin-bottom: -2px;
      padding-bottom: 0;
      &:hover {
        border-bottom: 1px solid transparent;
        a {
          border: 1px solid #555555;
        }
      }
      &.active a {
        -moz-border-bottom-colors: none;
        -moz-border-left-colors: none;
        -moz-border-right-colors: none;
        -moz-border-top-colors: none;
        background: #333333 none repeat scroll 0 0;
        border-color: #555555 #555555 transparent;
        border-image: none;
        border-style: solid;
        border-width: 1px;
      }
    }
    .product-title a {
      color: #808080;
    }
    .post-title a {
      color: #c0c0c0;
    }
    .tab-content {
      background: #333333 none repeat scroll 0 0;
      border: 1px solid #555555;
    }
    .quick-contact-form .form-control {
      background-color: rgba(5, 15, 5, 0.1);
      border-color: #333333;
    }
    .widget-title.line-bottom-theme-colored-2.border-bottom {
      border-bottom: 1px solid #444;
    }
  }
  &.no-border ul li {
    border: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  ul.list li::before, &.dark ul.list li::before {
    display: none;
  }
}

/* -------- Footer & Sidebar Widgets ---------- */

/* --------Horizontal Contact Widget  ---------- */

.horizontal-contact-widget {
  border-top: 1px dashed #666666;
  .each-widget::before {
    content: "\f111";
    font-family: FontAwesome;
    left: 0;
    position: absolute;
    right: 0;
    top: -42px;
  }
  &.dark {
    border-top: 1px dashed #333333;
  }
}

/* -------- Horizontal Contact Widget Dark ---------- */

.widget.dark .btn.btn-default {
  border-color: #333;
}

/* -------- Flickr-Feed ---------- */

#flickr-feed img {
  background: none repeat scroll 0 0 #000;
  float: left;
  height: 60px;
  margin: 0 5px 5px 0;
  padding: 0;
  width: 70px;
}

.text-hover-theme-colored {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.widget .services-list li {
  background-color: #eeeeee;
  border-bottom: 1px solid #ddd;
  padding: 10px 15px 10px 20px;
  &.active {
    position: relative;
    border-bottom: 1px solid transparent;
    a {
      color: #fff;
    }
    &::after {
      -moz-border-bottom-colors: none;
      -moz-border-left-colors: none;
      -moz-border-right-colors: none;
      -moz-border-top-colors: none;
      border-image: none;
      border-style: solid;
      border-width: 21px;
      bottom: 0;
      content: "";
      height: 21px;
      left: -42px;
      position: absolute;
      top: 0;
      width: 23px;
    }
  }
}

/*
 * footer.less
 * -----------------------------------------------
*/

.footer {
  a {
    color: #808080;
    &:focus, &:hover, &:active {
      color: #666666;
    }
  }
  .widget {
    .widget-title {
      margin-top: 0px;
    }
    &.dark .quick-contact-form button {
      border-color: #333333;
      color: #909090;
      &:hover {
        background-color: #eeeeee;
        border-color: #eeeeee;
        color: #333333;
      }
    }
  }
}

/* fixed footer */

footer#footer.fixed-footer {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: -1;
}

@media screen and (max-width: 992px) {
  footer#footer.fixed-footer {
    position: relative;
  }
}

body.has-fixed-footer .main-content {
  background-color: #fff;
}

@media screen and (max-width: 992px) {
  body.has-fixed-footer .main-content {
    margin-bottom: 0 !important;
  }
}

/*# sourceMappingURL=style-main.css.map */