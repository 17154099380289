#single-profesor{
  padding-top: 1.9rem;
  padding-bottom: 1.9rem;
  h1,h2{
    margin-top: 0;
    margin-bottom: .5rem;
    line-height: 32px;
  }
  .detalle-prof{
    h3{
    margin-top: 0;
    }
    img{
      width: 100%;
    }
    p{
      text-align: justify;
    }
    span{
      a{
        margin-right: .5rem;
        i{
          font-size: 2em;
        }
      }
    }
  }
}