.section-sumate {
  div.container {
    padding: 2em 0;
    display: block;

    h1, h2, h3 {
      margin-top: 0;
    }
    p {
      text-align: justify;
    }
    img {
      max-width: 100%;
    }

    .list{
      h1{
        padding-top: 0;
        margin-top: -15px;
        @include breakTablet{
          margin-top: 10px;
        }
      }

      li{
        list-style: none !important;
        &:before{
          display: none;
        }
        small{
          padding-left: 17px;
        }
      }
    }
  }


}