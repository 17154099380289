section.container {
  max-width: 1300px;
}

ul {
  list-style: none !important;
  margin: 0;
  padding: 0 !important;
  li{
    margin: 0;
    padding: 0;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  //padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

a {
  &:visited {
    color: unset;
  }
}

.entry-date{
  ul{
    margin-left: 10px;
    margin-bottom: 8px;
    padding: 5px;
  }
}