#menu-lateral-celi,#menu-profesorado {
  margin-top: 1rem;

  li {
    width: 100%;
    border-bottom: 1px solid lightgrey;
    letter-spacing: 1px;
    color: grey;
    font-size: .9rem !important;

    a{
      color:black;
      &:active,&:focus,&:visited{
        color:grey;
      }
    }

    &.current-menu-item{
      background: $color_celi;
      a{
        font-weight: 500;
        color:white;
      }
    }


  }
}