.section-testimonios {
  padding-bottom: 2rem;

  .item-testimonio {
    //padding: 0;
    border-bottom:1px solid $color_celi;

    .image {
      height: 180px;
      background-size: cover;
      background-position: center center;
    }
    p {
      color: #3a3a3a;
      text-align: justify;
      padding-right: 1rem;
    }
    h2 {
      color: $color_celi;
      text-transform: uppercase;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      //background-color: $color_celi;
    }

  }

  .panel-testimonio {
    display: none;
    position: fixed;
    padding: 1rem;
    background: white;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 888;
    height: auto;
    max-height: 500px;
    width: 70%;

    .video{
      width: 100%;
      //border:1px solid red;
      //display: flex;
      //justify-items: center;
      //align-items: center;
      iframe{
        height: 260px;
      }
    }


    h1,h2 {
      margin-top: 0;
      text-transform: uppercase;
      padding-top: 0;
      line-height: 3rem;
    }

    .detail-container {
      overflow: auto;
      height: 235px;

      p {
        height: auto;
      }
    }

    .image-test {
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 280px;

      img{
        max-width: 100%;
        //display: block;
        //margin: 0 auto;
      }
    }
  }

  .back-test {
    display: none;
    background: rgba(0, 0, 0, .6);
    height: 100%;
    width: 100%;
    z-index: 500;
    position: fixed;
    left: 0;
    top: 0;
  }
}
